import React from "react";

const ProjectInfoContext = React.createContext({
    // title
    // descriotion
    // sourceLang
    // targetLang
    // isValid
    // deadLine
    // currentUserRole
    // acceptance
    // projectType
    project: {},

    LANGUAGEOPTIONS: [],

    initProject: () => {},
    setProject: (project) => {},
    setValueForKey: (key, value) => {},

})

export default ProjectInfoContext;
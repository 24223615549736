import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Home, ArrowLeft } from "react-feather";

import {
    Col,
    Container,
    Row,
} from "reactstrap";

const Intro = () => {
    const history = useHistory();
    return (
        <div className="sub_container float-center">
            <div>
                <Link to={`/`} className="float-left">
                    <Home />
                </Link>
                <Link onClick={() => { history.go(-1) }} className="float-left">
                    <ArrowLeft />
                </Link>
            </div>
            <br /><br />
            <div className="page_header">
                <h2>이용약관</h2>
            </div>
            <div className="page_policy">
                <section>
                    <h3>제1장 서비스 이용</h3>
                    <div>
                        <h4>제 1 조 (목 적)</h4>
                        <ol style={{ listStyle: "none" }}>
                            <li>이 약관은 주식회사 애니파이브(이하 “회사”)가 운영하는 인터넷 서비스 사이트(이하 “사이트” 또는 “씽캣툴즈”)를 통해 제공하는 인터넷 전자상거래 관련 서비스(이하 “서비스”)와 관련하여 회사와 이용자의 권리, 의무, 책임사항을 규정함을 목적으로 합니다. 또한 이 약관은 유무선 PC통신, 태블릿 PC, 스마트폰(아이폰, 안드로이드폰 등) 어플리케이션 및 모바일웹 등을 이용하는 전자상거래 등에 대해서도 그 성질에 반하지 않는 한 준용됩니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 2 조 (정 의)</h4>
                        <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                        <ol>
                            <li>
                                회사가 운영하는 사이트는 아래와 같습니다.<br />
                            thinkcat.tools, 추후 회사에서 공지하고 제공하는 기타 웹사이트, 스마트폰 및 이동통신기기를 통해 제공되는 모바일 어플리케이션 포함.
                        </li>
                            <li>이용자 : 씽캣툴즈에 접속하여 이 약관에 따라 서비스를 받는 회원 및 비회원을 말합니다.</li>
                            <li>회원 : 이 약관에 따라 회사와 서비스 이용계약을 체결하고 회원 아이디(ID)를 부여받아 회사가 제공하는 서비스를 이용하는 개인 및 법인을 말합니다. 회원은 이용하는 서비스에 따라 매도자와 매수자로 구분될 수 있습니다.</li>
                            <li>고객 : 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사와 서비스 이용계약을 체결하고 회원 아이디(ID)를 부여 받아 사이트 내에 게시된 광고를 열람하거나 특정 기업에 대한 게시글을 작성하거나 비상장 주식 거래글을 작성하고, 타 회원에게 거래 요청을 보내는 등 사이트를 계속적으로 이용할 수 있는 자를 말합니다.</li>
                            {/* <li>전문가 회원 : 회사가 요청한 회원가입에 필요한 정보를 제공하여 회원등록을 한 자로서, 회사와 서비스 이용계약을 체결하고 회원 아이디(ID)를 부여 받아 씽캣 캐시를 구매하여 고객에게 견적을 보내는 등 고객을 찾기 위한 서비스를 계속적으로 이용할 수 있는 개인, 개인사업자 및 법인을 말합니다.</li> */}
                            {/* <li>소속 전문가 : 고객의 요청에 전문화된 서비스를 제공하기 위해 전문가 회원이 등록한 전문가로서 별도의 회원가입을 요하지 않는 비회원입니다. 단, 비대면 화상회의플랫폼인 협업센터와 씽캣이 제공하는 회원가입 기반의 서비스를 이용하기 위해서는 정식 회원가입이 필요합니다.</li> */}
                            <li>요청자 : 회사가 제공하는 서비스를 이용하여 매도 또는 매수 글을 게시한 회원에게 거래에 대한 요청을 보내는 고객을 말합니다.</li>
                            {/* <li>서비스 의뢰서 : 요청자가 원하는 용역에 대한 구체적인 정보를 담고 있는 서비스 요청서입니다.</li> */}
                            {/* <li>견적(견적서) : 전문가 회원이 자신이 제공할 수 있는 용역에 관하여 요청자에게 보낼 수 있는 용역 금액 및 내용에 대한 요약본입니다.</li> */}
                            <li>콘텐츠(Contents) : 회사가 씽캣툴즈에서 제공하는 정보, 토론방 게시글 작성, 장터 매도/매수 게시글 작성, 매도/매수 요청의 건, 프로그램 등 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지ㆍ영상 및 복합체의 정보나 자료를 의미합니다.</li>
                            <li>회원 아이디(이하 “이메일”) : 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.</li>
                            <li>비밀번호(Password) : 이용자가 등록회원과 동일인인지 신원을 확인하고, 회원의 통신상 개인정보를 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.</li>
                            <li>비회원 : 회원에 가입하지 않고 서비스를 이용하는 자로, 씽캣툴즈에서 정한 한정된 서비스를 제공받으며 씽캣툴즈에서 제공받는 정보 등의 서비스 정보 수신을 서면 없이 암묵적으로 동의한 자를 지칭합니다.</li>
                            <li>회원 탈퇴 : 회원이 이용계약을 종료시키는 행위를 말합니다.</li>
                            <li>뉴스레터(Newsletter): 회사가 보내는 씽캣툴즈 소식이 담긴 이메일을 의미합니다.</li>
                            <li>인터랙션 : 회원간의 채팅 등 씽캣툴즈에서 제공하는 방식을 통해 상호작용할 수 있는 행위를 의미 합니다.</li>
                            {/* <li>협업센터 : 고객과 전문가의 비대면 업무회의를 수행할 수 있도록 회원에게 제공되는 유무선 인터넷 기반의 실시간 비대면 화상회의 서비스입니다.</li> */}
                        </ol>
                    </div>
                    <div>
                        <h4>제 3 조 (약관의 명시, 효력 및 개정)</h4>
                        <ol>
                            <li>회사는 이 약관의 내용과 주소지, 사이트 관리자의 성명, 개인정보보호 담당자의 성명, 회사의 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 씽캣툴즈의 초기 서비스 화면 하단에 게시합니다. 다만, 약관의 구체적 내용은 이용자가 연결화면을 통하여 볼 수 있습니다.</li>
                            <li>이 약관은 그 내용을 씽캣툴즈에 게시하거나 이메일 등 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.</li>
                            <li>서비스 이용 시 화면에서 제시되는 경고 메시지의 효력은 이 약관과 충돌하지 않는 한 이 약관의 효력과 동일합니다.</li>
                            <li>회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 기타 관련 법령을 위배하지 않는 범위 내에서 약관을 변경할 수 있으며, 변경된 약관은 제2항과 같은 방법으로 효력을 발생합니다.</li>
                            <li>제4항에 따라 공지된 적용일자 이후에 회원이 씽캣툴즈의 서비스를 계속 이용하는 경우, 변경된 약관의 효력 발생일로부터 7일 이내에 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 개정된 약관에 동의하는 것으로 간주합니다. 개정된 약관에 동의하지 아니하는 회원은 언제든지 자유롭게 서비스 이용계약을 해지할 수 있습니다.</li>
                            <li>회사가 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 사이트의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “사이트”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 4 조 (약관 외 준칙)</h4>
                        <ol>
                            <li>이 약관은 회사가 제공하는 개별서비스에 관한이용안내(이하 ‘서비스 별 안내’라 합니다)와 함께 적용합니다.</li>
                            <li>이 약관에 명시되지 않은 사항에 대해서는 전기통신사업법, 전자문서 및 전자거래기본법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 기타 관련법령 및 서비스 별 안내의 규정에 의합니다.</li>
                        </ol>
                    </div>
                </section>

                <section>
                    <h3>제 2 장 서비스 이용 계약</h3>
                    <div>
                        <h4>제 5 조 (이용 계약의 성립)</h4>
                        <ol>
                            <li>이용 계약은 서비스 이용 희망자의 이 약관에 대해 동의한다는 의사표시와 이용 신청에 대한 회사의 승낙으로 성립됩니다.</li>
                            <li>이 약관에 대한 동의는 이용 신청 당시 서비스 이용 희망자의 이용약관의 확인과 명시적 의사표시를 통해 성립됩니다.</li>
                            <li>이용 계약은 만 14세 이상의 개인 또는 기업(개인사업자 및 법인사업자 등)이 할 수 있습니다. 타인의 정보를 도용하여 회원으로 가입한 자는 회사가 제공하는 서비스를 이용할 수 없으며, 이용하여서도 안 됩니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 6 조 (회원가입 및 승낙)</h4>
                        <ol>
                            <li>회원가입은 회원이 되고자 하는 이용자(이하 “가입신청자”)가 약관의 내용에 동의를 하고, 회사가 정한 가입 양식에 따라 회원정보(이용자ID), 비밀번호, 주소, 연락처 등)를 기입하여 회원가입신청을 하고 회사가 이러한 신청에 대하여 승인함으로써 체결됩니다.</li>
                            <li>이용자의 회원가입신청에 대하여 회사가 승낙한 경우, 회사는 회원 ID/Password와 기타 회사가 필요하다고 인정하는 내용을 이용자에게 통지합니다.</li>
                            <li>회사는 이용자의 신청에 대해서 회원가입을 승낙하는 것을 원칙으로 합니다. 다만, 다음 각 호에 해당하는 신청에 대하여는 승인을 하지 아니할 수 있습니다.
                            <ol style={{ listStyle: "none" }}>
                                    <li>① 본인 실명이 아니거나 다른 사람의 명의를 사용하여 신청하였을 때</li>
                                    <li>② 서비스 이용 계약 신청서의 내용을 허위로 기재하였을 때</li>
                                    <li>③ 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때</li>
                                    <li>④ 부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
                                    <li>⑤ 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우</li>
                                    <li>⑥ 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우</li>
                                    <li>⑦ 기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우</li>
                                </ol>
                            </li>
                            <li>
                                회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                            <ol style={{ listStyle: "none" }}>
                                    <li>① 회사가 설비의 여유가 없는 경우</li>
                                    <li>② 회사의 기술상 지장이 있는 경우</li>
                                    <li>③ 기타 회사의 귀책사유로 이용승낙이 곤란한 경우</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 7 조 (서비스 이용)</h4>
                        <ol>
                            <li>회원은 사이트를 통한 회원가입 시 발급된 ID 하나로 제1장 제2조 1항에 명시된 모든 사이트에 자동 가입되며, 해당 서비스를 이용할 수 있습니다. 단, 일부 사이트의 특정 서비스는 별도의 가입절차를 통하여 이용할 수 있으며, 이 경우 해당 사이트의 이용 시에는 해당 사이트의 특정서비스에 대한 이용약관이 이 약관보다 우선 적용됩니다.</li>
                            <li>회원의 최초 서비스 이용신청 이후 사이트가 늘어난 경우에도 신규 사이트의 이용약관에서 달리 명시되지 않는 한 제1항의 내용이 마찬가지로 적용됩니다. 이 경우 회사는 신규 사이트의 서비스 개시 일자, 회원자동가입 사실 기타 관련 정보를 그 개시일 7일 이전부터 개시일까지 각 사이트에 공지하거나, 또는 그 개시일 7일 이전까지 이메일 통지 등으로 각 회원에게 개별 통지합니다.</li>
                            <li>씽캣툴즈 서비스 이용은 사이트의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</li>
                            <li>회사가 씽캣툴즈에서 제공하는 서비스는 기본적으로 무료입니다. 단, 별도로 일부 특정 서비스를 이용하기 위해서는 회사로부터 구매하거나 무상으로 제공받은 씽캣툴즈 캐시를 사용하여야만 해당 서비스 이용이 가능합니다.</li>
                            <li>유료서비스 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는 규정에 따릅니다.</li>
                            <li>회사는 통신판매중개업자로서 서비스 요금이 무료인 서비스와 일부 유료서비스 이용과 관련하여 이용자들 사이에서 발생한 거래와 관련된 손해에 대해서는 회사에 고의 또는 중대한 과실이 있는 경우를 제외하고는 책임을 지지 않습니다.</li>
                        </ol>
                    </div>
                </section>
                <section>
                    <h3>제 3 장 서비스 제공 및 변경</h3>
                    <div>
                        <h4>제 8 조 (서비스 내용)</h4>
                        <p>회사가 제공하는 서비스의 내용은 다음과 같습니다.</p>
                        <ol style={{ listStyle: "none" }}>
                            <li>① 고객이 전문가에게 의뢰를 요청하기 위한 수단제공</li>
                            <li>② 고객의 서비스 의뢰서를 전문가 회원에게 제공</li>
                            <li>③ 고객에게 서비스 의뢰에 대한 전문가 견적(제안)정보 제공</li>
                            <li>④ 씽캣툴즈가 제공하는 유료서비스(견적발송 등)를 이용하기 위한 씽캣툴즈 캐시 판매(예정)</li>
                            <li>⑤ 회원에게 비대면 협업 및 화상회의를 위한 협업센터 서비스 제공</li>
                            <li>⑥ 기타 관련 부수적 서비스</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 9 조 (정보의 제공 및 광고의 게재)</h4>
                        <ol>
                            <li>회사는 회원에게 서비스 이용에 필요하다고 인정되는 각종 정보에 대해서 사이트 및 이메일, SMS, DM 발송 등 각종 매체에 게재하는 방법 등으로 회원에게 제공할 수 있습니다.</li>
                            <li>회사는 서비스 개선 및 소개 등을 목적으로 회원의 동의 하에 추가적인 개인정보를 요청할 수 있습니다.</li>
                            <li>회사는 서비스의 운용과 관련하여 사이트, 이메일, SMS, DM 등에 광고 등을 게재할 수 있습니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 10 조 (서비스 제공의 제한 및 중단)</h4>
                        <ol>
                            <li>회사는 정기점검, 보수, 교체 등 회사가 필요한 경우 및 부득이한 사유로 인하여 서비스 이용에 지장이 있는 경우에는 서비스 이용의 전부 또는 일부를 제한하거나 일시 중단할 수 있습니다.</li>
                            <li>전시, 사변, 천재지변 또는 이에 준하는 국가 비상사태가 발생하거나 발생할 우려가 있는 경우와 정전, 서비스 이용 폭주 등으로 정상적인 서비스가 불가능한 경우 등 기타 불가항력적인 사유가 있는 경우에는 서비스 이용의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</li>
                            <li>제1항에 의한 서비스 중단의 경우에는 사이트는 회원에게 제11조의 방법으로 통지를 합니다. 단, 사이트가 통제할 수 없는 사유로 인한 서비스 중단으로 사전 통지가 불가능한 경우에는 그러하지 아니합니다.</li>
                            <li>회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 11 조 (회원에 대한 통지)</h4>
                        <ol>
                            <li>회사가 회원에 대한 통지를 하는 경우에는 회원이 서비스 이용신청 시 사이트 화면 또는 회원가입 시 제출한 이메일 주소로 할 수 있습니다.</li>
                            <li>회사는 불특정 다수 회원에 대한 통지의 경우 7일 이상 사이트 상에 게시함으로써 개별 통지에 갈음할 수 있습니다. </li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 12 조 (회원탈퇴 및 자격상실)</h4>
                        <ol>
                            <li>회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 씽캣툴즈 내의 메뉴 또는 전화 등의 방법을 이용하여 가입해지를 신청할 수 있으며, 회사는 즉시 회원 탈퇴 처리를 합니다.</li>
                            <li>회원 탈퇴가 이루어짐과 동시에 서비스 이용과 관련된 모든 정보는 삭제됩니다. 이로 인해 발생하는 불이익에 대한 책임은 회원 본인에게 있습니다.</li>
                            <li>회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.
                            <ol style={{ listStyle: "none" }}>
                                    <li>① 가입신청 시에 허위 내용을 등록한 경우</li>
                                    <li>② 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우</li>
                                    <li>③ 서비스를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                                    <li>④ 제1호 내지 제3호 기타 부정한 방법으로 씽캣툴즈 캐시를 적립, 사용한 경우</li>
                                    <li>⑤ 회사가 회원 자격을 제한ㆍ정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 10일 이내에 그 사유가 시정되지 아니하는 경우</li>
                                </ol>
                            </li>
                            <li>회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.</li>
                        </ol>
                    </div>
                </section>
                {/* <section>
                <h3>제 4 장 씽캣툴즈 캐시 사용 및 보상</h3>
                <div>
                    <h4>제 13 조 (씽캣툴즈 캐시 사용 및 보상)</h4>
                    <ol>
                        <li>씽캣툴즈 캐시는 회사가 제공하는 유료 서비스를 사용할 때 지불 수단으로 이용될 수 있습니다.</li>
                        <li>씽캣툴즈 캐시는 보상이 필요할 경우 씽캣툴즈 서비스 내에서 유료 결제 서비스 별 보상 정책에 대한 안내를 할 수 있습니다.</li>
                    </ol>
                </div>
                <div>
                    <h4>제 14 조 (견적 발생 비용)</h4>
                    <ol>
                        <li>전문가 회원은 고객이 작성한 서비스 의뢰서를 열람하고, 고객에게 자신이 제공할 수 있는 용역에 대한 견적(제안)을 발송할 수 있습니다. 고객이 작성한 서비스 의뢰서의 유효기간은 48시간이며, 해당 시간 내에 견적을 발송할 수 있습니다.</li>
                        <li>견적 발송은 유료이며, 전문가 회원은 해당 견적을 발송하는 데 필요한 씽캣툴즈 캐시가 있어야 견적발송서비스를 이용할 수 있습니다.</li>
                        <li>견적 발송 서비스 대금은 전문가 회원과 고객 간 원활하고 정확한 매칭을 위하여 전문가 서비스 종류에 따라 차등 산정됩니다. 회사는 전문가 회원이 각 견적을 발송하기 전에 지불하여야 하는 대금을 확인할 수 있도록 사전 고지합니다. 이를 확인하지 아니하고 견적을 발송함으로써 전문가 회원에게 발생한 손해에 대하여는 회사가 책임을 지지 아니합니다.</li>
                    </ol>
                </div>
                <div>
                    <h4>제 15 조 (미열람/미계약 견적 건에 대한 보상)</h4>
                    <ol>
                        <li>제안마감(고객의 서비스 의뢰 후 48시간 또는 회사가 서비스 별로 정한 제안서 최대 수신기한 후) 후 72시간동안 해당 견적을 고객이 열람(확인)하지 않은 경우, 전문가 회원은 견적 발송에 지급된 비용을 전액 서비스 캐시로 보상 받을 수 있습니다.</li>
                        <li>고객이 해당 견적을 확인(열람)하였으나, 계약이 성사되지 않은 경우, 전문가 회원은 견적 발송에 사용한 씽캣툴즈 캐시의 1/3을 서비스 캐시로 반환 받을 수 있습니다.</li>
                    </ol>
                </div>
            </section>
            <section>
                <h3>제 5 장 지급 수단</h3>
                <div>
                    <h4>제 16 조 (씽캣툴즈 캐시의 구매 및 구매취소와 환불)</h4>
                    <ol>
                        <li>전문가 회원은 견적 발송 및 고객과의 추가 인터랙션을 사용하기 위하여 씽캣툴즈 캐시를 구입할 수 있습니다.</li>
                        <li>전문가 회원은 충전 캐시를 구매 후 지급받은 날로부터 5년 간 미사용 충전캐시에 대하여 환불을 요청하고, 해당 충전 캐시를 구매하기 위해 지급한 대금을 환불받을 수 있습니다.</li>
                        <li>회사는 전문가 회원이 구매(충전)한 캐쉬의 구매(결제)취소를 요청할 경우, 취소를 요청한 날로부터 3영업일 이내에 결제한 수단과 동일한 수단으로 대금을 환불하여야 합니다. 다만, PG사를 통한 실질적인 결제취소는 신용카드 및 실시간계좌이체의 경우, 약 3 내지 5영업일 정도가 소요될 수 있습니다.</li>
                        <li>제 3항에도 불구하고 다음 각호와 같이 부득이한 경우 회사는 3영업일 내에 전문가 회원의 계좌로 금원을 송금하는 방식으로 환불을 진행합니다.
                            <ol style={{ listStyle: "none" }}>
                                <li>① 카드결제 시점으로부터 1년이 경과한 경우</li>
                                <li>② 휴대폰 소액결제 당월 전액 환불이 아닌 경우</li>
                                <li>③ 기타 결제수단으로 환불이 불가한 경우</li>
                            </ol>
                        </li>
                        <li>5. 회원은 충전 캐시(유료캐시)의 잔여 미사용분에 대해 회원 탈퇴 전, 환불을 요청할 수 있습니다. 회사는 전문가 회원이 캐시의 환불을 요청한 경우, 다음 각호의 규정에 따라 회원이 정한 계좌로 금원을 송금하는 방식으로 환불을 진행합니다.
                            <ol style={{ listStyle: "none" }}>
                                <li>① 반환 비율 : 1캐시(1,000원) X 반환비율 80%</li>
                                <li>② 당월 환불접수건에 대해 익월 5일 일괄지급(휴무일일 경우 휴무 다음일)</li>
                            </ol>
                        </li>
                        <li>회사는 위 대금을 환급함에 있어 전문가 회원이 신용카드 또는 전자화폐 등의 결제수단으로 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 대금 청구를 정지 또는 취소하도록 요청합니다.</li>
                        <li>제2항 내지 제5항에 의한 씽캣툴즈 캐시 환불 시, 해당 충전 캐시 구매 및 기타 비정기적 혜택으로 회사가 전문가  회원에게 무상으로 제공한 서비스 캐시는 전액 소멸됩니다.</li>
                        <li>상법 제 64조(상사시효)에 근거하여 회원이 구매한 충전 캐시는 씽캣툴즈의 최종 이용일로부터 이용내역 없이 60개월이  경과한 이후 자동으로 소멸되어 복구 및 환불이 불가능합니다.</li>
                        <li>회원의 등록정보가 허위 정보로 판명되거나 가입 조건 서비스 이용 조건에 부합되지 않는 점이 판명될 경우 징계 및 강제 탈퇴가 되며 회원 본인의 귀책사유로 인해 환불 및 보상이 불가능합니다.</li>
                        <li>회원이 자진탈퇴로 인해 계약이 해지되는 경우, 회원이 보유한 잔여 충전 캐시 및 보너스 캐시는 자동으로 소멸되어 복구 및 환불이 불가능합니다.</li>
                    </ol>
                </div>
                <div>
                    <h4>제 17 조 (서비스 캐시)</h4>
                    <ol>
                        <li>전문가 회원은 씽캣툴즈 캐시 구매에 대한 혜택으로 회사로부터 충전 캐시과 같이 씽캣툴즈 서비스 결제 수단으로 사용할 수 있는 서비스 캐시를 지급받을 수 있습니다. 전문가 회원은 보유하고 있는 충전 캐시를 모두 사용한 경우에만 서비스 캐시를 사용할 수 있습니다.</li>
                        <li>서비스 캐시는 회사가 전문가 회원에게 무상으로 제공하는 혜택으로 환불이 불가하며, 다음 각 호의 규정을 충족 시 일괄 삭제됩니다.
                            <ol style={{ listStyle: "none" }}>
                                <li>① 충전 시 제공되는 서비스 캐시 및 비정기적 이벤트로 발생하는 서비스 캐시는 지급일로부터 30일내 미사용시 자동 소멸됩니다.</li>
                                <li>② 견적제출 후 고객의 미계약, 미열람에 따라 보상제공된 서비스 캐시는 지급일로부터 50일내 미사용시 자동 소멸됩니다.</li>
                                <li>③ 회사는 무료캐시 소멸일로부터 1개월 전에 이메일 등을 통하여 전문가 회원에게 서비스 캐시가 소멸 예정임을 통지합니다.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div>
                    <h4>제 18 조 (씽캣 캐시 대금지급방법)</h4>
                    <ol>
                        <li>전문가 회원은 씽캣 캐시의 구매에 대한 대금을 다음 각 호의 방법 중 회사가 정하는 방법으로 지급합니다.
                            <ol style={{ listStyle: "none" }}>
                                <li>① 신용카드 (간편)결제</li> <li>② 휴대전화 (간편)결제</li>
                                <li>③ 실시간 계좌이체</li>
                            </ol>
                        </li>
                        <li>회사는, 대금의 결제를 직접 처리하지 않으므로 전문가 회원이 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 불이익에 대하여 고의 및 중대한 과실이 없는 한 책임지지 않습니다.</li>
                    </ol>
                </div>
                <div>
                    <h4>제 19 조 (과오금)</h4>
                    <ol>
                        <li>회사는 과오금이 발생한 경우 대금의 결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.</li>
                        <li>회원의 책임 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.</li>
                        <li>회사는 과오금의 환불절차를 디지털콘텐츠이용자보호지침에 따라 처리합니다.</li>
                    </ol>
                </div>
            </section> */}
                <section>
                    <h3>제 6 장 서비스 이용 계약의 해제, 해지</h3>
                    <div>
                        <h4>제 20 조 (회사의 서비스 이용 계약해제, 해지 및 이용제한)</h4>
                        <ol>
                            <li>회사는 거래의 안정성을 위해 이 약관에서 정한 바에 따라 회원 자격을 정지하거나, 서비스 이용 제한 등의 조치를 취할 수 있습니다.</li>
                            <li>회사는 다른 이용자의 이 약관 위반 행위에 가공하거나 공모한 이용자 또는 이 약관 위반 행위를 돕거나 그로 인해 부당한 이득을 취한 이용자에 대해서도 이 약관 위반 행위에 대한 제재를 적용할 수 있습니다.</li>
                            <li>회사가 회원자격을 정지하거나 이용제한 등 기타 필요한 조치를 취할 경우 이 약관에 특별한 규정이 없는 한 사전에 회원에게 유선 또는 메일 등 알림을 통해 통보하며 회원이 연락 두절되거나 긴급을 요하는 부득이한 경우 선조치 후통보 할 수 있습니다.</li>
                            <li>이용자의 이 약관 위반 행위는 다음과 같습니다.
                            <ol style={{
                                    listStyle: "none",
                                    listStylePosition: "inside",
                                    textIndent: "-20px",
                                }}>
                                    <li>
                                        ① 상업적 광고 행위 [“상업적 광고 행위” 란 채팅, 프로필, 게시글 등을 이용하여 이루어지는 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 음란사이트 광고 행위
                                    <br /> : 피라미드, 금융 서비스를 광고하는 행위
                                    <br /> : 위법, 위조 등이 가능한 서비스를 광고하는 행위
                                    <br />
                                    </li>
                                    <li>② 사행 행위 [“사행행위” 란 아래 각 호의 행위를 의미합니다.]
                                    <br />: 불법 도박 사이트 서비스를 광고하는 행위
                                    <br />: 사회통념상 납득되지 않는 서비스를 광고하는 행위
                                    <br />
                                    </li>
                                    <li>③ 버그 및 제한사항 악용 [“버그 및 제한사항 악용” 이란 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 고의로 서비스 오류를 이용하여 이득을 얻고, 서비스 내 경제 시스템에 영향을 주는 행위
                                    <br /> 예) 고용건수 오류를 이용하여 반복적으로 이를 획득하는 행위
                                    <br /> : 고의로 서비스 오류를 이용하여 다른 이용자에게 피해를 주는 행위
                                    <br /> 예) 리뷰 작성을 이용하여 다른 이용자에게 고의적 악성 리뷰를 작성하여 피해를 주는 행위
                                    <br /> : 고의로 서비스 오류를 이용하여 시스템에 영향을 주는 행위
                                    <br /> 예) 프로그램 오류를 이용하여 서버를 다운시키는 행위
                                    <br />
                                    </li>
                                    <li>④ 어뷰징 [“어뷰징” 이란 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 고의로 리뷰 및 고용 수 등을 조작하는 행위
                                    <br /> : 일반적으로 서비스에 통용되지 아니하는 방법으로 재화를 늘리는 행위
                                    <br /> : 비정상적으로 시스템 설계상 제한을 회피하여 이익을 취하는 행위
                                    <br /> : 시스템상 허용되지 않는 방법으로 다른 이용자의 평판 이익을 늘리는 행위
                                </li>
                                    <li>⑤ 불건전 언어 사용 [“불건전 언어 사용” 이란 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 욕설, 비/속어 등을 사용하는 행위
                                    <br /> : 음란한 단어나 노골적인 성 묘사 등을 통해 성적 수치심을 느끼게 하는 표현이나 행동을 하는 행위
                                    <br /> : 다른 이용자에게 불쾌감이나 성적 수치심을 줄 수 있는 명칭을 사용하는 행위
                                    <br /> : 현실에 대한 위협이나 상대방에게 공포심을 느끼게 하는 표현이나 행동을 하는 행위
                                    <br /> : 기타 약관에서 금지하는 표현이나 통신을 하여 상대방에게 불쾌감이나 혐오감을 주는 행위
                                    <br /> : 제3자의 명예, 초상권, 개인정보를 포함한 제반 권리를 침해, 훼손할 목적의 내용이 포함된 대화 등 이에 준하는 행위
                                    <br />
                                    </li>
                                    <li>⑥ 불건전 이름(닉네임) 사용 [“불건전 이름 사용” 이란 온라인 문화를 저해하거나 관계 법령 및 사회질서와 미풍 양속을 저해하는 내용과 욕설 또는 음란한 내용으로 만든 이름, 비즈니스 네임 등을 포함하여 명명된 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 씽캣툴즈 직원 및 관리자 또는 제3자를 사칭하는 행위
                                    <br /> : 욕설, 비/속어 등을 사용하는 행위
                                    <br /> : 다른 이용자에게 불쾌감이나 성적 수치심을 줄 수 있는 명칭을 사용하는 행위
                                    <br /> : 기타 약관에서 금지하는 명칭을 사용하는 행위
                                    <br /> : 관련 계정의 닉네임 등은 임의로 변경될 수 있습니다.
                                    <br />
                                    </li>
                                    <li>⑦ 명의도용 [“명의도용” 이란 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 타인의 개인정보를 이용하여 계정을 생성하는 행위
                                    <br />
                                    </li>
                                    <li>⑧ 결제도용 [“결제도용” 이란 아래 각 호의 행위를 의미합니다.]
                                    <br /> : 타인의 결제수단을 무단으로 이용하여 서비스 이용요금 등을 결제하는 행위
                                    <br /></li> <li>⑨ 불건전 게시물 게시 [“불건전 게시물 게시” 란 아래 각 호의 행위를 의미합니다.]
                                        <br /> : 회사 또는 제3자의 권리(저작권, 특허 등)를 침해하는 게시물
                                        <br /> : 본인/타인의 개인정보(신상정보, 위치, 연락처, 이메일 등)이 포함된 게시물
                                        <br /> : 다른 사람에게 성적 수치심을 주거나 혐오감, 불쾌감을 유발하는 게시물
                                        <br /> : 다른 사람을 비방하거나 음해하는 목적의 게시물(인신공격, 루머 포함)
                                        <br /> : 영리 목적의 광고 게시물(경품, 상품광고, 사이트 홍보 등)
                                        <br /> : 악성코드가 포함되어 있거나 시스템 장애를 유도하는 게시물
                                        <br /> : 특정 사상/종교적 색채가 짙은 게시물
                                        <br /> : 특정 개인 및 회사에 대한 허위 사실을 악의적으로 유포하는 게시물
                                        <br /> : 인종, 성, 특정 지역을 비하하거나 차별하는 게시물
                                        <br /> : 폭력, 비행, 사행심을 조장하는 게시물
                                        <br /> : 이 약관 위반 행위와 관련된 게시물
                                        <br /> : 기타 약관, 관련 법령에서 금지하는 게시물
                                        <br /> : 짧은 시간에 많은 양의 게시물을 등록하거나 동일한 내용을 반복 게시하여 운영을 방해하는 행위
                                        <br />
                                    </li>
                                </ol>
                            </li>
                            <li>계정 공유 중 제 3자가 이용자 본인의 계정을 이용하여 이 약관 위반 행위가 발생한다고 하더라도 이용자 본인 및 해당 계정에 제재가 적용될 수 있습니다.</li>
                            <li>회원은 회사의 해제․해지 및 이용제한에 대하여 이의신청을 할 수 있습니다. 회원의 이의신청이 정당한 경우 회사는 즉시 회원이 서비스를 이용할 수 있도록 필요한 조치를 취합니다. </li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 21 조 (회사의 계약해제·해지의 효과)</h4>
                        <ol>
                            <li>회원의 귀책사유에 따른 이용계약의 해제·해지시 잔여 무료캐시는 소멸합니다. 잔여 충전 캐시이 있을 경우 회사는 회원가입시 회원이 입력한 연락처로 최소 30일의 기간을 두고 잔여 씽캣툴즈 캐시 환불을 위한 계좌정보 등을 입력할 것을 최고합니다. 회원이 최고 기간 내 환불을 위한 정보를 입력하지 않을 경우 잔여 씽캣 캐시는 소멸합니다.</li>
                            <li>회사가 제20조에 따라 서비스 이용계약을 해제 또는 해지한 경우 회사는 회원에게 손해배상을 청구할 수 있습니다. 다만, 회원이 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.</li>
                        </ol>
                    </div>
                </section>
                <section>
                    <h3>제 7 장 개인 정보의 처리</h3>
                    <div>
                        <h4>제 22 조 (회원의 개인정보 보호)</h4>
                        <ol>
                            <li>회사는 회원의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보 보호법 등 관계 법령에서 정하는 바를 준수하며 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다. </li>
                            <li>회사의 개인정보 보호는 씽캣툴즈 개인정보처리방침에 따릅니다.</li>
                        </ol>
                    </div>
                </section>
                <section>
                    <h3>제 8 장 당사자의 의무</h3>
                    <div>
                        <h4>제 23 조 (회사의 의무)</h4>
                        <ol>
                            <li>회사는 회원이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.</li>
                            <li>회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 기계의 결함이 있는 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.</li>
                            <li>회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.</li>
                            <li>회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을 통보하여야 합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 24 조 (회원의 의무)</h4>
                        <ol >
                            <li>1. 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
                            <ol style={{ listStyle: "none" }}>
                                    <li>① 회원가입신청 또는 변경 시 허위내용을 등록하는 행위</li>
                                    <li>② 씽캣툴즈에 게시된 정보를 허위로 변경하는 행위</li>
                                    <li>③ 회사 기타 제3자의 인격권 또는 지식재산권을 침해하거나 업무를 방해하는 행위</li>
                                    <li>④ 다른 회원의 ID를 도용하는 행위</li>
                                    <li>⑤ 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)의 전송 또는 게시하는 행위</li>
                                    <li>⑥ 회사의 직원이나 관리자를 가장하거나 사칭하여 또는 타인의 명의를 오용하여 글을 게시하거나 메일을 발송하는 행위</li>
                                    <li>⑦ 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타의 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 발송하는 행위</li>
                                    <li>⑧ 스토킹(stalking) 등 다른 회원을 괴롭히는 행위</li>
                                    <li>⑨ 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위</li>
                                    <li>⑩ 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위</li>
                                    <li>⑪ 외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위</li>
                                    <li>⑫ 사실관계를 왜곡하는 정보제공 등 기타 회사가 부적절하다고 판단하는 행위</li>
                                    <li>⑬ 제1호 내지 제12호 기타 부정한 방법으로 씽캣툴즈 캐시을 적립, 사용한 경우</li>
                                    <li>⑭ 기타 관계 법령이나 회사에서 정한 규정에 위배되는 행위</li>
                                </ol>
                            </li>
                            <li>회원이 개인의 정보를 허위 또는 잘못 기재하여 생기는 불이익에 대한 책임은 회원 본인에게 있으며, 이에 대해 회사는 아무런 책임이 없습니다.</li>
                            <li>회사가 사이트 운영상 부적절하다고 판단한 정보가 게시된 경우, 회사는 게시를 행한 자의 승낙 없이 게재된 당해 정보를 삭제할 수 있습니다. 단, 회사는 이러한 정보의 삭제 등을 최고할 의무를 지지 않습니다.</li>
                            <li>회사는 회원이 이 약관 또는 당사 정책에 위반되는 내용의 메시지를 프로필 영역에 작성하거나 타 회원에게 해당 메시지 내용을 발송하는 경우 별도의 통지 없이 작성 또는 발송된 내용을 임의로 수정 또는 삭제할 수 있습니다.</li>
                            <li>제1항에 해당하는 행위를 한 회원이 있을 경우 회사는 이 약관 제12조, 제20조, 제21조에서 정한 바에 따라 회원의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있으며, 특히 아래 각 호에 해당하는 행위를 할 경우에는 각각에 대응되는 조치를 취할 수 있습니다. 다만, 회사가 취할 수 있는 조치는 아래 각 호에 한정되지 아니하며, 회사가 조치를 취할 수 있는 회원의 불법적인 행위 역시 아래 각 호의 경우에 한정되지 아니합니다.
                            <ol style={{ listStyle: "none" }}>
                                    <li>① 타 회원들에 대한 무단 마케팅(요청서 및 견적에 제공 용역에 대한 내용 외 본인의 영리적 목적을 위한 내용을 무단으로 작성하는 것을 의미하며 광고 등을 포함): 모든 기능 사용 정지</li>
                                    <li>② 타 회원들에 대한 부적절한 대화 시도 및 메시지 발송 등(욕설, 성희롱, 기타 부적절한 표현을 포함함): 모든 기능 사용 정지(영구 제재)</li>
                                    <li>③ 타 회원들에게 허위사실을 유포하거나 표시광고를 위반하는 경우(견적에 회사에 상당한 수수료를 지불하고 있어, 절대 채팅으로 답변하지 말라는 내용을 기재하거나, 명함에 씽캣툴즈 로고를 무단으로 표시하고 자신을 씽캣툴즈 협력사로 소개하는 등을 포함함): 모든 기능 사용 정지(영구 제재)</li>
                                    <li>④ 전문가 회원의 의무 및 서비스 정책 위반(프로필에 계속하여 휴대폰 번호 등 개인정보를 노출, 요청서 또는 견적에 본인 연락처를 작성하여 직접 연락을 유도하는 등의 경우를 포함함): 모든 기능 사용 정지</li>
                                </ol>
                            </li>
                            <li>회원은 그 귀책사유로 인하여 회사나 다른 회원이 입은 손해를 배상할 책임이 있습니다.</li>
                            <li>회원은 서비스의 이용과 관련하여 관계법령, 약관, 세부이용지침, 서비스 이용안내 및 회사가 통지한 공지사항 등을 준수하여야 하며, 이를 수시로 확인하여야 합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 25 조 (고객의 의무)</h4>
                        <ol>
                            <li>고객은 제공받기 원하는 거래에 대한 내용으로 채팅 기능을 이용하여 거래자 및 회사에 제공하고 회사가 이를 다수의 회원에게 전달하는 것에 대하여 동의합니다.</li>
                            <li>고객은 허위나 과장된 내용으로 게시글을 작성하거나 또는 거래에 대한 의사가 없음에도 게시글을 작성할 수 없으며, 허위 및 과장된 내용으로 게시글을 작성함에 따라 거래가 이루어지지 못하거나 법적인 문제가 발생할 경우 이에 대한 책임은 고객에게 있습니다.</li>
                            <li>고객은 거래 전에 반드시 상대방의 게시글에 작성한 거래 상세 내용과 거래의 조건을 정확하게 확인해야 합니다. 구매 또는 판매 하려는 거래의 내용과 거래의 조건을 확인하지 않고 거래하여 발생한 모든 손실과 손해는 고객 본인이 부담합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 26 조 (게시글 작성 회원의 서비스 관련 의무)</h4>
                        <ol>
                            <li>회원(이하 “게시글 작성자”)은 대금 결제 및 환불 등 거래와 관련한 일체의 업무를 처리하며, 이를 신의성실 하게 이행하여야 할 의무가 있습니다. 또한 해당 거래에서 발생하는 모든 책임과 의무를 부담합니다.</li>
                            <li>회원(이하 “게시글 작성자”)이 작성한 프로필, 거래내용 등에 허위 또는과장, 기재누락, 오기가 있어서는 아니되며 이를 준수하지 아니하여 발생하는 모든 법적문제에 대하여는 회원(이하 “게시글 작성자”)이 모든 책임을 부담합니다. 회원(이하 “게시글 작성자”)은 해당 거래 및 기재한 모든 내용에 대한 진실성과 적법성에 대해 보증합니다.</li>
                            <li>회원(이하 “게시글 작성자”)은 작성한 게시글, 게시글 내용, 게시글 작성 시기 등 내용의 변경 사항이 있을 경우 이를 바로 갱신하여야 할 의무를 집니다.</li>
                            <li>회원(이하 “게시글 작성자”)은 관련법령 등을 위반하는 불법적인 거래 게시글을 제공하지 않는다는 점에 대해 보증해야하며 불법적이거나 본 이용약관을 위반하는 내용으로 허위 내용을 작성하여 회원(이하 “타 유저”)에게 송부할 수 없습니다.</li>
                            <li>회원(이하 “게시글 작성자”)은 거래를 진행하는 모든 회원(이하 “거래 요청 유저”)과 씽캣툴즈 서비스에서 제공하는 채팅 기능 등 별도의 연결수단을 통하여 세부사항을 조정할 수 있습니다. 회사는 거래 진행 및 이후 회원(이하 “게시글 작성자”)과 회원(이하 “거래 요청 유저”) 간에 발생한 일체의 문제에 대하여 어떠한 책임도 지지 않습니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 27 조 (회원의 ID 및 비밀번호에 대한 의무)</h4>
                        <ol>
                            <li>원칙적으로 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
                            <li>회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 되며, 그로 인한 모든 책임은 회원에게 있습니다.</li>
                            <li>회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야 합니다. 만약 회원이 위 사실을 인지하고도 회사에 대한 통보를 지체함으로써 발생한 손해에 대해서 회사는 배상할 의무가 없습니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 28 조 (게시물 또는 내용물의 삭제)</h4>
                        <ol>
                            <li>회원이 회사에 등록하는 게시물 및 타인 게시물의 활용 등으로 인하여 본인 또는 타인에게 손해나 기타 문제가 발생하는 경우 회원은 이에 대한 책임을 지게 되며, 회사는 이에 대하여 책임을 지지 않습니다.</li>
                            <li>회사는 회원이 게시하거나 등록한 내용이 제20조 제4항의 규정에 위반되거나 회사 소정의 게시기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다. 그러나 회사가 이러한 정보의 삭제 등을 할 의무를 지는 것은 아닙니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 29 조 (저작권의 귀속 및 권리, 의무)</h4>
                        <ol>
                            <li>회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에 귀속합니다.</li>
                            <li>회원은 회사의 서비스를 이용함으로써 얻은 정보를 회사의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</li>
                            <li>회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.</li>
                            <li>회사는 회원 또는 요청자가 사이트에 게시한 다음 각 호의 게시물을 회사의 서비스 제공과 관련한 마케팅 용도로 사용ㆍ복제ㆍ수정ㆍ출판ㆍ배포할 수 있습니다. 단, 회사는 게시물 이용 시 작성자 또는 요청자의 개인정보가 포함되지 않는 범위에서 해당 정보를 처리하고, 최대한 작성자의 권리를 보호할 수 있도록 노력할 것입니다.
                            <ol style={{ listStyle: "none" }}>
                                    <li>① 가. 유저의 프로필 정보</li>
                                    <li>② 나. 요청자 및 작성한가 작성한 후기(이에 포함된 글 및 사진)</li>
                                    <li>③ 다. 거래 및 게시글의 내용</li>
                                    <li>④ 라. 작성자와 요청자 사이의 연락(채팅) 내용</li>
                                    <li>⑤ 마. 기타 유저가 서비스 이용과정에서 작성·게시한 콘텐츠</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 30 조 (광고주 및 연결 사이트와의 관계)</h4>
                        <ol>
                            <li>회사의 공식 사이트 이외의 웹사이트 및 이메일에서 링크된 사이트에서는 회사의 개인정보 보호정책 및 이 약관이 적용되지 않습니다.</li>
                            <li>회사는 씽캣툴즈 사이트 또는 e-mail 상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.</li>
                        </ol>
                    </div>
                </section>
                <section>
                    <h3>제 9 장 기타</h3>
                    <div>
                        <h4>제 31 조 (양도의 금지)</h4>
                        <p>회원은 서비스 이용 권리를 타인에게 대여, 양도 또는 증여 등을 할 수 없으며, 또한 질권의 목적으로도 사용할 수 없습니다.</p>
                    </div>
                    <div>
                        <h4>제 32 조 (면책)</h4>
                        <ol>
                            <li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 고의 또는 과실이 없는 한 구매자에게 발생한 손해에 대하여 서비스 제공에 관한 책임이 면제됩니다.</li>
                            <li>회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</li>
                            <li>회사는 회원이 서비스의 이용을 통해 예상했던 금전적인 수익을 얻지 못하게 되거나, 서비스를 통하여 얻은 자료로 인해 손해를 보게 되더라도 이에 관하여 책임을 지지 않습니다.</li>
                            <li>회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는, 회사가 해당 정보의 허위성을 명백히 인지하였다는 특별한 사정이 없는 한, 책임을 지지 않습니다. 회사가 이 약관과 관련 법령에 따른 조치를 취한 경우에도 같습니다.</li>
                            <li>회사는 서비스 이용과 관련하여 회사의 고의, 과실에 의하지 아니한 손해에 대하여 책임을 지지 않습니다.</li>
                            <li>회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.</li>
                            <li>회사는 회원들에게 요청서와 견적을 전달하거나 광고플랫폼을 제공하고 이에 대한 부수적인 서비스를 제공할 뿐이므로 작성자와 요청자 간 거래와 관련하여 발생한 의무의 불완전 이행, 이행 지체 등 거래 이행 미비, 사후처리, 대금 정산, 완성품의 하자, 청약철회, 물품의 반품 등 어떠한 법적 분쟁 및 사후처리에 대해서도 개입하거나 책임지지 않습니다.</li>
                            <li>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</li>
                            <li>회사는 회원 간에 분쟁이 발생하였을 경우 고의 또는 중과실이 없는 한 회원 간 법적 문제에 대하여 책임을 지지 않습니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 33 조 (손해배상)</h4>
                        <p>당사자 일방이 이 약관 상 의무를 위반함으로 인하여 상대방에게 손해가 발생한 경우, 귀책사유 있는 당사자는 상대방이 입은 손해를 배상합니다.</p>
                    </div>
                    <div>
                        <h4>제 34 조 (비밀유지)</h4>
                        <ol>
                            <li>당사자는 서비스를 이용하는 과정에서 알게된 상대방의 정보 또는 이용자의 정보를 제3자에게 누설, 공개하지 아니합니다.</li>
                            <li>본 조는 이용계약이 해지되거나 서비스 제공이 중단된 이후에도 유효합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 35 조 (고객센터)</h4>
                        <ol>
                            <li>회사는 이용자에게 생긴 문제를 해결하기 위해 최선을 다하며, 이를 위하여 이용자로부터 고객센터에 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보합니다. </li>
                            <li>회사와 이용자 간 분쟁이 발생할 경우 양자 간 합의에 의하여 처리하는 것을 원칙으로 하며 회사는 이용자 간 발생한 법적 문제에 대하여 고의 또는 중과실이 없는 한 책임이 없습니다. 그러나 회사는 고객센터를 통하여 피해 사항을 적극적으로 청취하여 이용자들 간 발생한 문제를 해결하는 데에 도움이 될 수 있도록 최선을 다합니다.</li>
                        </ol>
                    </div>
                    <div>
                        <h4>제 36 조 (관할 및 준거법)</h4>
                        <ol>
                            <li>회사와 이용자 간 발생한 분쟁에 관한 소송의 관할은 서울중앙지방법원을 전속적 관할 법원으로 합니다.</li>
                            <li>회사와 이용자 간에 제기된 소송에는 대한민국법을 적용합니다.</li>
                        </ol>
                    </div>
                </section>
                <section>
                    <h3>부칙</h3>
                    <br />제1조 (시행일) 이 약관은 2022년 07월 01일부터 시행합니다.
            </section>
            </div>
        </div>
    )
};





const TermsPolicyView = () => (
    <Container fluid classNameName="p-0">
        <Row>
            <Col lg={10} xl={8} classNameName="mx-auto">
                <Intro />
            </Col>
        </Row>
    </Container>
);

export default TermsPolicyView;

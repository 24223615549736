// npm packages
import React, { useState, useEffect, useContext } from 'react';
import ContentEditable from 'react-contenteditable';

import axios from 'axios';

// redux
// import { useSelector, useDispatch } from "react-redux";
import EditorDataContext from '../contexts/EditorDataContext';

import TextAreaSource from './TextArea.source';
import TextAreaTarget from './TextArea.target';

import { BACKEND } from '../../../Constants';

// spinner
import { Spinner } from 'reactstrap';

const TextArea = (props) => {
    const [text, setText] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [glossaryData, setGlossaryData] = useState([]);
    const [glossaryLoaded, setGlossaryLoaded] = useState(false);

    // const editor = useRef();
    // const fileName  = useSelector((state) => state.editorData.fileName );
    // const loginInfo = useSelector((state) => state.editorData.loginInfo);
    const edCtx = useContext(EditorDataContext);
    const fileName = edCtx.fileName;
    const loginInfo = edCtx.loginInfo;

    // const editable = props.editorType === '번역'? false :true;
    const editable = false;

    const glossaryOnHandler = (activeList) => {
        activeList.map((active) => {
            props.setGlossaryActive(active);
        })
    };

    const get_glossary_match = (text, index, glossaryMatch_key) => {
        const data = new FormData();
        data.append('process', `get_glossary_${glossaryMatch_key}`)
        data.append('text', text);
        data.append('index', index);

        data.append('project_no', loginInfo.project_no);
        data.append('ownerId', loginInfo.userId);
        data.append('filename', fileName);

        loginInfo.project_no !== 'individual' &&
            loginInfo.project_no !== 'tempFiles' &&
            axios.post(`${BACKEND.SERVER}/matchingGlossary`, data
            ).then((response) => {
                // console.log(response)
                setGlossaryData(response.data);
            }).then(() => {
                setGlossaryLoaded(true);
            })
    };

    useEffect(() => {
        setIsSaved(false);
        if (props.line) {
            const text = props.line.replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, '').replace(/&nbsp;/g, ' ')
            setText(text);
        }
        // setText(props.line.replace(/<span[^>]+>/g, '<span class="badge rounded-pill bg-info">S</span>'))
        // setText(props.line.replace(/<span/g, '<span class="badge rounded-pill bg-info"'))
        // setText(props.line)
    }, [props.line]);

    useEffect(() => {
        if (props.modifiedOrNot) {
            !props.onlyInspection &&
                text !== '' &&
                get_glossary_match(text, props.index, props.glossaryMatch_key)
        } else {
            if (props.onlyInspection) {
                setGlossaryLoaded(false);
            } else {
                setGlossaryData(props.glossaryIndex);
                setGlossaryLoaded(true);
            }
        }
    }, [props.index, text, props.glossaryIndex]);

    const Cell = (props) => {
        if (!editable) {
            if (!glossaryLoaded) {
                return <div>{text}</div>
            }
            else {
                return (
                    <div>
                        {props.glossaryMatch_key === 'src'
                            ? <TextAreaSource
                                index={props.index}
                                text={text}
                                glossaryData={glossaryData}
                                glossaryActive={props.glossaryActive}
                                setGlossaryActive={props.setGlossaryActive}
                                currentActive={props.currentActive}
                            />
                            : <TextAreaTarget
                                index={props.index}
                                text={text}
                                glossaryData={glossaryData}
                                glossaryActive={props.glossaryActive}
                                setGlossaryActive={props.setGlossaryActive}
                                currentActive={props.currentActive}
                            />
                        }
                    </div>
                )
            }
        }
        else {
            return (
                <ContentEditable
                    className='p-1'
                    name={props.name}
                    html={text}
                    disabled={props.selected ? false : true}
                    onChange={props.changeHandler}
                    style={
                        props.selected
                            ? {
                                border: '0.15rem solid rgb(170, 170, 250)',
                                borderRadius: '0.4rem',
                                outline: 'none',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)'
                            }
                            : {
                                outline: 'none',
                                border: '0.15rem solid rgba(0, 0, 0, 0)'
                            }}
                />
            )
        }
    }

    return (
        <div
            className='p-2'
            name={props.name}
            style={props.line === undefined
                ? { textAlign: 'center' }
                : {}}
        >
            {props.line === undefined
                ? <Spinner color="primary" size='sm' />
                : <Cell
                    {...props}
                    currentActive={edCtx.currentActive}
                />
            }

        </div>
    );
};

export default TextArea;
import http from "../../../services/http-common";

const Export2Tmx = (fileName, project_no, source, target, direction, withoutStyle) => {
    const userInfo = localStorage.getItem('userInfo');
    const userId = userInfo ? JSON.parse(userInfo).userUid : '';

    const formData = new FormData();

    formData.append('fileName', fileName);
    formData.append('project_no', project_no);
    formData.append('source', JSON.stringify(source));
    formData.append('target', JSON.stringify(target));
    formData.append('direction', direction);
    formData.append('process', 'tmx');
    formData.append('withoutStyle', withoutStyle);
    formData.append('userId', userId);

    return http.post('/files', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
};

export default {
    Export2Tmx
};

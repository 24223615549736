// npm packages
import React, { useState, useEffect, useRef } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import './suneditor.min.css';

// Wrapper
import Wrapper from '../helpers/Wrapper';

// react-bootstrap
import {Col, Row, Button} from 'react-bootstrap';

const Editer = (props) => {
    const [text, setText] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const editor = useRef();
    const fileName = useRef();

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
        //console.log(editor.current);
        return editor.current;
    };

    const changeHandler = (string) => {
        setText(string);
        setIsSaved(false);
    };

    const onMouseLeavehandler = (event) => {
        console.log(event)
    }

    useEffect(() => {
        fileName.current = props.fileName;
        setIsSaved(false);
        setText(props.data);

    }, []);

    return (
        <Wrapper>
            <Row sm = {12}
                onMouseLeave={props.onMouseLeavehandler || null}
                id = {props.id}>
                
                <Col ref = {editor} sm = {12}>
                <SunEditor
                    // getSunEditorInstance={getSunEditorInstance}
                    height="100%"
                    
                    setOptions={props.hasStyle?
                        {
                        buttonList: [
                            [
                            'fontSize',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'fontColor',
                            ]
                        ]
                        }:
                        {buttonList: []}}
                    disable = {props.changeable? false :true}
                    setContents={text}
                    onChange={props.changeHandler || null}
                    on
                    lang='ko'
                    name='editor'
                /></Col>
            </Row>
        </Wrapper>
    );
};

export default Editer;
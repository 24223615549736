import React from 'react';
import editorWebService from '../services/EditorWebService';

const Export2Tmx = (props) => {
    const promise = new Promise(async (resolve, reject) => {
        await editorWebService.Export2Tmx(
            props.fileName, 
            props.project_no,
            props.source,
            props.target,
            props.direction,
            props.withoutStyle).then((response) => {
                resolve(response.data);
            });
    });
    return promise
};

export default Export2Tmx;
import React, { useState, useReducer } from "react";

// react-bootstrap
import { ToggleButton } from "react-bootstrap";

const CheckButton = (props) => {
    //const [checked, setChecked] = useState(true);
    //const [buttonName, setButtonName] = useState('Checked')

    return (
        <ToggleButton
            onClick={props.onCheckHandler}
            className = 'mb-0'
            id = {props.id}
            value = {props.id}
            type = 'checkbox'
            variant = {props.variant || 'outline-primary'}
            checked = {props.check 
                ?props.check.checkBool
                :true}
            >
            {props.check 
            ?props.check.name
            :'test'}
        </ToggleButton>
    )
};

export default CheckButton;
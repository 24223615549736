import { faAcquisitionsIncorporated } from '@fortawesome/free-brands-svg-icons';
import React, { useReducer } from 'react';

import AssignmentContext from './assignment-context';

const defaultAssignmentState = {
  // items keys: 
  // index, sentence, assignUser, deadline
  items: [],
  teamMembers: [],
  deadline: '',
  transPercent: 0,

  selected: []
};

const AssignmentReducer = (state, action) => {
  switch(action.type){
    case('init'):{
      return {
        items: [],
        teamMembers: [],
        deadline: '',
        transPercent: 0,
      
        selected: []
      }
    }
    
    case('extendItem'):{
      const items = state.items;
      items.push(...action.payload.assignment);
      const selected = state.selected
      selected.push(...action.payload.selected);
      
      return {
        items: items,
        teamMembers: action.payload.teamMembers,
        deadline: action.payload.deadline,
        transPercent: action.payload.transPercent,
        selected: selected
        }
    }
      
    case('select-item'):{
      return {
        ...state,
        selected: action.selectItems
      }
    }

    case('assign'):{
      const user = state.teamMembers.find(
        item => item.no == action.user_no
      )
      const items = [
        ...state.items
      ];

      action.indexes.map(
        index => {
          const item = {
            ...state.items[index],
            assignUser: user
          };
          items.fill(item, index, index+1)    
        }
      )
      
      return {
        ...state,
        items: items
      }
    }
  };
  return state
};

const AssignmentProvider = (props) => {
  const [assignState, dispatchAssign] = useReducer(
    AssignmentReducer, defaultAssignmentState);
  
  const initAssignment = (payload) => {
    dispatchAssign({
      type: 'init',
      payload: payload
    })
  };

  const extendAssignment = (payload) => {
    dispatchAssign({
      type: 'extendItem',
      payload: payload
    })
  };

  const selectItem = (selectItems) => {
    dispatchAssign({
      type:'select-item',
      selectItems: selectItems
    })
  };

  const setAssignment = (selectedKeys, user_no) => {
    dispatchAssign({
      type: 'assign',
      indexes: selectedKeys,
      user_no: user_no
    })
  };

  const assignmentContext = {
    items: assignState.items,
    teamMembers: assignState.teamMembers,
    deadline: assignState.deadline,
    transPercent: assignState.transPercent,
    selected: assignState.selected,

    initItems: initAssignment,
    extendItem: extendAssignment,
    assignItem: setAssignment,
    selectItem: selectItem
  };

  return (
    <AssignmentContext.Provider value = {assignmentContext}>
      {props.children}
    </AssignmentContext.Provider>
  )
};

export default AssignmentProvider;


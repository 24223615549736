import React from 'react';

const GlossaryContext = React.createContext({
  sourceLanguage: '',
  targetLanguage: '',
  // item keys:
    // index
    // sourceGlossary
    // targetGlossary
    // auther
    // date
  items: [],
  tempItems: [],
  editing: [],

  setItems: (items) => {},
  addItem: (item) => {},
  removeItem: (index) => {},
  changeItem: (index, key, value, date) => {},
  changeEditing: (index) => {},
  storeItem: (index, source, target) => {},
  addElement: (index, key) => {},
  deleteElement: (index, key) => {},
  reflesh: () => {},
  
})

export default GlossaryContext;
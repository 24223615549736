import React from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

const UnauthorizedPage = (props) => {
  return (
    <React.Fragment>
      <div className="text-center">
        <h1 className="display-1 font-weight-bold">401</h1>
        <p className="h1">세션 인증 에러</p>
        <p className="h2 font-weight-normal mt-3 mb-4">
          로그인이 필요합니다.  
        </p>
        <Link to="/">
            <Button color="primary" size="lg">
                Return to website
            </Button>
        </Link>
      </div>
    </React.Fragment>
  )
};

export default UnauthorizedPage;
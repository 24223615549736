import React, { useCallback } from "react";
import UploadService from "../../../../services/FileUploadService";

import { Card } from "react-bootstrap";
import { FileText } from 'react-feather';
import { useDropzone } from 'react-dropzone';

// utils
import checktoValidFile from '../../../upload/utils/checkValidExt';

import { BACKEND } from "../../../../Constants";

const baseURL = BACKEND.SERVER;

const UploadFile = (props) => {
  //const {project_no, parentUpdate} = props;
  // const history = useHistory();
  
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const email = userInfo? userInfo.userEmail: null;

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    // console.log(props.project_no)
    const files = checktoValidFile(acceptedFiles, ['xlsx']);
    files.map((file) => 
      UploadService.upload(file, email, props.project_no, false, ()=> {}
        ).then((response) => {
          // console.log(response);
          if (response.data.filename === 'duplicateFile') {
            alert(response.data.message)
          } else {
            props.addItem(file, response.data.filename);
          }
        }).catch((error) => {
          alert('파일을 업로드할 수 없었습니다!')
        })
    );
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Card style = {{width:'100%'}}>
        <div 
          {...getRootProps()}
          style={{
            'border': '2px dashed',
            'textAlign': 'center'}}>
        <input {...getInputProps()} />
        {isDragActive 
          ? 
          <>
            <FileText size = '40' className="mt-4 mb-4" />
            <h5>드롭하여 파일 업로드</h5>
          </> 
          : 
          <>
            <FileText size = '40' className="mt-4 mb-4" />
            <h6>지원되는 파일: .xlsx </h6>
            <h5>클릭 또는 드래그 앤 드롭하여 파일 업로드</h5>
          </> 
        }
        </div>
    </Card>
  );
};

export default UploadFile;

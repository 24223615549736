import React, { useEffect, useState, useContext } from "react";

// parts
import TextEditor from "./TextEditor";

// Wrapper
// import Wrapper from "../helpers/Wrapper";

// react-bootstrap
import { Table, Spinner } from "react-bootstrap";
// context
import EditorDataContext from "../contexts/EditorDataContext";
// get file extention
import { getFileExt } from "../../upload/utils/utils";

const HasStyleExt = ["docx", "pdf", "tmx"];

const TextEditors = (props) => {
    const edCtx = useContext(EditorDataContext);

    const fileName = edCtx.fileName;
    const sourceSentence = edCtx.sourceSentence;
    const targetSentence = edCtx.targetSentence;
    const sourceStyles = edCtx.sourceStyleDict;
    const targetStyles = edCtx.targetStyleDict;
    const modifiedOrNotDict = edCtx.modifiedOrNotDict;
    const checkedOrNotDict = edCtx.checkedOrNotDict;
    const editorStates = edCtx.editorStates;
    const tmxMatch = edCtx.tmxMatch;
    const stsScoreDict = edCtx.stsScoreDict;
    const glossaryMatch = edCtx.glossaryMatch;
    const glossaryMatchIndex = edCtx.glossaryMatchedIndex;
    const accessibilities = edCtx.targetSentenceAccessibility;
    const linesNumber = edCtx.linesNumber;
    const glossaryActive = edCtx.glossaryActive;
    const currentActive = edCtx.currentActive;
    const nonMemberLimitLine = edCtx.nonMemberLimitLine;

    const [hasStyle, setHasStyle] = useState(false);

    let lineNumber = 1;

    // console.log(sourceSentence.length);
    // console.log(targetSentence.length);
    // console.log(glossaryMatchIndex)
    // console.log(glossaryMatch)
    // console.log(tmxMatch)

    useEffect(() => {
        fileName && setHasStyle(HasStyleExt.includes(getFileExt(fileName)));
    }, [fileName]);

    return (
        <Table
            striped
            size="sm"
            style={{
                tableLayout: "fixed",
            }}
        >
            <tbody>
                {sourceSentence.map((srcLine, index) =>
                    srcLine && srcLine !== "<p><br></p>"
                        ? ((props.ownerId === "nonMember" &&
                            index < nonMemberLimitLine + 1) ||
                            props.ownerId !== "nonMember") && (
                            <React.Fragment key={index}>
                                <TextEditor
                                    index={index}
                                    lineNumber={lineNumber++}
                                    fileName={fileName}
                                    currentActive={currentActive}
                                    srcLine={sourceSentence[index]}
                                    tgtLine={targetSentence[index]}
                                    srcStyle={sourceStyles[index]}
                                    tgtStyle={targetStyles[index]}
                                    accessibility={
                                        accessibilities[index]
                                            ? accessibilities[index]
                                            : "nonMember"
                                    }
                                    tmxmatch={tmxMatch[index] ? tmxMatch[index] : "empty"}
                                    glossaryMatch={glossaryMatch[index]}
                                    srcGlossary={
                                        glossaryMatchIndex.length > 0
                                            ? glossaryMatchIndex[0][index]
                                            : []
                                    }
                                    tgtGlossary={
                                        glossaryMatchIndex.length > 0
                                            ? glossaryMatchIndex[1][index]
                                            : []
                                    }
                                    score={stsScoreDict[index] || null}
                                    changeLines={props.changeLines}
                                    changeLine={props.changeLine}
                                    editorState={editorStates[index]}
                                    onEditorStateHandler={props.onEditorStateHandler}
                                    modifiedOrNot={modifiedOrNotDict[index]}
                                    checkOrNot={checkedOrNotDict[index]}
                                    onModifedOrNotHandler={props.onModifedOrNotHandler || null}
                                    onCheckedOrNotHandler={props.onCheckedOrNotHandler || null}
                                    getSTSScore={props.getSTSScore}
                                    hasStyle={hasStyle}
                                    nonMemberLimitLine={nonMemberLimitLine}
                                    glossaryActive={glossaryActive}
                                    onlyInspection={props.onlyInspection ? true : false}
                                    onSaveButton={props.onSaveButton}
                                    saveToDB={props.saveToDB}
                                    ownerId={props.ownerId}
                                    limitedOptions={props.limitedOptions}
                                    editorType={props.editorType}
                                    onLineSelect={props.onLineSelect}
                                    setGlossaryData={props.setGlossaryData}
                                    setGlossaryActive={props.setGlossaryActive}
                                    setOnRightClickMenu={props.setOnRightClickMenu}
                                    setXyPosition={props.setXyPosition}
                                />
                            </React.Fragment>
                        )
                        : null
                )}
                {!props.completion && (
                    <tr index={linesNumber} ref={props.observerTarget}>
                        <td colSpan="2" className="text-center">
                            <Spinner animation="border" variant="primary" />
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default TextEditors;

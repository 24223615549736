import { getFileExt } from './utils'

const ALLOWFILEEXT = ['docx', 'pdf', 'srt', 'smi', 'txt', 'xlsx']

const checktoValidFile = (files, allowFileExt = ALLOWFILEEXT) => {
    const allowFiles = files.filter(
        file =>
            allowFileExt.includes(getFileExt(file.name))
    )
    if (allowFiles) {
        if (allowFiles.length !== files.length) {
            // alert(`Exists not supported extension in uploaded files.`)
            alert('지원하지 않는 파일이 있습니다.')
        }
    } else {
        alert('지원하지 않는 파일이 있습니다.')
    }
    return allowFiles
};

export default checktoValidFile;
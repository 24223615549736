import React, { useState } from "react";
import { Card } from "react-bootstrap";


// WebEditor/UI
// import DropdownList from '../../WebEditor/UI/DropdownList';

import UploadModal from "./Upload.Modal";
import UploadCard from "./Upload.part";
import UploadFileList from "./Upload.Filelist";

// // file icon
// import docIcon from "../icon/docIcon.png"
// import pdfIcon from "../icon/pdfIcon.png"

import DocumentProvider from "../context/fileProvider";

import { BACKEND } from "../../../Constants";

// file upload process modal
import LoadingModal from "../../WebEditor/progress/LoadingModal";
import translationGIF from "../../WebEditor/progress/Translation.gif";

// resource monitor
import Monitor from "../../dashboard/monitor/Index";

const baseURL = BACKEND.SERVER;

const UploadFile = (props) => {
  const [showModal, setShowModal] = useState(false);
  const modalClose = () => setShowModal(false);
  const modalShow = () => setShowModal(true);

  return (
    <React.Fragment>
      <LoadingModal 
        children = '업로드 중'
        gif={translationGIF}
        show = {showModal}
      />
      <DocumentProvider>
        <Card>
          <Card.Header>
            <UploadCard 
              modalClose = {modalClose}
              modalShow = {modalShow}
            />
            {/* <Button 
              onClick={modalShow}>
              업로드
            </Button> */}
          </Card.Header>
          <Monitor />
          <Card.Body>
            <UploadFileList
              modalClose = {modalClose}
              modalShow = {modalShow} 
            />
          </Card.Body>
        </Card>
        {/* <UploadModal 
          show = {showModal}
          modalClose = {modalClose}
          modalShow = {modalShow} /> */}
      </DocumentProvider>
    </React.Fragment>
  )
}

export default UploadFile;

import React, { useRef, useContext, useState } from 'react';
import axios from 'axios';

import { 
  Button, Modal, Card, Col, Alert,
  Row, Form } from 'react-bootstrap';

// contexts
import GlossaryContext from '../contexts/Glossary-context';

import { BACKEND } from '../../../../Constants';

const CreateGlossary = (props) => {
  const gsCtx = useContext(GlossaryContext);
  const gsName = useRef();
  
  const userInfo  = localStorage.getItem('userInfo');
  const userEmail = userInfo ? JSON.parse(userInfo).userEmail :'';
  const userId    = userInfo ? JSON.parse(userInfo).userUid: '';
  const handleClose = () => props.onCreateGlossary(false);
  const handleShow = () => props.onCreateGlossary(true);
  const [createButtonActive, setCreateButtonActive] = useState(true);

  const onChangeHandler = (event) => {
    const fileExist = gsCtx.items.findIndex((item) => 
      item.filename == event.target.value);
    if (fileExist == -1){
      setCreateButtonActive(true);
    } else {
      setCreateButtonActive(false);
    }
  };

  const onCreateButton = (event) => {
    const filename = gsName.current.value == ''
      ?`glossary_${gsCtx.items.length}`
      :gsName.current.value;
    
    const [date, times] = props.getDateTime();
    const item = {
      'filename': filename,
      'filesize': null,
      'owner': userEmail,
      'user_no':userId,
      'project_no': props.project_no,
      'uploaded': `${date} ${times}`,
    };
    gsCtx.addItem(item);
    const data = new FormData();
    data.append('project_no', props.project_no);
    data.append('owner', userId);
    data.append('user_no', userId);
    data.append('fileName', filename);
    data.append('process', 'glossary.addFile')
    
    axios.put(`${BACKEND.SERVER}/files`, data
      ).then((response) => {
        handleClose();
      })
    
  };


  return (
    <Modal show={props.createGlossary} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>용어집 생성</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="glossary">용어집 이름</Form.Label>
                <Form.Control
                  type="text"
                  name="text"
                  id="glossary"
                  placeholder="glossary"
                  ref = {gsName}
                  onChange = {onChangeHandler}
                  isInvalid = {!createButtonActive}
                />
              </Form.Group>
            </Col>
          </Row>
          {!createButtonActive && <Row className = 'm-0 align-middle'>
            <Alert variant="danger" className='m-0'>
              <p className='pl-2 m-0'>
                동일한 용어집 이름이 있습니다.
              </p>
            </Alert>
          </Row>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          닫기
        </Button>
        <Button 
          variant="primary" 
          onClick={onCreateButton}
          disabled = {!createButtonActive}
        >
          저장
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default CreateGlossary;
import React, { useState, useEffect } from "react";

// UI
import ToggleButton from "../UI/ToggleButton";

const STSCheck = (props) => {
    const [checkName, setCheckName] = useState('일괄 재검수 필요');
    const [variant, setVariant] = useState('outline-light');
    
    const onCheckHandler = (event) => {
        event.preventDefault();
        props.onCheckHandler(event);
        scoreHandler(props);
    };

    useEffect(() => {
        scoreHandler(props);
    },);

    const scoreHandler = (props) => {
        if (props.score !== null && props.score > 0) {
            var score = parseFloat(props.score);
            if (score > 90) {
                // blue
                if (props.editorState === 'webEditor'){
                    setVariant('outline-primary')
                } else {
                    setVariant('primary')
                }
            }
            else if (score <= 90 && score > 80) {
                // green
                if (props.editorState === 'webEditor'){
                    setVariant('outline-success')
                } else {
                    setVariant('success')
                }
            }
            else if (score <= 80 && score > 60) {
                // yellow
                if (props.editorState === 'webEditor'){
                    setVariant('outline-warning')
                } else {
                    setVariant('warning')
                }
            }
            else {
                // red
                if (props.editorState === 'webEditor'){
                    setVariant('outline-danger')
                } else {
                    setVariant('danger')
                }
            }
            setCheckName(`번역 정확도 ${props.score}%`);
        }
        else {
            if (props.editorState === 'webEditor'){
                setVariant('outline-info')
            } else {
                setVariant('info')
            }
            setCheckName('검수 하기');
        }
    };
    
    return (
        <ToggleButton 
            className = {checkName === '검수 하기' ?'pb-0 pt-0' :'p-0'}
            id = {props.id}
            onClick = {onCheckHandler}
            variant = {variant}
            name = {checkName}
            disabled = {props.disabled}
            style = {{
                width: '100%',
                cursor: props.disabled ?'not-allowed' :'pointer'}}
        />
    )
};

export default STSCheck;
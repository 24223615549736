import axios from "axios";
import { BACKEND } from "../../Constants";
import React, { useContext, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import AddFile from "./AddFile";
import InputForm from "./InputForm";
import AuthContext from "../../store/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const EditProfile = ({ history }) => {
    const authCtx = useContext(AuthContext);
    const username = authCtx.username;
    const useremail = authCtx.useremail;
    const token = authCtx.token;

    const [title, setTitle] = useState("");
    const [comment, setComment] = useState("");
    const [isError, setIsError] = useState(false);

    const titleChange = (data) => {
        setTitle(data);
    };

    const handleSubmit = () => {

        if (title.trim() === "") {
            alert("닉네임을 입력해주세요.");
        } else {
            const data = {
                name: title,
            };
            console.log(data);
            axios
                .post(BACKEND.SERVER + "/name", data, {
                    headers: {
                        "Authorization": `JWT ${token}`
                    }
                })
                .then((res) => {
                    if (res.statusText !== "OK") {
                        alert(res.status);
                    } else if (res.statusText === "OK") {
                        if (typeof (res.data.message) !== 'undefined') {
                            setIsError(true);
                            setComment(res.data.message);
                        }
                        else {
                            authCtx.name(res.data.username, useremail);
                            setIsError(false);
                            alert("닉 네임이 변경됐습니다.");
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3"><FontAwesomeIcon className="mr-4" id="left" icon={faArrowLeft} onClick={() => {
                history.push("/profile");
            }} />프로필 편집</h1>
            <AddFile token={token} />
            <InputForm
                title={username}
                titleChange={(e) => {
                    titleChange(e);
                }}
            />
            <Row>
                <Col md="4" xl="3">
                    {isError && <div>{comment}</div>}
                    <Button type="submit" color="primary" className="mr-4 float-right" onClick={handleSubmit}>
                        저장하기
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default EditProfile;

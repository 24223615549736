import React from "react";

const TextAreaTarget = (props) => {
  const glossaryData = props.glossaryData;

  const getIntersection = (array1, array2, unique = true) => {
    const intersection = array1.filter(element => array2.includes(element));
    if (unique) {
      return [...new Set(intersection)];
    }
    return intersection;
  };
  const getMaximumSpan = (span, array) => {
    const selectedSpan = span.filter((value, index) => array.includes(index));
    const spanRange = [];
    selectedSpan.map((value) => {
      spanRange.push(value[1]-value[0])
    });
    const maxRange = Math.max(...spanRange);
    const index = selectedSpan.indexOf(maxRange);
    
    if (index < 0) {
      return 0;
    }
    return index;
  }

  const glossaryOnHandler = (activeList) => {
    props.setGlossaryActive(activeList);
  };

  const TextSpan = (props) => {
    return (
      <span
        style = {{
          backgroundColor: props.bgColor,
          cursor: 'pointer'
        }}
        onClick = {() => {glossaryOnHandler(props.glossary_index)}}
      >
        {props.text}
      </span>
    )
  };

  if (glossaryData) {
    if (glossaryData.length > 0) {
      return (
        <div>
          {glossaryData.map((value, index) => {
            const intersection = value[1]
              ?getIntersection(value[1].glossary_index, props.glossaryActive)
              :[];
            const activeSpan = (value[1] && props.index === props.currentActive)
              ?getMaximumSpan(value[1].glossary_span, intersection)
              :0;
            const activeAlpha = (value[1] && props.index === props.currentActive)
              ?0.2*intersection.length >= 0.6
                ?0.6
                :0.2 + 0.2*intersection.length
              :0.2;

            return (
              value[1]
              ?<span key = {index}>
                {value[1].glossary_span[activeSpan][0] !== 0 && <TextSpan 
                  bgColor = {`rgba(250, 108, 108, ${activeAlpha})`}
                  glossary_index = {value[1].glossary_index}
                  text = {value[0].slice(
                    0, 
                    value[1].glossary_span[activeSpan][0])}
                />}
                <TextSpan 
                  bgColor = {`rgba(108, 108, 250, ${activeAlpha})`}
                  glossary_index = {value[1].glossary_index}
                  text = {value[0].slice(
                    value[1].glossary_span[activeSpan][0], 
                    value[1].glossary_span[activeSpan][1])}
                />
                {value[1].glossary_span[activeSpan][1] !== value[0].length && <TextSpan 
                  bgColor = {`rgba(250, 108, 108, ${activeAlpha})`}
                  glossary_index = {value[1].glossary_index}
                  text = {value[0].slice(
                    value[1].glossary_span[activeSpan][1],
                    )}
                />}
              </span>
              :<span key = {index}>
                {value[0]}
              </span>
            )
          })}
        </div>
      )
    }
  }
  return props.text
};

export default TextAreaTarget;

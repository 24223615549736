import React, { useCallback } from 'react';

import { Card } from "react-bootstrap";
import { FileText } from 'react-feather';
import { useDropzone } from 'react-dropzone';

import checktoValidFile from '../../../upload/utils/checkValidExt';

const UploadFile = (props) => {
  const onDrop = useCallback(uploadedFiles => {
    const files = checktoValidFile(uploadedFiles, ['xlsx'])
    props.setFiles(files);
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Card style = {{width:'100%'}}>
      <div 
        {...getRootProps()}
        style={{
          'border': '2px dashed',
          'textAlign': 'center'}}>
      <input {...getInputProps()} />
      {isDragActive 
        ? 
        <>
          <FileText size = '30' className="mt-2 mb-2" />
          <h5>드롭하여 파일 업로드</h5>
        </> 
        : 
        <>
          <FileText size = '30' className="mt-2 mb-2" />
          <h6>지원되는 파일: .xlsx </h6>
          <h5>클릭 또는 드래그 앤 드롭하여 파일 업로드</h5>
        </> 
      }
      </div>
    </Card>
  )

};

export default UploadFile;
import React, { useCallback, useRef, useState, useContext, useEffect, useReducer } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import LongPressable from 'react-longpressable';
import ScrollToBottom from "react-scroll-to-bottom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../../store/auth-context";
import { faAngleUp, faAngleDown, faArrowLeft, faSearch, faCheck, faCheckDouble, faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import "./Minimal.css";
import axios from "axios";
import { BACKEND } from "../../Constants";
import {
    Button,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Media,
} from "reactstrap";

const defaultMessage = {
    room: '',
    author: '',
    nick: '',
    message: '',
    time: '',
    ref: 0
}

const styleg = { "color": "#cccccc" };
const styleb = { "color": "#000000" };
const stylec = { cursor: 'pointer' };
const stylet = {
    resize: 'none',
    overflow: 'hidden'
};

const reducer = (state, action) => {
    let updatedItems;
    if (action.type === 'ADD') {
        // updatedItems = action.item.concat(state);
        updatedItems = state.reverse().concat(action.item).reverse();
        return updatedItems;
    }
    else if (action.type === 'MORE') {
        //    updatedItems = state.concat(action.item);
        updatedItems = [...new Set([...state, ...action.item])];
        return updatedItems;
    }
    else if (action.type === 'READ') {
        return action.item;
    } else if (action.type === 'DELETE') {
        updatedItems = state.filter((val) => {
            if (!action.item.includes('' + val.uid)) {
                return val
            }
        })
        return updatedItems;
    } else if (action.type === 'CHECK') {
        let unreadMessageIndices = state.reduce(
            (c, v, i) => v.ref === 0 ? c.concat(i) : c, []
        );
        // console.log(unreadMessageIndices);
        updatedItems = [...state];
        unreadMessageIndices.forEach(unreadMessageIndex => {
            let unreadMessage = state[unreadMessageIndex];
            if (unreadMessage) {
                const updatedItem = {
                    ...unreadMessage,
                    ref: 1
                }
                updatedItems[unreadMessageIndex] = updatedItem;
            }
        }
        )
        return updatedItems;
    } else if (action.type === 'SAVE') {
        let unsavedMessageIndices = state.reduce(
            (c, v, i) => v.uid === 0 ? c.concat(i) : c, []
        );
        // console.log("SAVE", unsavedMessageIndices);
        updatedItems = [...state];
        unsavedMessageIndices.forEach(unsavedMessageIndex => {
            let unsavedMessage = state[unsavedMessageIndex];
            if (unsavedMessage) {
                const updatedItem = {
                    ...unsavedMessage,
                    uid: action.item.uid
                }
                updatedItems[unsavedMessageIndex] = updatedItem;
            }
        }
        )
        return updatedItems;
    } else if (action.type === 'APPROVE') {
        let approvedMessageIndices = state.reduce(
            (c, v, i) => v.uid === action.item.uid ? c.concat(i) : c, []
        );
        // console.log("APPROVE", approvedMessageIndices);
        updatedItems = [...state];
        approvedMessageIndices.forEach(approvedMessageIndex => {
            let approvedMessage = state[approvedMessageIndex];
            if (approvedMessage) {
                const updatedItem = {
                    ...approvedMessage,
                    approve: action.item.approve
                }
                updatedItems[approvedMessageIndex] = updatedItem;
            }
        }
        )
        return updatedItems;
    }
    return defaultMessage;
}

function getHighlightedText(text, highlight) {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> {parts.map((part, i) =>
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { backgroundColor: '#000000', color: '#ffffff' } : {}}>
            {part}
        </span>)
    } </span>;
}

const ChatMessages = (props) => {
    const textAreaRef = useRef();
    const history = useHistory();
    const location = useLocation();

    const authCtx = useContext(AuthContext);

    const socket = authCtx.socket;
    const token = authCtx.token;

    const { readRoom, searchTerm, setSearchTerm, setShowChat, who, username, useremail, room, roomRef } = props;
    var ids = room.split('|');
    var id2 = '';
    if (useremail === ids[0])
        id2 = ids[1];
    else id2 = ids[0];

    const [refresh, setRefresh] = useState();

    const [isOnline, setIsOnline] = useState(true);
    const [upGray, setUpGray] = useState(false);
    const [downGray, setDownGray] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    // const [searchTerm, setSearchTerm] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [checkedItems, setCheckedItems] = useState([]);
    const [messageList, dispatchMessageAction] = useReducer(reducer, []);
    const [rows, setRows] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchTotal, setSearchTotal] = useState(0);


    const observer = useRef();
    const lastMessageElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    const countMessage = () => {
        const messageData = {
            room: room,
            author: useremail,
            search: searchTerm,
        };
        // console.log("count", messageData);
        socket.emit('count', messageData);
    }

    const loadMore = () => {
        // console.log("ask for more", selected, pageNumber, messageList.length);
        const messageData = {
            room: room,
            author: useremail,
            nick: username,
            page: pageNumber,
        };

        socket.emit('more', messageData, (response) => {
            // console.log("RECEIVED");
            moreMessages(response.messages);
            setHasMore(response.messages.length > 0);
            setLoading(false);
        });
    }
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        // console.log(checkedItems, event.target.value);
        if (event.target.checked) {
            setCheckedItems([...checkedItems, event.target.value]);
        } else {
            setCheckedItems(checkedItems.filter((el) => el !== event.target.value));
        }
    };
    const onDelete = (e) => {
        removeMessages(checkedItems);
    }
    const LongPress = (key) => {
        setShowCheckbox(true);
        setCheckedItems([...checkedItems, '' + key]);
        console.log('Long pressed.', key);
    }

    const ShortPress = (e) => {
        console.log('Short pressed.');
    }

    const updateNotifications = () => {
        const data = {
            member: useremail
        };
        axios
            .post(BACKEND.SERVER + "/messages", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    authCtx.setMessages(res.data);
                    localStorage.setItem("messages", JSON.stringify(res.data));
                    // console.log("update: ", res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const addMessage = (item) => {
        dispatchMessageAction({ type: 'ADD', item: item });
        if (messageList.length == 0) readRoom(); // 처음 채팅
    }
    const oldMessages = (item) => {
        dispatchMessageAction({ type: 'READ', item: item });
        updateNotifications();
    }
    const moreMessages = (item) => {
        dispatchMessageAction({ type: 'MORE', item: item });
    }
    const checkMessages = (item) => {
        dispatchMessageAction({ type: 'CHECK', item: item });
    }
    const saveMessages = (item) => {
        dispatchMessageAction({ type: 'SAVE', item: item });
    }
    const deleteMessages = (item) => {
        dispatchMessageAction({ type: 'DELETE', item: item });
    }
    const approveMessages = (item) => {
        dispatchMessageAction({ type: 'APPROVE', item: item });
    }

    const removeMessages = async () => {
        if (checkedItems !== "") {
            const messageData = {
                room: room,
                author: useremail,
                nick: username,
                message: checkedItems,
                ref: 0
            };
            await socket.emit("remove_messages", messageData);
            deleteMessages(checkedItems);
            setCheckedItems([]);
            setShowCheckbox(false);
        }
    };

    const sendMessage = async () => {
        var icon = faCommentSlash;
        if (currentMessage !== "") {
            var condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                // fetch('https://davinci.financial', { // Check for internet connectivity
                fetch('https://thinkcat.tools', {
                    mode: 'no-cors',
                })
                    .then(() => {
                        setCurrentMessage("");
                        setRows(1);
                    }).catch(() => {
                        console.log("nope@!")
                        setIsOnline(false);
                        icon = faCommentSlash;
                    })

            } else {
                setIsOnline(false);
                icon = faCommentSlash;
            }
            var pre_time = 0;
            if (messageList.length > 0) pre_time = messageList[messageList.length - 1].time;
            const messageData = {
                uid: 0,
                room: room,
                author: useremail,
                nick: username,
                nick2: who.name,
                message: currentMessage,
                pre_time: pre_time,
                time: Math.round((new Date()).getTime() / 1000),
                ref: 0,
                icon: icon,
            };
            await socket.emit("send_message", messageData);
            // console.log("messageData", messageData)
            addMessage(messageData);
        }
    };

    const readMessage = () => { // 수신자 입장에서 읽었다고 보냄(자동)
        const messageData = {
            room: room,
            author: useremail,
            nick: username,
            message: '',
            ref: 1,
        };
        socket.emit("read_message", messageData); // 요기
    };

    const approveMessage = (data) => { // 수신자 입장에서 읽었다고 보냄(자동)
        const uid = data.uid;
        const approve = data.approve;

        const messageData = {
            room: room,
            author: useremail,
            uid: data.uid,
            approve: data.approve
        };

        socket.emit("approve_message", messageData);
    };

    const filteredMessages = messageList.filter((val) => {
        if (searchTerm === "") return ''
        else if (val.message.toLowerCase().includes(searchTerm.toLowerCase())) {
            return val
        }
    });

    const refs = messageList.reduce((acc, value) => {
        acc['' + value.uid] = React.createRef();
        return acc;
    }, {});

    const find = (index) => {
        const filtered = messageList.filter((val) => {
            if (searchTerm === "") return ''
            else if (val.message.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val
            }
        });
        return filtered[index];
    }

    const goto = (index) => {
        var it = find(index);
        if (typeof (it) !== 'undefined') {
            var id = it.uid;

            refs[id].current.scrollIntoView({
                behavior: 'auto',
                block: 'nearest'
            })
        } else {
            setPageNumber(prevPageNumber => prevPageNumber + 1)
        }
    }

    const handleClick = (direction) => {
        let i = selected;
        if (direction > 0) i = i + 1;
        else i = i - 1;

        if (i > searchTotal - 1) {
            i = searchTotal - 1;
        }
        else if (i < 0) {
            i = searchTotal - 1;
            i = 0;
        }
        else {
            setSelected(i);
            goto(i);
        }

    };

    const calDate = (d) => {
        var date = new Date(d * 1000);
        var week = ['일', '월', '화', '수', '목', '금', '토'];
        var dayOfWeek = week[date.getDay()];

        var time = date.getFullYear() + '년 ' +
            ('00' + (date.getMonth() + 1)).slice(-2) + '월 ' +
            ('00' + date.getDate()).slice(-2) + '일 ' +
            dayOfWeek + '요일';
        return time;
    }

    const calTime = (d) => {
        var date = new Date(d * 1000);
        var time = ('00' + date.getHours()).slice(-2) + ':' +
            ('00' + date.getMinutes()).slice(-2) + ':' +
            ('00' + date.getSeconds()).slice(-2);
        return time;
    }

    const handleWrite = (event) => {
        setRows(1);
        setCurrentMessage(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        countMessage();
        setSelected(0);
        goto(0);
    }

    useEffect(() => {
        const height = textAreaRef.current.scrollHeight;
        const rowHeight = 20;
        const trows = Math.ceil(height / rowHeight) - 1;
        if (trows > 4) {
            setRows(4);
        }
        else if (trows > 1 && trows < 5) {
            setRows(trows);
        }
        else {
            setRows(1);
        }
        //		setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }, [currentMessage]);

    useEffect(() => {
        if (searchTotal <= 1) {
            setUpGray(true);
            setDownGray(true);
        }
        else if (selected == 0 && searchTotal > 1) {
            setDownGray(true);
            setUpGray(false);
            // console.log("setUpGray false");
        }
        else if (selected == searchTotal - 1 && searchTotal > 1) {
            setUpGray(true);
            setDownGray(false);
        }
        else {
            setUpGray(false);
            setDownGray(false);
            // console.log("setUpGray false2");
        }
    }, [selected, searchTerm, searchTotal]);

    useEffect(() => {
        setSearchTerm("");
        setShowSearch(false);
        setSearchTotal(0);
        setLoading(true);
        socket.on("old_messages", (data) => {
            oldMessages(data);
            setPageNumber(1);
            setHasMore(data.length > 0);
            setLoading(false);
        });
    }, [room]);

    useEffect(() => {
        // console.log("Page: ", pageNumber, messageList.length);
        setLoading(true);
        setError(false);
        // socket.on("more_messages", (data) => {
        //        moreMessages(data);
        //	setHasMore(data.length > 0);
        //	setLoading(false);
        //  });
        loadMore();
        if (showSearch) {
            //			goto(selected);
            // console.log("goto index", selected);
        }
    }, [pageNumber]);

    useEffect(() => {
        if (searchTerm != '') {
            // setShowSearch(true);
            // setSelected(0);
            goto(selected);
        }
    }, [messageList]);


    useEffect(() => {
        socket.on("receive_message", (data) => { // 받을 때
            // console.log("받음", data.room, roomRef.current);
            if (data.room === roomRef.current) { // 여기가 중요, Ref 쓰는 이유
                //console.log("받음", data.room, roomRef.current);
                addMessage(data);
            } else {
                //console.log("안받음", data.room, roomRef.current);
            }
        });
        socket.on("count_message", (data) => {
            // console.log("총", data);
            setSearchTotal(data);
        });
        socket.on("check_message", (data) => { // 상대가 읽었을 때
            checkMessages(data);
        });
        socket.on("saved_message", (data) => { //원격 서버에서 저장이 디었을 때 (on은 수동적인 것) 수신할 신호에 대한 
            saveMessages(data);
            // setWhichIcon(faCheck);
            // console.log("saved", data);
        });
    }, [socket]);

    return (
        <Col lg={7} xl={9} className="chat-window">
            <form onSubmit={handleSubmit}>
                <div className="py-2 px-4 border-bottom">
                    <Media className="align-items-center py-1">
                        {showSearch ?
                            (<span><FontAwesomeIcon className="mr-4" id="left" icon={faArrowLeft} onClick={() => {
                                setShowSearch(false);
                                setSearchTerm("");
                            }} />
                                <input className="chat-search" type="text" value={searchTerm} placeholder="검색..." autoFocus
                                    onChange={handleChange} /></span>)
                            : (!showCheckbox ? (<span><FontAwesomeIcon className="mr-4" id="left" icon={faArrowLeft} onClick={() => {
                                setShowChat(false);
                            }} />{who.name}님과의 대화
        &nbsp;<FontAwesomeIcon id="search" style={stylec} icon={faSearch} onClick={() => { setShowSearch(true) }} /></span>) : (<span><FontAwesomeIcon className="mr-4" id="left" icon={faArrowLeft} onClick={() => {
                                    setShowCheckbox(false);
                                    setCheckedItems([]);
                                }} />
        &nbsp;<button onClick={onDelete} >삭제</button></span>))}
                    </Media>
                </div></form>

            <div className="position-relative">
                <div className="chat-messages chat-body">
                    <div className="d-flex flex-column-reverse message-container">
                        {messageList.map((messageContent, index) => {
                            return (<div key={index} className="row1">
                                {messageList.length == index + 1 ? (<div ref={lastMessageElementRef}></div>) : ''}
                                <div>{loading && 'Loading...'}</div>
                                <div>{error && 'Error'}</div>

                                <div className="cleft" id={showCheckbox ? "checkyes" : "checkno"}><input type="checkbox" checked={checkedItems.includes('' + messageContent.uid) ? true : false} key={index} value={'' + messageContent.uid} onChange={handleCheckboxChange} /></div><div className="cright">
                                    {calDate(messageContent.pre_time) != calDate(messageContent.time) &&
                                        <div className="text-center"><Button
                                            key={index}
                                            color="secondary"
                                            className="btn-pill mr-1 mb-1"
                                            size="sm"
                                            disabled
                                        >
                                            {calDate(messageContent.time)}</Button></div>}
                                    <div className="message"
                                        key={index}
                                        ref={refs['' + messageContent.uid]}
                                        id={useremail === messageContent.author ? "you" : "other"} >
                                        <LongPressable
                                            onShortPress={ShortPress}
                                            onLongPress={() => LongPress('' + messageContent.uid)}
                                            longPressTime={700}>
                                            <div className="message-content">
                                                {useremail !== messageContent.author
                                                    && (messageContent.ref > 0 ? '' : readMessage())}
                                                {/* 확인 */}
                                                {showSearch ? <p style={{ whiteSpace: 'pre-line' }}>{getHighlightedText(messageContent.message, searchTerm)}</p>
                                                    : <p style={{ whiteSpace: 'pre-line' }}>{messageContent.message}

                                                    </p>
                                                }
                                            </div>
                                        </LongPressable>
                                        <div className="message-meta">
                                            <p id="time">{calTime(messageContent.time)}</p>
                                            {useremail !== messageContent.author && (<p id="author">{messageContent.nick}</p>)}
                                            {useremail === messageContent.author && (messageContent.ref > 0 ? <FontAwesomeIcon id="check" icon={faCheckDouble} /> : (messageContent.uid > 0 ? <FontAwesomeIcon id="check" icon={faCheck} /> : (typeof (messageContent.icon) !== 'undefined' ? <FontAwesomeIcon id="check" icon={messageContent.icon} /> : <FontAwesomeIcon id="check" icon={faCommentSlash} />)))}
                                        </div>
                                    </div>
                                </div></div>)
                        })}
                    </div>
                </div>
            </div>

            <div className="chat-footer flex-grow-0 py-3 px-4 border-top">
                {showSearch ? (
                    <><span className="float-right">
                        <FontAwesomeIcon id="up" style={upGray ? styleg : styleb} icon={faAngleUp} fixedWidth onClick={() => handleClick(1)} />
                        {"  "}
                        <FontAwesomeIcon id="down" style={downGray ? styleg : styleb} icon={faAngleDown} fixedWidth onClick={() => handleClick(-1)} />
                    </span>
                        <Media body className="ml-1">
                            검색결과:{"  "} {searchTotal}개 중 {searchTotal > 0 ? selected + 1 : 0}번째</Media>
                    </>
                )
                    : (
                        <InputGroup>
                            <Input type="textarea"
                                innerRef={textAreaRef}
                                style={stylet}
                                rows={rows}
                                placeholder="메시지를 입력하세요."
                                value={currentMessage}
                                onChange={handleWrite}
                            />
                            <InputGroupAddon addonType="append">
                                <Button onClick={sendMessage} color="primary">&#9658;</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    )}
            </div>
        </Col>
    );
};

export default ChatMessages;

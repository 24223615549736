import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Button, Card, CardBody } from "reactstrap";
import { Home, ArrowLeft } from "react-feather";
import AuthContext from '../../store/auth-context';
import useInput from "./SignInput";

import Axios from "axios";
import { BACKEND } from "../../Constants";

import WithdrawalModal from "./WithdrawalModal"

const Withdrawal = () => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const username = authCtx.username;

    const [isLoading, setIsLoading] = useState(false);
    const [check, setCheck] = useState(false)
    const [possibleStatus, setPossibleStatus] = useState(2)
    const [myData, setMyData] = useState({})

    const [passwordType, setPasswordType] = useState(false);
    const passwordInputRef = useRef(null);
    const is_Password = (value) => value.trim() !== "";
    const {
        value: password_Value,
        isValid: password_IsValid,
        hasError: password_HasError,
        valueChangeHandler: password_ChangeHandler,
        inputBlurHandler: password_BlurHandler,
        reset: reset_Password,
    } = useInput(is_Password);
    const password_Classes = password_HasError ? "form-control invalid" : "form-control";

    const onCheckedSingleBox = useCallback(
        (checked) => {
            setCheck(checked)
        })

    const chkHandler = (event) => {
        event.preventDefault();

        var enteredPassword = passwordInputRef.current.value;
        if (enteredPassword) {
            let url = BACKEND.SERVER + "/chk-withdrawal";
            let data = {
                password: enteredPassword
            }
            setIsLoading(true);
            Axios.post(url, data, {
                headers: {
                    Authorization: `JWT ${token}`,
                }
            })
                .then((response) => {
                    // 0 : 탈퇴 가능, 1 : 탈퇴 불가능
                    if (response.data.message["possible_status"] === -1) {
                        alert("비밀번호를 확인해주세요")
                    }
                    setMyData(response.data)
                    setPossibleStatus(response.data.message["possible_status"])
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log(error.data)
                })

        }
    }

    const closeModal = () => {
        setPossibleStatus(2)
    }

    const submitHandler = (event) => {
        event.preventDefault();

        var enteredPassword = passwordInputRef.current.value;
        console.log("qt", enteredPassword)
        if (enteredPassword) {
            let url = BACKEND.SERVER + "/withdrawal";
            let data = {
                password: enteredPassword
            }
            setIsLoading(true);

            Axios.post(url, data, {
                headers: {
                    Authorization: `JWT ${token}`,
                }
            })
                .then((response) => {
                    setIsLoading(false);
                    authCtx.logout();
                    localStorage.clear();
                    alert(response.data['message']);
                    history.replace("/");
                })
                .catch((err) => {
                    setIsLoading(false);
                    alert(err.data);
                })
        } else {
            alert("비밀번호를 입력해주세요");
        }
    }

    return (
        <React.Fragment>
            <Link to={`/`} className="float-left">
                <Home />
            </Link>
            <Link onClick={() => { history.go(-1) }} className="float-left">
                <ArrowLeft />
            </Link>
            <div className="text-center mt-4">
                <h2 className="text-center">회원탈퇴</h2>
                <p className="lead">회원탈퇴를 신청하기 전에 안내 사항을 꼭 확인해주세요.</p>
            </div>

            <Card>
                <CardBody>
                    {possibleStatus === 0 ? <WithdrawalModal closeModal={closeModal} >
                        <div className="mb-4">
                            <section>
                                <h3 className="text-center">안내</h3>
                                <div className="mb-4 text-center">
                                    사용하고 계신 아이디(이하 : {username})는 회원 <b>탈퇴가 가능</b>합니다. 계속하시려면 아래 버튼을 클릭해주세요.
                                </div>
                                <div>
                                    <h4 className="text-center">계정 탈퇴 조건</h4>
                                    <div className="text-center">
                                        사용하고 계신 아이디(이하 : {username})를 탈퇴할 경우 <b>재사용 및 복구가 불가능</b>합니다.
                                        <br />탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가하오니 신중하게 선택하시기 바랍니다.<br />
                                        <br />탈퇴 후 회원정보 및 개인형 서비스 <b>이용기록은 모두 삭제</b>됩니다.
                                        회원정보 및 채팅기록 등 개인형 서비스 이용기록은 모두 삭제되며, <b>삭제된 데이터는 복구되지 않습니다.</b>
                                        삭제되는 내용을 확인하시고 <b>필요한 데이터는 미리 백업</b>을 해주세요.
                                    </div>
                                    <br />
                                    <div className="text-center" width="80%">
                                        <ul width="80%">
                                            <li><b>닉네임&nbsp;:&nbsp;</b>일정기간 재사용 불가</li>
                                            <li><b>게시글(이하: 프로젝트 데이터)&nbsp;:&nbsp;</b>게시글 재열람 불가 또는 삭제</li>
                                            <li><b>거래내역&nbsp;:&nbsp;</b>거래내역 재열람 불가 또는 삭제</li>
                                            <li><b>채팅&nbsp;:&nbsp;</b>채팅기록 재열람 불가 또는 삭제</li>
                                            <li><b>캐시&nbsp;:&nbsp;</b>캐시 및 구매내역 삭제</li>
                                        </ul>
                                    </div>
                                    <div className="text-center">
                                        탈퇴 후에는 아이디<b>「{username}」</b>로 다시 가입할 수 없으며 아이디와 데이터는 복구할 수 없습니다.
                                        <br />게시판형 서비스(이하: 프로젝트)에 남아 있는 게시글은 탈퇴 후 재열람 및 삭제할 수 없습니다.
                                        <br />또한, 해당 아이디를 사용해 씽캣툴즈에서 제공하는 서비스를 이용할 수 없게 됩니다.
                                    </div>

                                </div>
                            </section>
                            <Button className="m-auto" onClick={submitHandler}>탈퇴하기</Button>
                        </div>
                    </WithdrawalModal> : possibleStatus === 1 && <WithdrawalModal closeModal={closeModal}>
                        <div className="mb-4">
                            <section>
                                <h3 className="text-center">안내</h3>
                                <div className="mb-4 text-center">
                                    사용하고 계신 아이디(이하 : {username})는 회원 <b>탈퇴가 불가능</b>합니다. 아래 계정 탈퇴 조건을 확인해 주시기 바랍니다.
                                </div>
                                <div>
                                    <h4>계정 탈퇴 조건</h4>
                                    <ol
                                        style={{
                                            listStyle: "none",
                                            listStylePosition: "inside",
                                            textIndent: "-20px",
                                        }}>
                                        <li>
                                            ① 진행중인 모든 프로젝트 종료 후
                                        </li>
                                        <li>
                                            ② 모든 프로젝트 데드라인 종료 후
                                        </li>

                                    </ol>
                                </div>

                                <div>
                                    <h4>현재 계정 현황</h4>
                                    <ol
                                        style={{
                                            listStyle: "none",
                                            listStylePosition: "inside",
                                            // textIndent: "-5px",
                                        }}
                                    >
                                        <li>
                                            모든 프로젝트 : {myData && myData.message["projects"] > 0 ? `${myData.message["projects"]}개` : "없음"}
                                        </li>
                                        {myData && myData.message["ongoing_projects"].length > 0 &&
                                            <li>
                                                참여중인 프로젝트 : {myData.message["ongoing_projects"].length}개
                                            </li>}
                                        {myData.message["ongoing_projects"].map((project, index) => (

                                            <div>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>프로젝트명</th>
                                                            <th>데드라인</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>{project.title}</th>
                                                            <td>{project.deadline}</td>
                                                        </tr>
                                                    </tbody>

                                                </Table>
                                            </div>
                                        ))}

                                        <small>위 프로젝트가 모두 종료 시 탈퇴가 가능합니다.</small>

                                    </ol>
                                </div>

                            </section>
                        </div>
                    </WithdrawalModal>}
                    <div className="m-sm-4">
                        <div className="mb-4">

                            <div>
                                사용하고 계신 아이디(이하 : {username})를 탈퇴할 경우 <b>재사용 및 복구가 불가능</b>합니다.
                                    <br />탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가하오니 신중하게 선택하시기 바랍니다.<br />
                                <br />탈퇴 후 회원정보 및 개인형 서비스 <b>이용기록은 모두 삭제</b>됩니다.
                                    회원정보 및 채팅기록 등 개인형 서비스 이용기록은 모두 삭제되며, <b>삭제된 데이터는 복구되지 않습니다.</b>
                                    삭제되는 내용을 확인하시고 <b>필요한 데이터는 미리 백업</b>을 해주세요.
                            </div>
                            <br />
                            <div className="float-center" width="80%">
                                <ul width="80%">
                                    <li><b>닉네임&nbsp;:&nbsp;</b>일정기간 재사용 불가</li>
                                    <li><b>게시글&nbsp;:&nbsp;</b>게시글 재열람 불가 또는 삭제</li>
                                    <li><b>거래내역&nbsp;:&nbsp;</b>거래내역 재열람 불가 또는 삭제</li>
                                    <li><b>채팅&nbsp;:&nbsp;</b>채팅기록 재열람 불가 또는 삭제</li>
                                    <li><b>캐시&nbsp;:&nbsp;</b>캐시 및 구매내역 삭제</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            탈퇴 후에는 아이디<b>「{username}」</b>로 다시 가입할 수 없으며 아이디와 데이터는 복구할 수 없습니다.
                            <br />게시판형 서비스에 남아 있는 게시글은 탈퇴 후 삭제할 수 없습니다.
                            <br />또한, 해당 아이디를 사용해 씽캣툴즈에서 제공하는 서비스를 이용할 수 없게 됩니다.
                        </div>
                        <br />
                        <input type="checkbox" onChange={(e) => onCheckedSingleBox(e.target.checked)} />
                        &nbsp;동의합니다.
                        <br />
                        <div>
                            <input
                                className={password_Classes}
                                type={!passwordType ? "password" : "text"}
                                name="password"
                                value={password_Value}
                                onChange={password_ChangeHandler}
                                onBlur={password_BlurHandler}
                                placeholder="비밀번호 확인"
                                ref={passwordInputRef}
                                style={password_HasError ? { boxShadow: "0px 0px 3px 0.5px rgba(244,158,150,0.5)" } : { boxShadow: "" }}
                            // style={{
                            //     display: "inline-block",
                            //     // float: "left"
                            //     width: "60%"
                            // }}
                            />
                        </div>

                        {!check &&
                            <div className="text-center mt-3">
                                <Button color="primary" size="lg" outline
                                    onClick={() => alert("동의 후 진행할 수 있습니다.")}
                                >
                                    회원탈퇴
                                </Button>
                            </div>}
                        {check &&
                            <div className="text-center mt-3">
                                <Button color="primary" size="lg"
                                    onClick={chkHandler}>
                                    회원탈퇴
                                </Button>
                                {isLoading && <p>요청을 보냈습니다.</p>}
                            </div>}
                    </div>
                </CardBody>
            </Card>
        </React.Fragment >
    );
};

export default Withdrawal;

import React, { useState } from "react";

// react-bootstrap
import { Card, Form, FloatingLabel } from "react-bootstrap";

const SelectList = (props) => {
    // const [item, setItem] = useState(props.items[0]);

    const itemSelectHandler = (event) => {
        event.preventDefault();
        //console.log(event.target.value);
        props.directionSelect(event.target.value);
        // setItem(event.target.value);
    };

    return (
        <FloatingLabel label = {props.title} >
            <Form.Select 
                onChange = {itemSelectHandler}
                id = 'select-form'
                name = 'select-form'
                className = 'mr-2'
                disabled = {props.waiting}
                autoComplete='off'
            >
                <option defaultValue>{props.default}</option>
                {props.items.map((name) => (
                    name !== props.default
                    ?<option id = {name}>
                        {name}
                    </option>
                    :<></>
                    )
                )}
            </Form.Select>
        </FloatingLabel>
 
    )
    
};

export default SelectList;
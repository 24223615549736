import React,  { useState, useEffect } from 'react';
// import { Button, Col, Container, Row } from "reactstrap";
import {
  useParams, useHistory
} from "react-router-dom";

import { useDispatch } from 'react-redux';
import { toggleTopNavBar } from '../../redux/actions/tobNavBarActions'
import { hideSidebar } from '../../redux/actions/sidebarActions';

import OnlyInspection from '../../pages/WebEditor/parts/OnlyInspection';
import EditorDataProvider from '../WebEditor/contexts/EditorDataProvider';

const Inspector = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const direction = params.direction;
  const filename = params.filename;
  const project_no = params.project_no;

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    dispatch(toggleTopNavBar());
    dispatch(hideSidebar());
  },[]);

  useEffect(() => {
    const unblock = history.block((loc, action) => {
      dispatch(toggleTopNavBar());
      return true
    })
    return () => {
      unblock()}
  }, []);

  return (
  <EditorDataProvider>
    <OnlyInspection
      currentFile={filename} 
      setIsloading={setIsloading}
      direction = {direction} 
      project_no = {project_no}
      acceptance = {history.location.state
        ? history.location.state.acceptance
        : null}
    />
  </EditorDataProvider>
);

}
export default Inspector;

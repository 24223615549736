import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";

import { landing as landingRoutes, dashboard as dashboardRoutes, page as pageRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";

import AuthContext from "../store/auth-context";
import ResetPassword from "../pages/auth/ResetPassword";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import SignUpRequest from "../pages/auth/SignUpRequest";
import Withdrawal from "../pages/auth/Withdrawal";
import ScrollToTop from "../components/ScrollToTop";
import TermsPolicyView from "../pages/auth/TermsPolicyView";
import Personal from "../pages/auth/Personal";
import ElasticSearch from "../pages/search/ElasticSearch";

const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={(props) => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
                // Route item without children
                <Route
                    key={index}
                    path={path}
                    exact
                    render={(props) => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            )
    );

const App = () => {
    const authCtx = useContext(AuthContext);

    // console.log(authCtx.isLoggedIn);

    return (
        <Switch>
            {childRoutes(LandingLayout, landingRoutes)}
            {childRoutes(DashboardLayout, dashboardRoutes)}
            <AuthLayout>
                {authCtx.isLoggedIn && (
                    <Route path="/auth/reset-password">
                        <ResetPassword />
                    </Route>
                )}
                {!authCtx.isLoggedIn && (
                    <Route path="/auth/sign-in">
                        <SignIn />
                    </Route>
                )}
                {!authCtx.isLoggedIn && (
                    <Route path="/auth/sign-up">
                        <SignUp />
                    </Route>
                )}
                {!authCtx.isLoggedIn && (
                    <Route path="/auth/chk-request">
                        <SignUpRequest />
                    </Route>
                )}
                {authCtx.isLoggedIn && (
                    <Route path="/auth/withdrawal">
                        <Withdrawal />
                    </Route>
                )}
                {/* <Route path="/docs/policy">
                    <TermsPolicyView />
                </Route>
                <Route path="/docs/personal">
                    <Personal />
                </Route> */}
                {authCtx.isLoggedIn && (
                    <Route path="/el-search">
                        <ElasticSearch />
                    </Route>
                )}
            </AuthLayout>
        </Switch>
    );
};

export default App;

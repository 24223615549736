import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import AuthContext from "../store/auth-context";

import { Box } from "react-feather";
import routes from "../routes/index";
// import sidelogo from "../assets/img/brands/gocat_white.svg";
// import sidelogo from "../assets/img/brands/thinkCAT_white.svg";
// import sidelogo from "../assets/img/brands/THINKCATICON.png";
import sidelogo from "../assets/img/brands/ver2/thinkcat_white.svg";
import { useMediaQuery } from "react-responsive";

// 20221206
import { toggleSidebar } from "../redux/actions/sidebarActions";

const initOpenRoutes = (location) => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
        const isActive = pathName.indexOf(route.path) === 0;
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === "/" ? true : false;

        _routes = Object.assign({}, _routes, {
            [index]: isActive || isOpen || isHome,
        });
    });

    return _routes;
};

const SidebarCategory = withRouter(
    ({ name, badgeColor, badgeText, icon: Icon, isOpen, children, onClick, location, to }) => {
        const getSidebarItemClass = (path) => {
            return location.pathname.indexOf(path) !== -1 || (location.pathname === "/" && path === "/dashboard")
                ? "active"
                : "";
        };
        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
                <span
                    data-toggle="collapse"
                    className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
                    onClick={onClick}
                    aria-expanded={isOpen ? "true" : "false"}
                >
                    <Icon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{name}</span>
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </span>
                <Collapse isOpen={isOpen}>
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {children}
                    </ul>
                </Collapse>
            </li>
        );
    }
);

const SidebarItem = withRouter(({ name, badgeColor, badgeText, icon: Icon, location, to, state }) => {
    const getSidebarItemClass = (path, state) => {
        if (location.pathname.split('/').length > 2) {
            if (`/${location.pathname.split('/')[1]}` === path) {
                if (location.state && state) {
                    if (Object.keys(location.state).includes('projectType')) {
                        return location.state.projectType === state.type ? 'active' : ''
                    }
                    else {
                        return location.state.type === state.type ? 'active' : ''
                    }
                }
            }
        }
        else {
            if (location.pathname === path) {
                if (location.state && state) {
                    return location.state.type === state.type ? 'active' : ''
                }
                else {
                    return 'active'
                }
            }
        }
        return ''
    };
    return (
        <li className={"sidebar-item p-0 " + getSidebarItemClass(to, state)}>
            <NavLink
                to={!state ? to : { pathname: to, state }}
                className="sidebar-link"
                activeClassName="active"
            >
                {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
                {name}
                {badgeColor && badgeText ? (
                    <Badge color={badgeColor} size={18} className="sidebar-badge">
                        {badgeText}
                    </Badge>
                ) : null}
            </NavLink>
        </li>
    );
});

const Sidebar = ({ location, sidebar, layout, dispatch }) => {
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    var icon = '';
    const pathName = location.pathname;
    if (pathName.includes("/upload")) {
        icon = '/jpg/June1.png';
    }
    else if (pathName.includes("/projects")) {
        icon = '/jpg/Haku.png';
    }
    else {
        icon = '/jpg/Rie.png';
    }

    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

    const toggle = (index) => {
        // Collapse all elements
        Object.keys(openRoutes).forEach(
            (item) =>
                openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
        );

        // Toggle selected element
        setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
    };

    const toggleHandler = () => {
        isMobile && dispatch(toggleSidebar());
    }

    return (
        <nav className={"sidebar" + (!sidebar.isOpen ? " toggled" : "") + (sidebar.isSticky ? " sidebar-sticky" : "")}>
            <div className="sidebar-content">
                <PerfectScrollbar>
                    <a className="sidebar-brand pl-4 p-3" href="/">
                        <img src={sidelogo} title="THINK! CAT" height='50' />
                        {/* <img src={icon} title="THINK! CAT" height="50" /> */}
                    </a>

                    <ul className="sidebar-nav">
                        {routes.map((category, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {category.header
                                        ? <li className="sidebar-header p-3" style={{ color: "#fff" }}>{category.header}</li>
                                        : null}
                                    {category.children
                                        ? (
                                            <SidebarCategory
                                                name={category.name}
                                                badgeColor={category.badgeColor}
                                                badgeText={category.badgeText}
                                                icon={category.icon}
                                                to={category.path}
                                                isOpen={openRoutes[index]}
                                                onClick={() => toggle(index)}
                                            >
                                                {category.children.map((route, index) => (
                                                    <div
                                                        onClick={toggleHandler}
                                                    >
                                                        <SidebarItem
                                                            key={index}
                                                            name={route.name}
                                                            to={route.path}
                                                            badgeColor={route.badgeColor}
                                                            badgeText={route.badgeText}
                                                            state={route.state || null}
                                                        />
                                                    </div>
                                                ))}
                                            </SidebarCategory>
                                        )
                                        : (
                                            <div
                                                onClick={toggleHandler}
                                            >
                                                <SidebarItem
                                                    name={category.name}
                                                    to={category.path}
                                                    icon={category.icon}
                                                    badgeColor={category.badgeColor}
                                                    badgeText={category.badgeText}
                                                    state={category.state || null}
                                                />
                                            </div>
                                        )
                                    }
                                </React.Fragment>
                            );
                        })}
                    </ul>

                    {/* {!layout.isBoxed && !sidebar.isSticky ? (
                        <div className="sidebar-bottom d-none d-lg-block">
                            <div className="media">
                                <img
                                    className="rounded-circle mr-3"
                                    src={avatar}
                                    alt="Chris Wood"
                                    width="40"
                                    height="40"
                                />
                                <div className="media-body">
                                    <h5 className="mb-1">Chris Wood</h5>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-success"
                                        />{" "}
                                        Online
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null} */}
                </PerfectScrollbar>
            </div>
        </nav>
    );
};

export default withRouter(
    connect((store) => ({
        sidebar: store.sidebar,
        layout: store.layout,
    }))(Sidebar)
);

import React, { useRef, useContext } from "react";
import AuthContext from "../../store/auth-context";
import Axios from 'axios';
import { BACKEND } from "../../Constants";

import { Input, Button } from "reactstrap";
import { CornerDownRight, XCircle } from "react-feather";

function createRoomName(id1, id2) {
    if (id1 > id2) {
        // swap two values
        let temp = id2;
        id2 = id1;
        id1 = temp;
    }
    return id1 + '|' + id2;
}

const NewForm = (props) => {

    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const theemail = authCtx.useremail;
    const thename = authCtx.username;
    const socket = authCtx.socket;

    const inputRef = useRef(null);

    const handleX = (id) => {
        let updatedItems = [...props.forms];
        props.forms.forEach((it, index) => {
            if (index == id) {
                console.log(id);
                updatedItems[index] = null;
                return;
            }
        });
        props.setForms(updatedItems);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        let comment = inputRef.current.value;
        let room = null
        if (props.who != theemail) {
            room = createRoomName(props.who, theemail);
        }

        var the_comment = comment.trim();
        if (the_comment === "") {
            // setError(true);
            alert('내용을 입력하세요');
            inputRef.current.focus();
        }
        else {
            // console.log(editor.current.getContents())
            const token = localStorage.getItem('token');
            const data = {
                "fid": props.fid,
                "thread": props.thread,
                "comment": comment,
            };
            Axios
                .post(BACKEND.SERVER + "/recomment/" + props.query, data, {
                    headers: {
                        "Authorization": `JWT ${token}`
                    }
                })
                .then((res) => {
                    // setComment("");
                    if (res.statusText !== "OK") {
                        alert(res.data.message);
                    } else if (res.statusText === "OK") {
                        var added = res.data;
                        var date = new Date();
                        date = date.getFullYear() + '-' +
                            ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
                            ('00' + date.getDate()).slice(-2) + ' ' +
                            ('00' + date.getHours()).slice(-2) + ':' +
                            ('00' + date.getMinutes()).slice(-2) + ':' +
                            ('00' + date.getSeconds()).slice(-2);
                        let newcomment = {
                            "member_email": theemail,
                            "member_name": thename,
                            "comment": added.comment,
                            "thread": added.thread,
                            "ref": 0,
                            "no": added.no,
                            "fid": added.fid,
                            "pid": added.pid,
                            "query": props.query,
                            "created_datetime": date,
                            "room": room,
                        }
                        let last_fid = added.last_fid;
                        props.insertComment(last_fid, props.id, newcomment);
                        if (props.who != theemail) {
                            socket.emit("send_comment", newcomment);
                        }
                        handleX(props.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };


    const commentChange = (e) => {
        {
            isLoggedIn
                ? props.commentChange(e.target.value)
                : alert("먼저 로그인해주세요.")
        }
    };

    const stylep = { cursor: "pointer" };
    const styles = { width: "80%" };

    return (
        <>
            <CornerDownRight className="mr-2" />
            <Input className="form-control mt-2" style={styles} type="textarea" name="comment2"
                placeholder="댓글에 대한 댓글을 입력하실 수 있습니다"
                onChange={commentChange} innerRef={inputRef}
                autoFocus
            /> <Button type="submit" color="primary" className="ml-2"
                onClick={handleSubmit}
            >
                등록
                    </Button>
            <XCircle className="ml-2" size={18} style={stylep}
                onClick={() => {
                    handleX(props.id)
                }} />
        </>
    );
};

export default NewForm;

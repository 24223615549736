import React, { useReducer, useEffect } from "react";
import axios from "axios";

import ResourceTable from "./ResourceTable";

import { BACKEND } from "../../../Constants";

const DANGER_LIMIT = 80;
const WARNING_LIMIT = 70;

const setResourceData = (state, payload) => {
  let status = {}
  if (payload === 'server error') {
    Object.values(state).map((value, index) => {
      status = {
        ...status,
        [index+1]: {status1: 'off', status2: 'off'}
      }
    });
  }
  else {
    Object.values(payload).map((value, index) => {
      status = {
        ...status,
        [index+1]: {
          status1: value['gpu_memory'] > WARNING_LIMIT
          ? value['gpu_memory'] > DANGER_LIMIT
            ? 'off'
            : 'warning'
          :'on',
          status2: value['main_memory'] > WARNING_LIMIT
          ? value['main_memory'] > DANGER_LIMIT
            ? 'off'
            : 'warning'
          : 'on'
        }
      }
    });
  }
  return status;
};

const Monitor = () => {
  const [data, setData] = useReducer(setResourceData, {
    1: {status1: 'on',
        status2: 'on'},
    // modified 20250102
    // 2: {status1: 'on',
    //     status2: 'on'}
  });
  const getResource = () => {
    axios.get(`${BACKEND.SERVER}/resourceMonitor`
    ).then((response) => {
      if (response.status === 200){
        setData(response.data)
      }
      else {
        setData('server error');
      }
    }).catch(() => {
      setData('server error');
    })
  }
  useEffect(() => {
    getResource()
  }, [])
  
  return (
    <React.Fragment>
      <ResourceTable data = {data} />
    </React.Fragment>
  )
};

export default Monitor;

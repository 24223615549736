import React, { useCallback, useRef, useState, useContext, useEffect } from "react";
import axios from "axios";
import { BACKEND } from "../../Constants";
//import { useHistory } from "react-router";
import LetteredAvatar from 'react-lettered-avatar';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import {
    Badge,
    Card,
    Col,
    Container,
    Form,
    Input,
    ListGroupItem,
    Media,
    Row
} from "reactstrap";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import avatar2 from "../../assets/img/avatars/avatar-2.jpg";
import avatar3 from "../../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../assets/img/avatars/avatar-5.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { Phone, Video, MoreHorizontal } from "react-feather";
import AuthContext from "../../store/auth-context";
import ChatMessages from "./ChatMessages";

var pad = function (num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

var today = Math.round((new Date()).getTime() / 1000);

function genDate(signdate) {
    const diff = today - signdate;
    var yesterday = today - 86400;
    var year = (new Date(signdate * 1000)).getFullYear().toString();
    var month = ((new Date(signdate * 1000)).getMonth() + 1).toString();
    var date = (new Date(signdate * 1000)).getDate().toString();
    var yester_date = (new Date(yesterday * 1000)).getDate().toString();
    var hour = (new Date(signdate * 1000)).getHours();
    var min = (new Date(signdate * 1000)).getMinutes().toString();
    var time = '';

    if (diff < 60 * 60 * 24) {
        if (date == yester_date) time = '어제';
        else if (hour < 12) time = '오전 ';
        else {
            time = '오후 ';
            if (hour > 12) hour -= 12;
        }
        if (date !== yester_date) time += hour.toString() + ":" + pad(min, 2);
    }
    else if (diff < 60 * 60 * 24 * 2 && date == yester_date)
        time = '어제';
    else time = year + "." + pad(month, 2) + "." + pad(date, 2)
    return time
}
function createRoomName(id1, id2) {
    if (id1 > id2) {
        // swap two values
        let temp = id2;
        id2 = id1;
        id1 = temp;
    }
    return id1 + '|' + id2;
}


const Chat = () => {
    const location = useLocation();
    let query = location.member_email;
    let query2 = location.member_name;
    const projectType = location.state ? location.state['type'] : 'personal'
    if (projectType === 'operation') {
        query = "thinkcat@thinkcat.tools";
        query2 = "씽캣"
    }
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const token = authCtx.token;
    const socket = authCtx.socket;
    // const thename = authCtx.username;
    // const theemail = authCtx.useremail;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const thename = isLoggedIn ? userInfo.userName : null;
    const theemail = isLoggedIn ? userInfo.userEmail : null;
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const [who, setWho] = useState("");
    const [room, _setRoom] = useState("");
    const [search, setSearch] = useState("");
    const [key, setKey] = useState("");
    const [showChat, setShowChat] = useState(false);
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const handleChange = (event) => {
        setShowChat(false)
        setSearch(event.target.value);
        setKey(event.target.value);
        setPageNumber(1);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowChat(false)
        setPartners([]);
        var the_search = search.trim();
        if (the_search === "") {
            if (isLoggedIn) {
                readRoom();
            }
        } else {
            if (isLoggedIn) {
                searchRoom();
            }
        }
    }

    const roomRef = useRef(room);
    const inputRef = useRef(null);

    const setRoom = (data) => { // socket.io는 state update가 Ref를 안쓰면 안됨
        roomRef.current = data;
        _setRoom(data);
    }

    const joinRoom = (partner_email, partner_name, count) => {
        const theroom = createRoomName(theemail, partner_email);
        const enteredKey = inputRef.current.value;
        // console.log("join", theroom, enteredKey)
        if (theemail !== "" && theroom !== "") {
            setWho({ "email": partner_email, "name": partner_name });
            setRoom(theroom);
            { socket && socket.emit("join_room", theroom, theemail) }
            { socket && setShowChat(true) }
            { count == 0 && setKey("") }
            { count > 0 && setKey(enteredKey) }
            // console.log(enteredKey, partner_email, partner_name, thename, theroom);
        }
    }

    const observer = useRef();
    const lastPartnerElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    const searchRoom = () => {
        var the_search = search.trim();
        const data = {
            "member": theemail,
            "search": the_search,
            "page": pageNumber
        };
        axios
            .post(BACKEND.SERVER + "/chat", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    setPartners((partners) => [...new Set([...partners, ...res.data])]);
                    //    setPartners(res.data);
                    setHasMore(res.data.length > 0);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const readRoom = () => {
        const data = {
            member: theemail,
            page: pageNumber
        };
        axios
            .post(BACKEND.SERVER + "/chats", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    setPartners((partners) => [...new Set([...partners, ...res.data])]);
                    setHasMore(res.data.length > 0);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setError(true);
                console.log(err);
            });
    }

    useEffect(() => {
        if (socket) {
            if (room && typeof (room) !== 'undefined') {
                socket.on("receive_message", (data) => {
                    readRoom();
                });
            } else {
                socket.on("getNotification", (data) => {
                    readRoom();
                });
            }
        }
    }, [socket]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (isLoggedIn && search == '') {
            readRoom();
        }
        else if (isLoggedIn && search != '') {
            searchRoom();
        }
    }, [pageNumber]);

    useEffect(() => {
        if (isLoggedIn && typeof (query) !== 'undefined') {
            joinRoom(query, query2);
        }
    }, [query]);

    const styles = { cursor: 'pointer' };

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">채팅</h1>

            <Card>
                <Row noGutters>
                    {(!showChat || !isMobile) &&
                        <Col lg={5} xl={3} className="border-right">
                            <div className="px-4 d-md-block">
                                <Media className="align-items-center">
                                    <Media body>
                                        <Form inline="true" onSubmit={handleSubmit} className="d-sm-inline-block">
                                            <Input type="text" name="searchTerm"
                                                innerRef={inputRef}
                                                onChange={handleChange}
                                                className="my-3" placeholder="대화상대나 내용 검색" />
                                        </Form>
                                    </Media>
                                </Media>
                            </div>
                            {partners.map((item, index) => <ListGroupItem key={index}
                                action tag="a" onClick={() => {
                                    joinRoom(item.partner, item.partner_name, item.count);
                                }}
                                className={`border-0 ${who.email === item.partner ? "active" : ""}`}>
                                {partners.length === index + 1 ?
                                    (<div ref={lastPartnerElementRef} key={item}></div>) : (<div key={item}></div>)}
                                <div>{loading && 'Loading...'}</div>
                                <div>{error && 'Error'}</div>
                                <div className="float-right">
                                    {genDate(item.signdate)}<br />
                                    {item.unread > 0 &&
                                        <Badge color="success" className="float-right">
                                            {item.unread}
                                        </Badge>}
                                </div>
                                <Media>
                                    <LetteredAvatar
                                        size={40}
                                        name={item.partner_name}
                                    />
                                    <Media body className="ml-3">
                                        {item.partner_name}
                                        <div className="small">
                                            {item.comment}
                                        </div>
                                    </Media>
                                </Media>
                            </ListGroupItem>)
                            }
                            <hr className="d-block d-lg-none mt-1 mb-0" />
                        </Col>
                    }
                    {showChat &&
                        <ChatMessages readRoom={readRoom} searchTerm={key} setSearchTerm={setKey} setShowChat={setShowChat} who={who} username={thename} useremail={theemail} room={room} roomRef={roomRef} />
                    }
                    {!showChat &&
                        <Col lg={7} xl={9} className="chat-window">
                            <div className="text-center mt-4">
                                {!isLoggedIn && "먼저 로그인해주세요."}
                                {isLoggedIn && "대화할 상대를 선택해주세요."}
                            </div>
                        </Col>
                    }
                </Row>
            </Card>
        </Container>
    );
};

export default Chat;

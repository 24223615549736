const tableData = [
    {
        name: "Pandemic",
        sourceLanguages: "영어",
        targetLanguages: "한국어",
        comments: "COVID-19 관련 문서에서 만들어진 용어집입니다.",
        createDate: "2011/04/25",
        writer: "오시연",
    },
    {
        name: "AI 번역",
        sourceLanguages: "영어",
        targetLanguages: "한국어",
        comments: "번역완료함",
        createDate: "2011/04/25",
        writer: "오시연",
    },
    {
        name: "비긴어게인",
        sourceLanguages: "영어",
        targetLanguages: "한국어",
        comments: "비긴어게인 영화 대사 번역본",
        createDate: "2011/04/25",
        writer: "오시연",
    },
    {
        name: "한국 문학 소설집",
        sourceLanguages: "영어",
        targetLanguages: "한국어",
        comments: "한국 문학 소설집을 영어로 번역한 문서입니다.",
        createDate: "2011/04/25",
        writer: "오시연",
    },
    {
        name: "용어집1",
        sourceLanguages: "영어",
        targetLanguages: "한국어",
        comments: "용어집1입니다.",
        createDate: "2011/04/25",
        writer: "오시연",
    },
];

const tableColumns = [
    {
        dataField: "name",
        text: "이름",
        sort: true,
    },
    {
        dataField: "sourceLanguages",
        text: "언어",
        sort: true,
    },
    {
        dataField: "targetLanguages",
        text: "번역언어",
        sort: true,
    },
    {
        dataField: "comments",
        text: "메모",
        sort: true,
    },
    {
        dataField: "createDate",
        text: "작성일",
        sort: true,
    },
    {
        dataField: "writer",
        text: "작성자",
        sort: true,
    },
];

export { tableData, tableColumns };

import React, { useState, useContext } from "react";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// reactstrap
import { Row, Col, Button, Input } from "reactstrap";

// context
import DocumentContext from '../contexts/document-context';


const TopOptions = (props) => {
  const docCtx = useContext(DocumentContext);
  const [searchKeyword, setSearchKeyword] = useState('');
  
  const searching = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    keyword === '' && docCtx.search('');
  };
  const search = (event) => {
    docCtx.search(searchKeyword);
  };
  
  return (
    <Row className="mb-1">
      <Col></Col>
      <Col xs="auto" className="pr-0 align-self-center">
        <Input 
          placeholder="문서 검색"
          onChange = {searching}
          onKeyDown = {(event) => {
            event.key === 'Enter' && search(event)}}
        />
      </Col>
      <Col xs="auto" className="pl-0 pr-0 text-right">
        <Button
          outline
          color="secondary"
          className="ml-10 mr-10"
          style={{ borderColor: "rgba(0,0,0,0)" }}
          onClick = {search}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Col>
    </Row>
  );
};

export default TopOptions;
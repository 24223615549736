import React, { useState, useEffect } from "react";
import UploadService from "../services/FileUploadService";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";

import { BACKEND } from "../Constants";

import {
  Button
} from "reactstrap";

const baseURL = BACKEND.SERVER;

const EditFile = (props) => {
	const history = useHistory(); 
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileInfos, setFileInfos] = useState([]);
  const [showDownload, setShowDownload] = useState(false); 

	const {currentFile} = props;

   useEffect(() => {

    UploadService.read(currentFile, (event) => {
	    // console.log(currentFile);
    })
      .then((lines) => {
        setFileInfos(lines.data);
      })
      .catch(() => {
        setMessage("Could not read the file!");
      });

   }, []);


  const save = (event) => {
	event.preventDefault();
	
	const lines = fileInfos.slice()

    UploadService.save(currentFile, lines, (event) => {
    })
      .then((response) => {
        setShowDownload(true);
        setMessage(response.data.message);
      })
      .catch(() => {
        setMessage("Could not save the file!");
      });

  };

    const onChangeHandler = (e) => {
	    const newIds = fileInfos.slice()
	    // console.log(e.target.name, e.target.value);
	    newIds[e.target.name] = e.target.value;
	    setFileInfos(newIds);
    }


return (
    <div>
      <div className="card">
        <div className="card-header">
	{showDownload ? (<span><FontAwesomeIcon id="left" icon={faChevronLeft} onClick={()=> {setShowDownload(false)}} />&nbsp; 돌아가기</span>)
		: (<span><FontAwesomeIcon id="left" icon={faChevronLeft} onClick={()=> history.push('/upload') } />&nbsp; 재업로드</span>)
	}
	      </div>
	<form onSubmit={save}>
        <ul className="list-group list-group-flush">
          {fileInfos && !showDownload &&
            fileInfos.map((file, index) => (
              <input name={index} key={index} value={file} onChange={ onChangeHandler }/>
            ))}
        </ul>
	      <div>{message}</div>
	      {showDownload ?
			      (
	<Button href={ baseURL + "/download/" + currentFile}  target="_blank" color="success" size="lg">다운로드</Button>)
			      : ''}
	{fileInfos && !showDownload &&
	<Button color="primary" size="lg">저장하기</Button>}
	</form>
      </div>
    </div>
  );
};

export default EditFile;

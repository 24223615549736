import axios from 'axios';
import React, { useReducer } from 'react';

import TeamContext from './TeamContext';

const teamInit = {
  project_no: null,
  team: [],
}
  
const TeamReducer = (state, action) => {
  switch(action.type) {
    case 'InsertMember': {
      const new_member = {
        'email': action.email, 
        'name': action.name,
        'color': action.color}
      const team = [...state.team, new_member]
      
      return {
        ...state,
        team
      }
    };

    case 'ChangeColor': {
      const memberIndex = state.team.findIndex(
        member => member.email === action.email
      );
      const member = {
        ...state.team[memberIndex],
        'color': action.color
      };
      const teams = state.team.fill(member, memberIndex, memberIndex+1);
    
      return {
        ...state,
        team: teams
      }
    }
    
    case 'DeleteMember': {
      const team = state.team.filter(
        member => member.email !== action.email
      );
      return {
        ...state,
        team
      }
    };
    
    case 'InitProject': {
      return {
        ...state,
        project_no: action.project_no
      }
    };

    case 'InitMember': {
      return {
        ...state,
        team: action.team
      }
    }
  }
  return teamInit
}

const TeamProvider = (props) => {
  const [teamState, dispatchTeam] = useReducer(TeamReducer, teamInit)

  const initProject = (project_no) => {
    dispatchTeam({
      type: 'InitProject',
      project_no: project_no
    })
  };

  const initMember = (team) => {
    dispatchTeam({
      type: 'InitMember',
      team: team
    })
  };

  const insertMemberHandler = (payload) => {
    dispatchTeam({
      type: 'InsertMember', 
      email: payload.email, 
      name: payload.name,
      color: payload.color})
  };
  const deleteMemberHandler = (payload) => {
    dispatchTeam({
      type: 'DeleteMember', 
      email: payload.email})
  };
  const memberColorChangeHandler = (email, color) => {
    dispatchTeam({
      type: 'ChangeColor',
      email: email,
      color: color
    })
  };

  const teamContext = {
    project_no: teamState.project_no,
    team: teamState.team,

    initProject: initProject,
    initMember: initMember,
    
    insertMember: insertMemberHandler,
    deleteMember: deleteMemberHandler,

    memberColorChange:memberColorChangeHandler,
  };

  return (
    <TeamContext.Provider value = {teamContext}>
      {props.children}
    </TeamContext.Provider>
  )
}

export default TeamProvider;
import React, { useState, useContext } from "react";
import axios from "axios";

import { Badge, Button, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { MessageSquare, X, Square } from "react-feather";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TeamContext from "../contexts/TeamContext";
import ColorPicker from "./ColorPicker";

import AuthContext from "../../../../store/auth-context";
import { BACKEND } from "../../../../Constants";

// it need to dynamic path
import avatar from '../../../../assets/img/avatars/manDefault.jpg';
import { useMediaQuery } from "react-responsive";

function createRoomName(id1, id2) {
    if (id1 > id2) {
        // swap two values
        let temp = id2;
        id2 = id1;
        id1 = temp;
    }
    return id1 + '|' + id2;
}

const MemberCard = (props) => {
    const { name, email, color, role, acceptance } = props;
    const [clickColorPicker, setClickColorPicker] = useState(false);

    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const socket = authCtx.socket;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const theemail = isLoggedIn ? userInfo.userEmail : null;
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const teamContext = useContext(TeamContext);

    const deleteMember = (project_no, email) => {
        const token = localStorage.getItem('token');
        const member = {
            project_no: project_no,
            user_email: email
        };

        axios.delete(`${BACKEND.SERVER}/team`, {
            data: member,
            headers: {
                Authorization: `JWT ${token}`
            },

        }).then((res) => {
            if (res.statusText !== "OK") {
                alert(res.data.message);
            }
            else if (res.statusText === "OK") {
                alert(res.data.message);
                const payload = {
                    email: member.user_email
                }
                teamContext.deleteMember(payload);
            };
            // console.log("success: ", res);
        }).catch((err) => {
            console.log(err);
        });
    };

    const colorUpdateMember = (newColor) => {
        const token = localStorage.getItem('token');

        const updateData = {
            project_no: teamContext.project_no,
            user_email: email,
            personal_color: newColor,
            action: 'colorChange'
        }

        axios.post(`${BACKEND.SERVER}/team`, updateData, {
            headers: {
                "Authorization": `JWT ${token}`
            }
        })
    };

    const deleteMemberOnClick = (event) => {
        //console.log(event.currentTarget.id)
        const deleteEmail = event.currentTarget.id;
        deleteMember(teamContext.project_no, deleteEmail);

        //gabia
        var date = new Date();
        date = date.getFullYear() + '-' +
            ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('00' + date.getDate()).slice(-2) + ' ' +
            ('00' + date.getHours()).slice(-2) + ':' +
            ('00' + date.getMinutes()).slice(-2) + ':' +
            ('00' + date.getSeconds()).slice(-2);
        const sender_email = localStorage.getItem('useremail')
        let room = null
        if (deleteEmail != sender_email) {
            room = createRoomName(deleteEmail, sender_email);
        }
        let newproposal = {
            "sender_email": sender_email,
            "recipient_email": deleteEmail,
            "comment": "kick out",
            "ref": 0,
            "thread": "dlt",
            "no": teamContext.project_no,
            "query": String(teamContext.project_no),
            "created_datetime": date,
            "room": room
        }
        console.log("Deleted members completed")
        socket.emit("delete_member", newproposal);
    };

    const onClickColorPicker = () => {
        setClickColorPicker(!clickColorPicker)
    };

    const onColorPicker = (newColor) => {
        teamContext.memberColorChange(email, newColor);
        colorUpdateMember(newColor);
    };



    return (
        <Card>
            <CardHeader className='pb-0'>
                <Button
                    className='float-left'
                    color='light'
                    outline
                    style={{
                        border: 0,
                        color: color,
                        cursor: props.currentUserRole === 'PM'
                            ? 'pointer' : 'not-allowed'
                    }}
                    onClick={onClickColorPicker}
                    disabled={props.currentUserRole !== 'PM'}
                >
                    <FontAwesomeIcon
                        icon={faPalette}
                    />
                </Button>
                {clickColorPicker &&
                    <ColorPicker
                        color={color}
                        onClickColorPicker={onClickColorPicker}
                        onColorPicker={onColorPicker}
                    />}
                <Badge
                    className='pt-1 mt-1'
                    color='info'>
                    {role === 'PM' ? '프로젝트 매니저' : '멤버'}
                </Badge>
                {role !== 'PM' && <Badge
                    className='pt-1 mt-1'
                    color={acceptance === 'accepted' ? 'success' : 'warning'}>
                    {acceptance === 'accepted' ? '수락됨' : '대기중'}
                </Badge>}
                {role !== 'PM' && <Button
                    className='float-right'
                    color="danger"
                    outline
                    style={{
                        border: 0,
                        cursor: props.currentUserRole === 'PM'
                            ? 'pointer' : 'not-allowed'
                    }}
                    disabled={props.currentUserRole !== 'PM'}
                    size="sm"
                    onClick={deleteMemberOnClick}
                    key={email}
                    id={email}
                >
                    <X width={16} height={16} />
                </Button>}
            </CardHeader>
            <CardBody className="text-center">
                <img
                    src={avatar}
                    alt="avatar"
                    className="img-fluid rounded-circle mb-2"
                    width="32"
                    height="32"
                />
                <CardTitle tag="h5" className="mb-0">
                    {name}
                </CardTitle>
                <div className="text-muted mb-2">{acceptance === 'accepted' ? email : '대기중'}</div>
                {email != theemail && <div>
                    <Button
                        size="sm"
                        color="primary"
                    >
                        <Link
                            className="text-decoration-none text-light"
                            to={
                                {
                                    pathname: '/chats',
                                    member_name: name,
                                    member_email: email,
                                    state: { type: "personal" }
                                }
                            }>
                            <MessageSquare width={16} height={16} /> {isMobile ? "" : "대화하기"}
                        </Link>
                    </Button>


                </div>}
            </CardBody>
        </Card>
    )
}

export default MemberCard;

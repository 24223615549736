import React from 'react';

const DocumentContext = React.createContext({
  items: [],
  searchKeyword: '',

  autoTranslation: true,
  translationDomain: 'normal',

  setItems: (items) => {},
  addItem: (item) => {},
  removeItem: (fileName) => {},
  search: (keyword) => {},
  changeState: (item) => {},
  changeValueForKey: (key, value) => {},
});

export default DocumentContext;
import React, { useState, useRef, useContext } from "react";
import axios from "axios";

// uploadservice
import UploadService from "../../../../services/FileUploadService";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

// reactstrap
import {
  Table,
  Progress,
  Container,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";

// context
import TmxContext from "../contexts/tmx-context";

const TopOptions = (props) => {
  const tmxCtx = useContext(TmxContext);
  //const [tmxFile, setTmxFile] = useState();
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  
  const fileRef = useRef();

  const uploadProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const uploadButtonHanlder = (event) => {
    event.preventDefault();
    fileRef.current.click();
  };

  const searching = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    keyword === "" && tmxCtx.search("");
  };
  const search = () => {
    tmxCtx.search(searchKeyword);
  };

  const fileUploadOnChange = (event) => {
    const [file] = event.target.files;
    const email = JSON.parse(localStorage.getItem("userInfo")).userEmail;
    UploadService.upload(file, email, props.project_no, false, uploadProgress)
      .then((response) => {
        if (response.data.filename === "duplicateFile") {
          alert(response.data.message);
        } else {
          //setTmxFile(event.target.files);
          //setMessage(response.data.message);
          props.addItem(file, response.data.filename);
        }
      })
      .catch((error) => {
        alert("파일을 업로드할 수 없었습니다!");
      });
  };

  return (
    <Row className="mb-1">
      <Col></Col>
      <Col xs="auto" className="pr-0 align-self-center">
        <Input 
          placeholder = '번역메모리 검색'
          onChange = {searching}
          onKeyDown = {(event) => {
            event.key === 'Enter' && search()
          }} 
        />
      </Col>
      <Col xs="auto" className="pl-0 pr-0 text-right">
        <Button
          outline
          color="secondary"
          className="ml-10 mr-10"
          style={{ borderColor: "rgba(0,0,0,0)" }}
          onClick = {search}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
        {/* <input 
          ref = {fileRef}
          type = 'file'
          id = 'tmx-upload'
          onChange = {fileUploadOnChange}
          hidden />
        <Button 
            type = 'file'
            outline 
            color = 'secondary' 
            className="ml-10 mr-10"
            style = {{'borderColor':'rgba(0,0,0,0)'}}
            onClick = {uploadButtonHanlder}
        >
            <FontAwesomeIcon icon={faPlus} size ='lg'/>
        </Button>
      */}
      </Col>
    </Row>
  );
};

export default TopOptions;

const tableData = [
    {
        name: "Pandemic",
        languages: "영어, 한국어",
        counts: 5,
        comments: "COVID-19 관련 문서에서 만들어진 용어집입니다.",
        editDate: "2011/04/25",
        modifier: "오시연",
    },
    {
        name: "AI 번역",
        languages: "영어, 한국어",
        counts: 5,
        comments: "번역완료함",
        editDate: "2011/04/25",
        modifier: "오시연",
    },
    {
        name: "비긴어게인",
        languages: "영어, 한국어",
        counts: 60,
        comments: "비긴어게인 영화 대사 번역본",
        editDate: "2011/04/25",
        modifier: "오시연",
    },
    {
        name: "한국 문학 소설집",
        languages: "영어, 한국어",
        counts: 12,
        comments: "한국 문학 소설집을 영어로 번역한 문서입니다.",
        editDate: "2011/04/25",
        modifier: "오시연",
    },
    {
        name: "용어집1",
        languages: "영어, 한국어",
        counts: 30,
        comments: "용어집1입니다.",
        editDate: "2011/04/25",
        modifier: "오시연",
    },
];

const tableColumns = [
    {
        dataField: "name",
        text: "이름",
        sort: true,
    },
    {
        dataField: "languages",
        text: "언어",
        sort: true,
    },
    {
        dataField: "counts",
        text: "용어 개수",
        sort: true,
    },
    {
        dataField: "comments",
        text: "메모",
        sort: true,
    },
    {
        dataField: "editDate",
        text: "최근 수정일",
        sort: true,
    },
    {
        dataField: "modifier",
        text: "최근 수정자",
        sort: true,
    },
];

export { tableData, tableColumns };

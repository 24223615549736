import React, { useState, useContext } from 'react';

import axios from 'axios';

// UI
import { Modal, Row, Col, Button, Alert } from 'react-bootstrap';

// parts
import UploadFile from './UploadFile';
import CheckButtonItem from './CheckButtonItem';
import ImportedFiles from './ImportedFiles';

// contexts
import GlossaryContext from '../contexts/glossary-context';

import { BACKEND } from '../../../../Constants';

const ImportGlossary = (props) => {
  const [checked, setChecked] = useState(true);
  const [files, setFiles] = useState([]);
  const gsCtx = useContext(GlossaryContext);
  
  const CheckButtonHandler = (event) => {
    if (files.length <= 1){
      setChecked(!checked);
    }
    else {
      setChecked(true);
    }
  };

  const pushPost = () => {
    const userInfo = localStorage.getItem('userInfo');
    const userName = userInfo ?JSON.parse(userInfo).userName :'';
    const userNo   = userInfo ?JSON.parse(userInfo).userUid :'';

    files.length > 0 && Promise.all(files.map(async (file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('fileName', props.glossaryName);
      data.append('project_no', props.project_no);
      data.append('user_id', userNo);
      data.append('process', 
        checked 
        ?'glossary.import.add'
        :'glossary.import.replacement');
      
      await axios.post(`${BACKEND.SERVER}/files`, data
      ).then((response) => {
        // console.log(response)
        gsCtx.setItems(response.data)
      }).catch()
      
    }))
    // console.log(files)
    return new Promise((resolve, reject) => {
      resolve('imported');
    })
  }

  const importFile = () => {
    pushPost().then((res) => {
      setChecked(true);
      setFiles([]);
      props.setImportModal(false);
    }).catch(() => {
      alert('해당 파일을 불러 올 수 없거나 빈 파일일 수 있습니다.')
    })
  };

  const AlertCheck = () => {
    return (
      <Row className = 'ml-3 mb-2 align-middle'>
        <Alert variant="danger" className='m-0'>
          <Alert.Heading className = 'm-0'>주의!</Alert.Heading>
          <p className='pl-2 m-0'>
            새로운 파일로 대체시 기존 데이터를 잃게 됩니다.
          </p>
        </Alert>
      </Row>
    )
  }

  return (
    <Modal 
      show = {props.importModal} 
      centered
      onHide={()=>props.setImportModal(false)}
    >
      <Modal.Header>용어집 추가</Modal.Header>
      <Modal.Body>
        <Row>
          <UploadFile 
            project_no = {props.project_no}
            glossaryName = {props.glossaryName}
            setFiles = {setFiles}
          />
        </Row>
        {files.length > 0 && <ImportedFiles files = {files} />}
        <Modal.Footer></Modal.Footer>
        <Row className = 'ml-2'>
          <h6>추가 옵션</h6>
        </Row>
        <CheckButtonItem 
          id = {'add'}
          text = {'업로드 파일이 용어집에 추가'}
          checked = {checked}
          onClick = {CheckButtonHandler}
          available = {true}
        /> 
        <CheckButtonItem 
          id = {'replacement'}
          text = {'업로드 파일로 용어집 대체'}
          checked = {!checked}
          onClick = {CheckButtonHandler}
          available = {files.length <= 1 ?true :false}
        />
        {!checked && <AlertCheck />}
        <Modal.Footer>
          <Button 
            variant = 'light'
            onClick={()=>{
              setFiles([])
              props.setImportModal(false)}}
          >닫기</Button>
          <Button
            onClick = {importFile}
          >추가</Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
  
};

export default ImportGlossary;
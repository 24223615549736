import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// utils
import utils from "../utils/functions";
import { direction2code } from "../../../../services/utils";

// context
import DocumentContext from "../contexts/document-context";

// reactstrap
import { Button, Table, Progress, Spinner } from "reactstrap";

// service
import UploadService from "../../../../services/FileUploadService";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faTimes,
  faTasks,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const DocumentList = (props) => {
  const docCtx = useContext(DocumentContext);
  const history = useHistory();
  const [direction, setDirection] = useState("");
  const [validDirection, setValidDirection] = useState(false);
  const [docList, setDocList] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const email = userInfo ? userInfo.userEmail : null;

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const removeDocument = (event) => {
    const token = localStorage.getItem("token");

    const fileName = event.currentTarget.value;
    UploadService.deleteDocument(
      props.project_no,
      email,
      fileName,
      "documents",
      token
    )
      .then(() => {})
      .catch((error) => {
        error.response.status === 401 && history.push("/unauthorized");
      });
    docCtx.removeItem(fileName);
  };

  const checkValidDirection = () => {
    const directionCode = direction2code(
      props.directionSrc,
      props.directionTgt
    );
    if (directionCode != null) {
      setValidDirection(true);
      setDirection(directionCode);
    } else {
      setValidDirection(false);
    }
  };

  const historyPush = (event, process, filename) => {
    const region = event.target.getAttribute("name");

    if (process !== "translation" && region === "clickable" && validDirection) {
      history.push({
        pathname: `/translate/${props.project_no}/${filename}/${direction}`,
        state: { acceptance: props.acceptance },
      });
    }
  };

  useEffect(() => {
    !userInfo && history.push("/unauthorized");
  }, [userInfo]);

  useEffect(() => {
    checkValidDirection();
  }, [props.directionSrc, props.directionTgt]);

  useEffect(() => {
    docCtx.items && setDocList(docCtx.items);
  }, [docCtx.items]);

  useEffect(() => {
    if (docCtx.searchKeyword != "") {
      const searchItems = docCtx.items.filter((item) =>
        item.filename.includes(docCtx.searchKeyword)
      );
      setDocList(searchItems);
    } else {
      setDocList(docCtx.items);
    }
  }, [docCtx.searchKeyword]);
  
  // const style = {
  //   styleb: { borderBottom: "hidden" },
  //   stylet: { borderTop: "hidden" },
  // };

  const TablePC = (props) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>번호</th>
            <th>파일 이름</th>
            <th>상태</th>
            <th>업로드일</th>
            <th>업무분담</th>
            <th style={{ width: "2px" }}></th>
          </tr>
        </thead>
        <tbody>
          {docList.map((data, index) => (
            <tr
              key = {index}
              style = {{cursor: data.status != 'translation' && validDirection
                ?'pointer'
                :'not-allowed'
              }}
              onClick = {(event) => {
                historyPush(event, data.status, data.filename)
              }}
            >
              <td name="clickable" className="pb-1 pt-1">{index + 1}</td>
              <td name="clickable" className="pb-1 pt-1">
                {data.filename}({utils.humanFileSize(data.filesize)})
              </td>
              <td name="clickable" className="pb-1 pt-1">
                {data.status === "waiting" && (
                  <p className="mb-1 font-weight-bold">번역대기</p>
                )}

                {data.status === "translation" && (
                  <p className="mb-1 font-weight-bold">
                    번역 중... <Spinner color="info" size="sm" />
                  </p>
                )}
                {data.status === "translation done" && (
                  <p className="mb-1 font-weight-bold">번역완료</p>
                )}
                {data.status === "translation" && (
                  <Progress
                    className="mb-2"
                    animated
                    color="info"
                    striped
                    value={data.progress}
                  >
                    {`${data.progress.toFixed()}%`}
                  </Progress>
                )}

                {data.status === "translated" && (
                  <p className="mb-1 font-weight-bold">
                    휴먼 확인률
                    <span className="float-right">
                      {parseFloat(data.trans_percent.toFixed(2))}%
                    </span>
                  </p>
                )}
                {data.status === "translated" && (
                  <Progress className="mb-2" value={data.trans_percent} />
                )}
              </td>
              <td name="clickable" className="pb-1 pt-1">{data.uploaded}</td>
              <td className="pb-1 pt-1">
                <Link
                  to={
                    data.status != "translation" &&
                    validDirection &&
                    props.availableEdit &&
                    `/assignment/${props.project_no}/${data.filename}`
                  }
                >
                  <Button
                    color="secondary"
                    size="sm"
                    style={{
                      cursor:
                        data.status != "translation" &&
                        validDirection &&
                        props.availableEdit
                          ? "pointer"
                          : "not-allowed",
                    }}
                    disabled={!props.availableEdit}
                  >
                    <FontAwesomeIcon
                      className="pr-1"
                      icon={faTasks}
                      size="lg"
                    />
                    할당
                  </Button>
                </Link>
              </td>
              <td style={{ width: "auto" }} align="right" className="pb-1 pt-1">
                <Button
                  id={data.index}
                  value={data.filename}
                  outline
                  color="secondary"
                  className="ml-10 mr-10"
                  style={{
                    borderColor: "rgba(0,0,0,0)",
                    cursor: props.availableEdit ? "pointer" : "not-allowed",
                  }}
                  onClick={removeDocument}
                  disabled={!props.availableEdit}
                >
                  <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  };
  
  const TableMobile = (props) => {
    return (
      <div>
        {docList.map((data, index) => (
          <div
            key = {index}
            style = {{width: '100%'}}
          >
            <Table className="m-1 p-2" style = {{
              width: '100%',
              borderBottom: '1px solid #5b5b5b',
              tableLayout: 'fixed'
            }}>
            <colgroup>
              <col width="30%"/>
              <col width="70%"/>
            </colgroup>
            <tbody>
              <tr>
                <th>번호</th>
                <td name="clickable">{index + 1}</td>
              </tr>
              <tr>
                <th>파일 이름</th>
                <td 
                  name="clickable"
                  onClick = {(event) => {
                    data.status !== 'translation'
                    ? historyPush(event, data.status, data.filename)
                    : alert('번역이 완료되지 않았습니다.')
                  }}
                  style = {{wordBreak:'break-all'}}
                >
                  <p className = 'font-weight-bolder' name="clickable">
                    {data.filename}({utils.humanFileSize(data.filesize)})
                  </p>
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td name="clickable">
                  {data.status === "waiting" && (
                    <p className="mb-1 font-weight-bold">번역대기</p>
                  )}

                  {data.status === "translation" && (
                    <p className="mb-1 font-weight-bold">
                      번역 중... <Spinner color="info" size="sm" />
                    </p>
                  )}
                  {data.status === "translation done" && (
                    <p className="mb-1 font-weight-bold">번역완료</p>
                  )}
                  {data.status === "translation" && (
                    <Progress
                      className="mb-2"
                      animated
                      color="info"
                      striped
                      value={data.progress}
                    >
                      {`${data.progress.toFixed()}%`}
                    </Progress>
                  )}

                  {data.status === "translated" && (
                    <p className="mb-1 font-weight-bold">
                      휴먼 확인률
                      <span className="float-right">
                        {parseFloat(data.trans_percent.toFixed(2))}%
                      </span>
                    </p>
                  )}
                  {data.status === "translated" && (
                    <Progress className="mb-2" value={data.trans_percent} />
                  )}
                </td>
              </tr>
              <tr>
                <th>업로드일</th>
                <td name="clickable">{data.uploaded}</td>
              </tr>
              <tr>
                <th>업무분담</th>
                <td>
                  <Link
                    to={
                      data.status != "translation" &&
                      validDirection &&
                      props.availableEdit &&
                      `/assignment/${props.project_no}/${data.filename}`
                    }
                  >
                    <Button
                      color="secondary"
                      size="sm"
                      style={{
                        cursor:
                          data.status != "translation" &&
                          validDirection &&
                          props.availableEdit
                            ? "pointer"
                            : "not-allowed",
                      }}
                      disabled={!props.availableEdit}
                    >
                      <FontAwesomeIcon
                        className="pr-1"
                        icon={faTasks}
                        size="lg"
                      />
                      할당
                    </Button>
                  </Link>
                </td>
              </tr>
              <tr>
                <th>삭제</th>
                <td>
                  <Button
                    id={data.index}
                    value={data.filename}
                    outline
                    color="secondary"
                    style={{
                      borderColor: "rgba(0,0,0,0)",
                      cursor: props.availableEdit ? "pointer" : "not-allowed",
                    }}
                    onClick={removeDocument}
                    disabled={!props.availableEdit}
                  >
                    <FontAwesomeIcon icon={
                      props.availableEdit
                      ?faTrashAlt
                      :faTimes} 
                      size="lg" 
                    />
                  </Button>
                </td>
              </tr>
            </tbody></Table>
          </div>
        ))}
      </div>
    )
  };

  return (
    <React.Fragment>
      {isMobile
        ?<TableMobile {...props} />
        :<TablePC {...props} />
      }
    </React.Fragment>
  );
};

export default DocumentList;

import React, { useState, useRef } from 'react';

import { Button, Overlay, Tooltip, 
  Popover, OverlayTrigger } from 'react-bootstrap';
import { Tool } from 'react-feather';

import {ReactComponent as CheckmarkImage} from '../image/checkmark.svg';


const CheckBoxButton = (props) => {
  // const [checkmarkColor, setCheckmarkColor] = useState('#000000');
  const [checked, setChecked] = useState(props.selected === 1 ?true :false);
  const [checkmarkColor, setCheckmarkColor] = useState(
    props.selected === 1 ?'#ffffff' :'#000000');

  const ref = useRef(null);

  const onClickHandler = (event) => {
    setChecked(!checked);
    const color = checked ? '#000000' : '#ffffff';
    setCheckmarkColor(color);
    props.onButtonClick(event);
  };

  const onMouseOverHandler = (event) => {
    !checked && setCheckmarkColor('#ffffff');
  };
  const onMouseLeaveHandler = (event) => {
    !checked && setCheckmarkColor('#000000');
  };

  const renderTooltip = (props) => (
    <Tooltip 
      variant='primary' 
      id="button-tooltip"
      {...props}>
      다음번 클릭시 일괄 선택
    </Tooltip>  
  );

  return (
    <React.Fragment key = {props.number}>
      <Button
        id = {`Button-${props.number}`}
        name = 'clickableButton'
        className = 'pl-1 pr-1'
        variant={checked ?"secondary" :"outline-secondary"}
        onClick={onClickHandler}
        active = {checked}
        onMouseEnter={onMouseOverHandler}
        onMouseLeave={onMouseLeaveHandler}
        ref = {ref}
      >
        <CheckmarkImage 
          name = 'clickableButton'
          width={18}
          height={18}
          viewBox='0 0 18 26'
          fill={checkmarkColor}
        />
      </Button>
      <Overlay 
        target = {ref}
        show= {props.isButtonClicked && checked} 
        placement="bottom">
        {(props) => renderTooltip(props)}
      </Overlay>

    </React.Fragment>
  )
};

export default CheckBoxButton;
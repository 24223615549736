import axios from "axios";
import { BACKEND } from "../../Constants";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import UploadFile from "../../components/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, CardHeader, Progress, Col, Container, FormGroup, Input, Row } from "reactstrap";

// import { files } from "./filesData";

const AddFile = (props) => {
    const { project_no } = props;

    const [mail, setEmail] = useState("");
    const [thefiles, setFiles] = useState([]);

    const getDocuments = async () => {
        const token = props.token;
        const results = await axios.get(
            `${BACKEND.SERVER}/document/${project_no}`, {
            headers: {
                "Authorization": `JWT ${token}`
            },
        }
        );
        let num = parseInt(results.data[0].length);
        if (num == 0) {
            //    setTotalNum(num);
            // return <div>프로젝트 없음</div>;
        } else {
            //		console.log(results.data);
            setFiles(results.data);
        }
    }

    function humanFileSize(bytes, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + units[u];
    }

    const updateProject = (newFile) => {
        const token = localStorage.getItem('token');
        axios
            .post(BACKEND.SERVER + "/document", newFile, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    if (res.data.document) {
                        console.log("File", thefiles, newFile);
                        let newFiles = [...thefiles, newFile];
                        setFiles(newFiles)
                    } else {
                        alert(res.data.message);
                    }
                    // history.push("/projects");
                }
                console.log("success: ", res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
    }, [thefiles]);

    return (
        <Row>
            <Col md="4" xl="3">
                <Card>
                    <CardHeader>
                        <b>프로필 사진</b>
                    </CardHeader>
                    <CardBody>
                        <UploadFile />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default AddFile;

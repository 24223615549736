import React from "react";
import moment from "moment";

import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import DateTime from "react-datetime";

const InputForm = (props) => {
    const titleChange = (e) => {
        props.titleChange(e.target.value);
    };

    return (
                    <Form>
                        <Row form>
                            <Col md="4" xl="3">
	    <Card>
	    <CardBody>
                                <FormGroup>
                                    <Label className="font-weight-bold">닉 네임</Label>
                                    <Input
                                        type="title"
                                        name="title"
                                        placeholder="새 닉네임을 입력해주세요"
                                        onChange={titleChange}
                                    />
                                </FormGroup>
	    </CardBody>
	    </Card>
                            </Col>
                        </Row>
                    </Form>
    );
};
export default InputForm;

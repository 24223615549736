import React, { useState, useContext } from "react";

// context
import DocumentContext from "../contexts/document-context";

// ui
import {
  ButtonGroup,
  Button,
  Card,
  OverlayTrigger,
} from 'react-bootstrap'

const UploadOptionsRow = (props) => {
  const [translation, setTranslation] = useState('auto-translation');
  const [domain, setDomain] = useState('normal-translation');

  const docCtx = useContext(DocumentContext);

  const autoTranslationOnChange = (value) => {
    setTranslation(value);
    docCtx.changeValueForKey(
      'autoTranslation', 
      value === 'auto-translation'
      ?true
      :false);
  };
  const domainOnChange = (value) => {
    setDomain(value);
    docCtx.changeValueForKey(
      'translationDomain',
      value === 'normal-translation'
      ?'normal'
      :value === 'patent-translation'
        ?'patent'
        :'law'
    );
  };
  
  return (
    <Card>
      <Card.Header 
        className="p-1"
      >
        <p 
          className="h5 p-1 ml-1"
          style = {{
            borderBottom: 'solid 0.1rem gray',
            width: 'fit-content'
          }}
        >
          번역 옵션
        </p>
      </Card.Header>
      <Card.Body 
        className="pt-1 pl-1 pr-2 pb-2 ml-3"
      >
        <ButtonGroup>
          <OverlayTrigger
            trigger='hover'
            placement='bottom'
            overlay={
              <div 
                role='tooltip' 
                style={{
                position: 'absolute',
                marginTop: '0.1rem',
                backgroundColor: '#3ca861',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
              }}>
                <p 
                  className="m-0"
                  style = {{fontSize:'small'}}
                >
                  업로드와 함께 기계번역을 합니다.
                </p>
              </div>
            }
          >
            <Button
              key = {1}
              size='sm'
              value = 'auto-translation'
              variant={
                translation === 'auto-translation'
                ?'success'
                :'outline-success'
                }
              onClick={() => autoTranslationOnChange('auto-translation')}
            >
              <p>자동 번역</p>
            </Button>

          </OverlayTrigger>
          <OverlayTrigger
            trigger='hover'
            placement='bottom'
            overlay={
              <div 
                role='tooltip' 
                style={{
                position: 'absolute',
                marginTop: '0.1rem',
                backgroundColor: '#f0ad4e',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
              }}>
                <p 
                  className="m-0"
                  style = {{fontSize:'small'}}
                >
                  기계번역을 자동으로 하지 않습니다.
                </p>
              </div>
            }
          >
            <Button
              key = {2}
              size='sm'
              value = 'manual-translation'
              variant={
                translation === 'manual-translation'
                ?'warning'
                :'outline-warning'
                }
              onClick={() => autoTranslationOnChange('manual-translation')}
            >
              <p>수동 번역</p>
            </Button>
          </OverlayTrigger>
          
        </ButtonGroup>

        <OverlayTrigger
            trigger='hover'
            placement='bottom'
            overlay={
              <div 
                role='tooltip' 
                style={{
                position: 'absolute',
                marginTop: '0.1rem',
                backgroundColor: translation === 'auto-translation'
                  ?'#1F9BCF'
                  :'darkgray',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
              }}>
                <p 
                  className="m-0"
                  style = {{fontSize:'small'}}
                >
                  {translation === 'auto-translation'
                  ?'자동 번역시 번역 방법입니다.'
                  :'자동 번역시 허용됩니다.'
                  }
                </p>
              </div>
            }
          >
            <ButtonGroup
              className="ml-3"
            >
              <Button
                key = {1}
                size='sm'
                value = 'normal-translation'
                variant={
                  domain === 'normal-translation'
                  ?'info'
                  :'outline-info'
                  }
                onClick={() => domainOnChange('normal-translation')}
                disabled={translation === 'manual-translation'}
              >
                <p>일반 번역</p>
              </Button>
              <Button
                key = {2}
                size='sm'
                value = 'patent-translation'
                variant={
                  domain === 'patent-translation'
                  ?'info'
                  :'outline-info'
                  }
                onClick={() => domainOnChange('patent-translation')}
                disabled={translation === 'manual-translation'}
              >
                <p>특허 번역</p>
              </Button>
              <Button
                key = {3}
                size='sm'
                value = 'law-translation'
                variant={
                  domain === 'law-translation'
                  ?'info'
                  :'outline-info'
                  }
                onClick={() => domainOnChange('law-translation')}
                disabled={translation === 'manual-translation'}
              >
                <p>법률 번역</p>
              </Button>
            </ButtonGroup>
          </OverlayTrigger>
      </Card.Body>
    </Card>
  )
};

export default UploadOptionsRow;
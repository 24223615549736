import React, { useContext } from "react";

import EditorDataContext from "../../contexts/EditorDataContext";
import { Card, CardBody, Button } from "reactstrap";
import { Edit3 } from 'react-feather';

const OptionsTap = (props) => {
  const edCtx = useContext(EditorDataContext);

  const onClickReplace2Source = (event) => {
    !edCtx.checkedOrNotDict[props.index] &&
    props.onChangeLine(
      props.index, 
      edCtx.sourceSentence[props.index],
      null,
      false);
    props.saveToDB({
      key: props.index,
      line: edCtx.sourceSentence[props.index]
    });
  };

  const Replace2SourceButton = (props) => {
    return (
      <React.Fragment>
        <Button 
          size = 'sm' 
          color = 'primary' 
          className = 'mr-2'
          onClick = {onClickReplace2Source}
          id = 'src2tgt'
          disabled = {props.index === null}

        >
          <Edit3 
            height = {20}
            width = {20}
          />
        </Button>
        원문으로 대체
      </React.Fragment>
    )
  };

  return (
    <Card>
      <CardBody className ='p-2'>
        <Replace2SourceButton {...props} />
      </CardBody>
    </Card>
  )

};

export default OptionsTap;
import React from "react";
import { Link } from "react-router-dom";
import {
    Col,
    Container,
    Row
} from "reactstrap";

const Intro = () => (
    <div id="introduction" className="mb-5">
        <h3>서비스 특징</h3>
        <p className="text-lg">
            번역 PM, 번역자, 검수자가 온라인에서 AI의 도움을 받아 번역하고 검수하는 통합 서비스
        </p>
        <h3>역할별 특징</h3>
        <p className="text-lg">

        </p>
        <ul>
            <li>
                <b>번역 PM</b><br />
                이메일 대신 온라인에서<br />
                진척도 실시간 확인 <br />
                {/* 번역자 기여도 자동 측정<br /> */}
                추가 인력 투입 및 업무 분산 용이
            </li>
            <li>
                <b>번역자</b><br />
                파일만 업로드하면 자동 번역<br />
                AI 가번역 결과 중 잘된 부분은 그대로 사용하게 체크<br />
                일반, 법률, 특허 번역 AI 선택 가능<br />
                용어집과 안맞는 단어 표시<br />
                번역 메모리 지원<br />
            </li>
            <li>
                <b>검수자</b><br />
                번역 메모리 파일로 검수 진행<br />
                번역이 틀린 문장을 표시<br />
            </li>
            <li>
                <b>번역 팀</b><br />
                이메일로 팀원 초청 기능<br />
                팀원간 웹 채팅 기능<br />
            </li>
        </ul>
        <img src="/jpg/thinkcat_ad.png" />
    </div>
);

const TableOfContents = () => (
    <div id="table-of-contents" className="mb-5">
        <h3>
            이용 전 참고사항
        </h3>
        <ul>
            <li>
                <b>지원하는 언어가 영어-한글뿐인가요?</b><br />
                {/* 2022년 현재는 그렇습니다. 대신 일반적인 번역기에서 지원하지 않는 법률 및 특허 문서에 최적화된 AI 번역기를 선택하실 수 있습니다. 2023년 초에 일어와 중국어가 추가로 지원될 에정입니다. */}
                {/* 2023년 05월 기준 한국어 ↔ 일어, 중국어가 추가로 지원되고 있으며, 일반적인 번역기에서 지원하지 않는 법률 및 특허 문서에 최적화된 AI 번역기를 선택하실 수 있습니다. */}
                기존에는 영어↔한국어만 지원됐으나,<br />2023년 5월 현재 중국어↔한국어와 일본어↔한국어가 추가됐습니다.
            </li>
            <li>
                <b>지원하는 문서양식은 무엇인가요?</b><br />
                {/* 현재는 MS-Word (*.Docx)만 지원합니다. Docx 파일 본문의 폰트나 색깔 등의 양식을 모두 살려서 번역됩니다. 2022년 초에 자막 파일 (*.srt)와 텍스트 파일 (*.txt)이 추가될 예정입니다. 아래아 한글 (*.Hwp)이나 Adobe PDF (*.Pdf) 파일의 지원도 계획중입니다. */}
                현재는 MS-Word (*.Docx), PDF (*.pdf), 자막파일 (*.srt, *.smi) 그리고 텍스트 (.txt)만 지원합니다. Docx, PDF 파일 본문의 폰트나 색깔 등의 양식을 모두 살려서 번역됩니다. 추후 다른 파일 형식들이 추가될 예정입니다.
            </li>
            <li>
                <b>PC에서만 사용가능한가요?</b><br />
                반응형 웹으로 개발되어 스마트폰이나 태블릿에서도 편리하게 사용하실 수 있습니다. 또한 싱글 페이지 애플리케이션으로 개발되어 설치형 소프트웨어과 유사한 사용자 경험을 제공해드립니다.
            </li>
            <li>
                <b>사용 요금은 어떻게 되나요?</b><br />
                2022년 12월 현재는 테스트 기간으로 모든 기능이 무료입니다. 2023년 초에 유료화 될 예정입니다.
            </li>
        </ul>

    </div>
);

const SomethingMissing = () => (
    <div id="something-missing" className="mb-5">
        <h3>서비스 </h3>

        {/* <ul className="text-lg">
            <li>
                <Link to="/docs/getting-started">어떻게 프로젝트를 시작하나요?</Link>
            </li>
            <li>
                <Link to="/docs/environment-variables">채팅은 어떻게 할 수 있나요?</Link>
            </li>
            <li>
                <Link to="/docs/deployment">프로젝트에 오류가 있습니다.</Link>
            </li>
            <li>
                <Link to="/docs/state-management">시간은 어떻게 정할 수 있나요?</Link>
            </li>
            <li>
                <Link to="/docs/plugins">프로젝트를 수정하고 싶습니다.</Link>
            </li>
            <li>
                <Link to="/docs/changelog">작업이 끝난 문서를 받으려면 어떻게 해야하나요</Link>
            </li>
        </ul> */}
        <p className="text-lg">
            본 서비스는 한국지능정보사회진흥원의 말뭉치와 서울산업진흥원의 연구비 지원을 받은 사업결과입니다.
        </p>
        <p className="text-lg">
            메뉴얼에 누락된 부분이 있거나 헷갈리는 부분이 있으시면 아래 주소로 이메일을 보내주세요.<br />
            우리는 당신의 소식을 듣는 것을 좋아해요! 씽캣은 당신을 통해 더욱 개선되어질 것입니다<br />
            이메일 주소: <a href="mailto:thinkcat@anyfive.com">thinkcat@thinkcat.tools</a>
        </p>
    </div>
);

const Introduction = () => (
    <Container fluid className="p-0">
        <Row>
            <Col lg={10} xl={8} className="mx-auto">
                <h1 className="h3">도움말</h1>
                <hr className="my-4" />
                <Intro />
                <TableOfContents />
                <SomethingMissing />
                {/* <Contents /> */}
            </Col>
        </Row>
    </Container>
);

export default Introduction;

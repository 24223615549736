import React from "react";

import axios from 'axios';

import { BACKEND } from "../../../Constants";

const BackTranslate = (data) => {
    const promise = new Promise(async (resolve, reject) => {
        await axios.post(
            `${BACKEND.SERVER}/backTranslate`, data
            ).then((response) => {
                resolve(response.data);
            })
    });
    return promise;
};

export default BackTranslate;
import React from "react";

const TextAreaSource = (props) => {
  const glossaryData = props.glossaryData;

  const getIntersection = (array1, array2, unique = true) => {
    const intersection = array1.filter(element => array2.includes(element));
    if (unique) {
      return [...new Set(intersection)];
    }
    return intersection;
  };

  const glossaryOnHandler = (activeList) => {
    props.setGlossaryActive(activeList);
  };

  if (glossaryData) {
    if (glossaryData.length > 0) {
      return (
        <div>
          {glossaryData.map((value, index) => {
            const intersection = value[1]
              ?getIntersection(value[1].glossary_index, props.glossaryActive)
              :[];
            const activeAlpha = (value[1] && props.index === props.currentActive)
              ?0.2*intersection.length >= 0.6
                ?0.6
                :0.2 + 0.2*intersection.length
              :0.2;

            return (
              value[1]
              ?<span
                key = {index} 
                style = {{
                  backgroundColor: `rgba(108, 108, 250, ${activeAlpha})`,
                  cursor: 'pointer'
                }}
                onClick = {() => {glossaryOnHandler(value[1].glossary_index)}}
              >
                {value[0]}
              </span>
              :<span key = {index}>
                {value[0]}
              </span>
            )
          })}
        </div>
      )
    }
  }

  return props.text
};

export default TextAreaSource;

import axios from "axios";
import React, { useState, useEffect, useContext } from "react";

import { Button, Card, Col, Container, FormGroup, Input, Row } from "reactstrap";

// components
import TeamContext from "../contexts/TeamContext";
import AddTeam from "./AddTeam";
import AddTeamArea from "./AddTeamArea";
import TeamList from "./TeamList";

import AuthContext from "../../../../store/auth-context";

// loading
import LoadingModal from "../../progress/LoadingModal";
import loadingGIF from '../../progress/backTranslation.gif';

const TeamTab = (props) => {
    const { project_no } = props;
    const [showAddModal, setShowAddModal] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const teamContext = useContext(TeamContext);
    const authCtx = useContext(AuthContext);
    const socket = authCtx.socket;

    useEffect(() => {
        if (project_no !== undefined && project_no !== 'unknown') {
            teamContext.initProject(project_no);
        }
    }, [teamContext.project_no]);

    return (
        <Container>
            <LoadingModal 
                children = '불러오는중...'
                gif = {loadingGIF}
                show = {!loaded}
            />
            <AddTeam
                project_no={project_no}
                show={showAddModal}
                setShow={setShowAddModal}
                socket={socket}
            />
            <AddTeamArea
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                currentUserRole={props.currentUserRole}
            />
            <TeamList
                socket={socket}
                currentUserRole={props.currentUserRole}
                isActive = {props.isActive}
                setLoaded = {setLoaded}
            />
        </Container>
    );
};

export default TeamTab;

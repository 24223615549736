import React, { useState, useEffect } from 'react';
import classes from './SwitchButton.module.css'

const SwitchButton = (props) => {
  const [checked, setChecked] = useState(false);

  const onChangeHandler = (event) => {
    props.onChangeHandler(event);
    setChecked(!checked);
  };

  // useEffect(() => {
  //   setChecked(true);
  // }, [props.selected])
  return (
    <label className= 'm-0'
      style = {{
        verticalAlign: 'middle',
      }}>
      <span className={classes.switchWrapper}>
        <input
          id = {props.index}
          type="checkbox"
          checked={checked}
          disabled={props.disabled || false}
          onChange={onChangeHandler}

        />
        <span className={classes.switch}
          style = {{
            cursor: props.disabled
            ?'no-drop'
            :'pointer',
            backgroundColor: 
            props.disabled
            ? 'rgb(255 137 137)'
            :
              checked
              ? '#0088ff'
              : '#CCE5FF'
          }}>
          <span className={classes.switchHandle} />
        </span>
      </span>
    </label>
  )
};

export default SwitchButton;

import async from "../components/Async";

import {
    Bell as Bellicon,
    BookOpen as BookOpenIcon,
    Calendar as CalendarIcon,
    CheckSquare as CheckSquareIcon,
    Grid as GridIcon,
    Heart as HeartIcon,
    Layout as LayoutIcon,
    List as ListIcon,
    MapPin as MapPinIcon,
    Monitor as MonitorIcon,
    PieChart as PieChartIcon,
    Sliders as SlidersIcon,
    Users as UsersIcon,
    FileText as FileTextIcon,
    Folder as FolderIcon,
    Upload,
    Coffee,
    GitHub,
    CreditCard,
    CheckSquare,
    Edit as EditIcon,
    MessageCircle,

} from "react-feather";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import SignUpRequest from "../pages/auth/SignUpRequest";
import SignOut from "../pages/auth/SignOut";
import ResetPassword from "../pages/auth/ResetPassword";
import Withdrawal from "../pages/auth/Withdrawal";
import TermsPolicyView from "../pages/auth/TermsPolicyView";
import Personal from "../pages/auth/Personal";
import Page401 from "../pages/auth/Page401";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
import Boxed from "../pages/layouts/Boxed";
import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
import SidebarSticky from "../pages/layouts/SidebarSticky";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeCorporate from "../pages/layouts/ThemeCorporate";
import ThemeModern from "../pages/layouts/ThemeModern";

// Misc
import Read from "../pages/boards/Read";
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import Carousel from "../pages/ui-elements/Carousel";
import EmbedVideo from "../pages/ui-elements/EmbedVideo";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";

// Notifications
// import Notifications from "../pages/notifications/Notifications";

// Users
import Notification from "../pages/users/Notification";
// import Profile from "../pages/users/Profile";
import EditProfile from "../pages/users/EditProfile";
import Payment from "../pages/users/Payment";

// Pages
import Profile from "../pages/pages/Profile";
import Settings from "../pages/pages/Settings";
import Clients from "../pages/pages/Clients";
import Projects from "../pages/projects/Project/parts/Projects";
import SearchProjects from "../pages/projects/Project/parts/SearchProjects";
import Uploader from "../pages/editor/Uploader";
import Editor from "../pages/editor/Editor";
import Translate from "../pages/editor/Translate";
import Inspector from "../pages/editor/Inspector";
import Invoice from "../pages/pages/Invoice";
import Pricing from "../pages/pages/Pricing";
import Tasks from "../pages/pages/Tasks";
import Chat from "../pages/chat/Chat";
import TaskAssignment from '../pages/editor/TaskAssignment';
import Support from "../pages/boards/Support";
import Search from "../pages/boards/Search";
import ElasticSearch from "../pages/search/ElasticSearch";

// Pages.glossary
import GlossaryEditor from "../pages/editor/glossary/parts/Index";

// Documentation
import Introduction from "../pages/docs/Introduction";
import GettingStarted from "../pages/docs/GettingStarted";
import EnvironmentVariables from "../pages/docs/EnvironmentVariables";
import Deployment from "../pages/docs/Deployment";
import StateManagement from "../pages/docs/StateManagement";
import Plugins from "../pages/docs/Plugins";
import Changelog from "../pages/docs/Changelog";
import None from "../pages/None";
// import AddProject from "../pages/projects/Project/parts/AddProject.legacy";
import EditProject from "../pages/projects/Project/parts/EditProject";

import UploadFile from "../pages/upload/parts/UploadFile";

// 401 error
import UnauthorizedPage from "../components/UnauthorizedPage";

// various purpose error page
import ErrorPage from "../components/ErrorPage";

// test page
import Monitor from "../pages/dashboard/monitor/Index";


// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const Ecommerce = async(() => import("../pages/dashboards/Ecommerce"));
const Crypto = async(() => import("../pages/dashboards/Crypto"));
const Social = async(() => import("../pages/dashboards/Social"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicInputs = async(() => import("../pages/forms/BasicInputs"));
const AdvancedInputs = async(() => import("../pages/forms/AdvancedInputs"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));
const Wizard = async(() => import("../pages/forms/Wizard"));
const Add = async(() => import("../pages/boardforms/Add"));
const Edit = async(() => import("../pages/boardforms/Edit"));
const Delete = async(() => import("../pages/boardforms/Delete"));
const DeleteComment = async(() => import("../pages/boardforms/DeleteComment"));

// Board Forms
const BoardLayouts = async(() => import("../pages/boardforms/Layouts"));
const BoardBasicInputs = async(() => import("../pages/boardforms/BasicInputs"));
const BoardAdvancedInputs = async(() => import("../pages/boardforms/AdvancedInputs"));
const BoardInputGroups = async(() => import("../pages/boardforms/InputGroups"));
const BoardAdd = async(() => import("../pages/boardforms/Add"));
const BoardEdit = async(() => import("../pages/boardforms/Edit"));
const BoardDelete = async(() => import("../pages/boardforms/Delete"));
const BoardDeleteComment = async(() => import("../pages/boardforms/DeleteComment"));
const BoardValidation = async(() => import("../pages/boardforms/Validation"));
const BoardWizard = async(() => import("../pages/boardforms/Wizard"));


// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const PaginationTables = async(() => import("../pages/tables/Pagination"));
const RowSelectionTables = async(() => import("../pages/tables/RowSelection"));
const ExportCsvTables = async(() => import("../pages/tables/ExportCsv"));
const ExpandableRowsTables = async(() => import("../pages/tables/ExpandableRows"));

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

const tempRoutes = {
    path: '/temp',
    component: Monitor
}


// Routes
const landingRoutes = {
    path: "/",
    name: "Landing Page",
    component: Landing,
    children: null,
};

const uploadRoutes = {
    path: "/upload",
    name: "간편 번역",
    header: "작업",
    icon: FileTextIcon,
    component: UploadFile,
    children: null,
};
const editorRoutes = {
    path: "/editor/:filename/:direction",
    name: "편집",
    header: "작업",
    icon: SlidersIcon,
    component: Translate,
    children: null,
};
const translateRoutes = {
    path: "/translate/:project_no/:filename/:direction",
    name: "번역",
    header: "작업",
    icon: SlidersIcon,
    component: Translate,
    children: null,
};
const inspectRoutes = {
    path: "/inspector/:project_no/:filename/:direction",
    name: "검수",
    header: "작업",
    icon: SlidersIcon,
    component: Inspector,
    children: null,
};

const projectRoutes = {
    path: "/projects",
    name: "번역 프로젝트",
    icon: EditIcon,
    component: Projects,
    children: null,
    state: { type: 'translation' }
}

const postEditingRoutes = {
    path: "/projects",
    name: "검수 프로젝트",
    icon: CheckSquare,
    component: Projects,
    children: null,
    state: { type: 'post-editing' }
}

const searchProjectRoutes = {
    path: "/search_pjt",
    component: SearchProjects
};

// const addprojectRoutes = { 
//     path: "/projects/add", 
//     name: "Add projects", 
//     component: AddProject };

const editprojectRoutes = {
    path: "/projects/edit/:projectNo",
    name: "Edit projects",
    component: EditProject
};

// TaskAssignment
const taskAssignmentRoutes = {
    path: "/assignment/:project_no/:filename",
    name: '작업 할당',
    header: '작업',
    icon: SlidersIcon,
    component: TaskAssignment,
    children: null,
};

// GlossaryEditor
const glossaryEditorRoute = {
    path: '/editGlossary/:direction/:project_no/:name',
    name: '용어집 편집',
    component: GlossaryEditor
}

// 401 error page
const UnauthorizedPageRoute = {
    path: '/unauthorized',
    component: UnauthorizedPage
}

// general purpose error page
const generalErrorPageRoute = {
    path: '/error/:status',
    component: ErrorPage
}

const supportsRoutes = {
    path: "/support",
    name: "게시판",
    icon: Coffee,
    component: Support,
    children: null,
}
const supportRoutes = {
    path: "/support/:query?",
    name: "공지 및 도움말",
    icon: Coffee,
    component: Support,
    children: null,
}

const searchRoutes = {
    path: "/support-search/:query?",
    name: "토론",
    header: "작업",
    icon: SlidersIcon,
    component: Search,
    children: null,
};

const elasticRoutes = {
    path: "/el-search",
    components: ElasticSearch,
}

const chatRoutes = {
    path: "/chats/:query?",
    header: "채팅",
    name: "1:1 채팅",
    icon: MessageCircle,
    state: { type: "personal" },
    component: Chat,
    children: null,
};

const inquiryChatRoutes = {
    path: "/chats",
    header: "고객센터",
    name: "1:1 문의",
    icon: MessageCircle,
    state: { type: "operation" },
    component: Chat,
    children: null,
};

const inquireRoutes = {
    path: "/chats/1",
    header: "고객센터",
    name: "1:1 문의",
    icon: GitHub,
    component: Chat,
    children: null,
}

// const projectRoutes = {
//     path: "/projects",
//     name: "Projects",
//     icon: ListIcon,
//     component: Projects,
//     children: [
//         {
//             path: "/projects/add", 
//             name: "Add project", 
//             component: AddProject
//         },
//         {
//             path: "/projects/edit", 
//             name: "Edit project", 
//             component: EditProject
//         },
//         {
//             path: "/projects/assignment/:project_no/:filename",
//             name: 'Task Assigment',
//             component: TaskAssignment
//         },
//     ],
// };
const notificationsRoutes = {
    path: "/notifications",
    name: "Notification",
    icon: Bellicon,
    component: Notification,
    children: null,
};
const profileRoutes = {
    path: "/profile",
    name: "Profile",
    component: Profile,
    children: null,
};
const paymentRoutes = {
    path: "/payment",
    name: "구독하기",
    icon: CreditCard,
    component: Payment,
};
const editprofile = { path: "/profile/edit", name: "Edit profile", component: EditProfile };

const pageRoutes = {
    path: "/pages",
    name: "Linguistic Assets",
    icon: LayoutIcon,
    header: 'empty page',
    children: [
        {
            path: "/pages/clients",
            name: "Translation Memories",
            component: Clients,
        },
        {
            path: "projects",
            name: "Global Search",
            component: Projects,
        },
        // {
        //     path: "/pages/projects",
        //     name: "Linguistic Assets",
        //     component: Projects,
        // },

        // {
        //     path: "/pages/invoice",
        //     name: "Invoice",
        //     component: Invoice,
        // },
        // {
        //     path: "/pages/pricing",
        //     name: "Pricing",
        //     component: Pricing,
        // },
        // {
        //     path: "/pages/pricing",
        //     name: "Payments",
        //     component: Pricing,
        // },
        // {
        //     path: "/pages/tasks",
        //     name: "Reports",
        //     component: Tasks,
        // },
        // {
        //     path: "/pages/settings",
        //     name: "Settings",
        //     component: Settings,
        // },
        // {
        //     path: "/pages/tasks",
        //     name: "Tasks",
        //     component: Tasks,
        // },
        // {
        //     path: "/pages/chat",
        //     name: "Chat",
        //     component: Chat,
        //     badgeColor: "primary",
        //     badgeText: "New",
        // },
        // {
        //     path: "/pages/blank",
        //     name: "Blank Page",
        //     component: Blank,
        // },
    ],
};

const authRoutes = {
    path: "/auth",
    name: "Auth",
    icon: UsersIcon,
    badgeColor: "secondary",
    badgeText: "Special",
    children: [
        {
            path: "/auth/sign-in",
            name: "Sign In",
            component: SignIn,
        },
        {
            path: "/auth/sign-up",
            name: "Sign Up",
            component: SignUp,
        },
        {
            path: "/auth/chk-request/:query?",
            name: "Sign Up Check",
            component: SignUpRequest,
        },
        {
            path: "/auth/sign-out",
            name: "Sign Out",
            component: SignOut,
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword,
        },
        {
            path: "/auth/withdrawal",
            name: "User Withdrawal",
            component: Withdrawal,
        },
        {
            path: "/auth/401",
            name: "401 Page",
            component: Page401,
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
        },
    ],
};

const docsPolicyRoutes = {
    path: "/docs/policy",
    name: "이용약관",
    component: TermsPolicyView,
}
const docsPersonalRoutes = {
    path: "/docs/personal",
    name: "개인정보취급방침",
    component: Personal,
}

const layoutRoutes = {
    path: "/layouts",
    name: "Layouts",
    icon: MonitorIcon,
    children: [
        {
            path: "/layouts/sidebar-sticky",
            name: "Sticky Sidebar",
            component: SidebarSticky,
        },
        {
            path: "/layouts/sidebar-collapsed",
            name: "Sidebar Collapsed",
            component: SidebarCollapsed,
        },
        {
            path: "/layouts/boxed",
            name: "Boxed Layout",
            component: Boxed,
        },
        {
            path: "/layouts/theme-classic",
            name: "Classic Theme",
            component: ThemeClassic,
        },
        {
            path: "/layouts/theme-corporate",
            name: "Corporate Theme",
            component: ThemeCorporate,
            badgeColor: "primary",
            badgeText: "New",
        },
        {
            path: "/layouts/theme-modern",
            name: "Modern Theme",
            component: ThemeModern,
            badgeColor: "primary",
            badgeText: "New",
        },
    ],
};

const documentationRoutes = {
    path: "/docs",
    name: "Documentation",
    icon: BookOpenIcon,
    children: [
        {
            path: "/docs/introduction",
            name: "Introduction",
            component: Introduction,
        },
        {
            path: "/docs/getting-started",
            name: "Getting Started",
            component: GettingStarted,
        },
        {
            path: "/docs/environment-variables",
            name: "Environment Variables",
            component: EnvironmentVariables,
        },
        {
            path: "/docs/deployment",
            name: "Deployment",
            component: Deployment,
        },
        {
            path: "/docs/state-management",
            name: "State Management",
            component: StateManagement,
        },
        {
            path: "/docs/plugins",
            name: "Plugins",
            component: Plugins,
        },
        {
            path: "/docs/changelog",
            name: "Changelog",
            component: Changelog,
        },
    ],
};

const uiRoutes = {
    path: "/ui",
    name: "UI Elements",
    header: "Tools & Components",
    icon: GridIcon,
    children: [
        {
            path: "/ui/alerts",
            name: "Alerts",
            component: Alerts,
        },
        {
            path: "/ui/buttons",
            name: "Buttons",
            component: Buttons,
        },
        {
            path: "/ui/cards",
            name: "Cards",
            component: Cards,
        },
        {
            path: "/ui/carousel",
            name: "Carousel",
            component: Carousel,
        },
        {
            path: "/ui/embed-video",
            name: "Embed Video",
            component: EmbedVideo,
        },
        {
            path: "/ui/general",
            name: "General",
            component: General,
            badgeColor: "info",
            badgeText: "10+",
        },
        {
            path: "/ui/grid",
            name: "Grid",
            component: Grid,
        },
        {
            path: "/ui/modals",
            name: "Modals",
            component: Modals,
        },
        {
            path: "/ui/tabs",
            name: "Tabs",
            component: Tabs,
        },
        {
            path: "/ui/typography",
            name: "Typography",
            component: Typography,
        },
    ],
};

const iconRoutes = {
    path: "/icons",
    name: "Icons",
    icon: HeartIcon,
    badgeColor: "info",
    badgeText: "1500+",
    children: [
        {
            path: "/icons/feather",
            name: "Feather",
            component: Feather,
        },
        {
            path: "/icons/font-awesome",
            name: "Font Awesome",
            component: FontAwesome,
        },
    ],
};

const formRoutes = {
    path: "/forms",
    name: "Forms",
    icon: CheckSquareIcon,
    children: [
        {
            path: "/forms/layouts",
            name: "Layouts",
            component: Layouts,
        },
        {
            path: "/forms/basic-inputs",
            name: "Basic Inputs",
            component: BasicInputs,
        },
        {
            path: "/forms/input-groups",
            name: "Input Groups",
            component: InputGroups,
        },
    ],
};

const formPluginsRoutes = {
    path: "/form-plugins",
    name: "Form Plugins",
    icon: CheckSquareIcon,
    header: "Plugin & Addons",
    children: [
        {
            path: "/form-plugins/advanced-inputs",
            name: "Advanced Inputs",
            component: AdvancedInputs,
        },
        {
            path: "/form-plugins/editors",
            name: "Editors",
            component: Editors,
        },
        {
            path: "/form-plugins/validation",
            name: "Validation",
            component: Validation,
        },
        {
            path: "/form-plugins/wizard",
            name: "Wizard",
            component: Wizard,
        },
        {
            path: "/form/add/:query",
            name: "Add",
            component: Add,
        },
        {
            path: "/form/edit/:query",
            name: "Edit",
            component: Edit,
        },
        {
            path: "/form/delete/:query",
            name: "Delete",
            component: Delete,
        },
        {
            path: "/form/delete_comment/:query",
            name: "DeleteComment",
            component: DeleteComment,
        },

    ],
};

const advancedTablesRoutes = {
    path: "/advanced-tables",
    name: "Advanced Tables",
    icon: ListIcon,
    children: [
        {
            path: "/advanced-tables/pagination",
            name: "Pagination",
            component: PaginationTables,
        },
        {
            path: "/advanced-tables/row-selection",
            name: "Row Selection",
            component: RowSelectionTables,
        },
        {
            path: "/advanced-tables/expandable-rows",
            name: "Expandable Rows",
            component: ExpandableRowsTables,
        },
        {
            path: "/advanced-tables/export-csv",
            name: "Export CSV",
            component: ExportCsvTables,
        },
    ],
};

const chartRoutes = {
    path: "/charts",
    name: "Payments",
    icon: PieChartIcon,
    component: None,
    badgeColor: "primary",
    // badgeText: "New",
    children: null,
    // [
    //     {
    //         path: "/charts/chartjs",
    //         name: "Chart.js",
    //         component: Chartjs,
    //     },
    //     {
    //         path: "/charts/apexcharts",
    //         name: "ApexCharts",
    //         component: ApexCharts,
    //         badgeColor: "primary",
    //         badgeText: "New",
    //     },
    // ],
};

const mapRoutes = {
    path: "/maps",
    name: "Maps",
    icon: MapPinIcon,
    children: [
        {
            path: "/maps/google-maps",
            name: "Google Maps",
            component: GoogleMaps,
        },
        {
            path: "/maps/vector-maps",
            name: "Vector Maps",
            component: VectorMaps,
        },
    ],
};

const calendarRoutes = {
    path: "/calendar",
    name: "Settings",
    icon: CalendarIcon,
    component: None,
    children: null,
};

// This route is not visisble in the sidebar
const privateRoutes = {
    path: "/private",
    name: "Private",
    children: [
        {
            path: "/private/blank",
            name: "Blank Page",
            component: Blank,
        },
    ],
};

const privateSupportRoutes = {
    path: "/support/:query",
    name: "Read",
    component: Read,
    children: null,
};
// Dashboard specific routes
export const dashboard = [
    uploadRoutes,
    editorRoutes,
    translateRoutes,
    inspectRoutes,
    pageRoutes,
    layoutRoutes,
    documentationRoutes,
    uiRoutes,
    iconRoutes,
    formRoutes,
    projectRoutes,
    searchProjectRoutes,
    // addprojectRoutes,
    editprojectRoutes,
    taskAssignmentRoutes,

    formPluginsRoutes,
    advancedTablesRoutes,
    chartRoutes,
    // notificationsRoutes,
    mapRoutes,
    calendarRoutes,
    privateRoutes,
    privateSupportRoutes,

    glossaryEditorRoute,
    UnauthorizedPageRoute,
    generalErrorPageRoute,
    tempRoutes,

    searchRoutes,
    supportsRoutes,
    supportRoutes,

    chatRoutes,

    docsPolicyRoutes,
    docsPersonalRoutes,

    notificationsRoutes,
    profileRoutes,
    editprofile,

    paymentRoutes,
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
    uploadRoutes,
    projectRoutes,
    postEditingRoutes,
    paymentRoutes,
    // chatRoutes,
    inquiryChatRoutes,
    supportsRoutes,
    // pageRoutes,
    // authRoutes,
    // layoutRoutes,
    // documentationRoutes,
    // uiRoutes,
    // iconRoutes,
    // formRoutes,
    // formPluginsRoutes,
    // advancedTablesRoutes,
    // chartRoutes,
    // notificationsRoutes,
    // mapRoutes,
    // calendarRoutes,
    elasticRoutes,

];

import React, { useReducer } from 'react';

import DocumentContext from './file-context';
import { defaultProjectInfoState } from '../../projects/Project/contexts/ProjectInfoProvider';

const defaultDocumentState = {
    items: [],
    LANGUAGEOPTIONS: defaultProjectInfoState.LANGUAGEOPTIONS,
};

const DocumentReducer = (state, action) => {
    if (action.type === 'add') {
        const updatedItems = state.items.concat(action.item);
        //console.log(action.item);
        return {
            ...state,
            items: updatedItems
        }   
    }
    else if (action.type === 'remove') {
        const removedItems = state.items.filter(
            item => item.filename != action.name         
        );
        return {
            ...state,
            items: removedItems
        }
    }
    else if (action.type === 'setItems') {
        return {
            ...state,
            items: action.items
        }
    }
    else if (action.type === 'changeState') {
        const updateIndex = state.items.findIndex((item) =>
            item.filename === action.item.filename);
        const updateItem = {
            ...state.items[updateIndex],
            direction: action.item.direction
            ? action.item.direction
            : state.items[updateIndex].direction,
            status: action.item.status
            ? action.item.status
            : state.items[updateIndex].status,
            progress: action.item.progress
            ? action.item.progress
            : 0};
        const updateItems = state.items;
        updateItems[updateIndex] = updateItem;

        return {
            ...state,
            items: updateItems
        }
    }
    else if (action.type === 'setValueForKey') {
        const updateItem = {
            ...state.items[action.index],
            [action.key]: action.value
        }
        const updateItems = state.items;
        updateItems[action.index] = updateItem;

        return {
            ...state,
            items: updateItems
        }
    }
};

const DocumentProvider = (props) =>{
    const [documentState, dispatchDocumentState] = useReducer(
        DocumentReducer, defaultDocumentState);
    
    const setDocumentItems = (items) => {
        dispatchDocumentState({type: 'setItems', items: items});
    };
    const addDocument = (item) => {
        dispatchDocumentState({type: 'add', item: item});
    };
    const removeDocument = (fileName) => {
        dispatchDocumentState({type: 'remove', name: fileName});
    };
    const changeState = (state) => {
        dispatchDocumentState({type: 'changeState', item: state})
    };
    const setValueForKey = (index, key, value) => {
        dispatchDocumentState({
            type: 'setValueForKey', 
            index, key, value})
    }
   
    const documentContext = {
        items: documentState.items,
        LANGUAGEOPTIONS: documentState.LANGUAGEOPTIONS,

        setItems: setDocumentItems,
        addItem: addDocument,
        removeItem: removeDocument,
        changeState: changeState,
        setValueForKey: setValueForKey
    };

    return (
        <DocumentContext.Provider value = {documentContext}>
            {props.children}
        </DocumentContext.Provider>
    )
}

export default DocumentProvider;


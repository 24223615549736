import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// UI
import SelectList from "../UI/SelectList";
import CheckButton from "../UI/CheckButton";
import CheckSwitch from "../UI/CheckSwitch";

// files
import Download from "../files/Download";

// react-bootstrap
import {
  Col,
  Row,
  Button,
  ListGroup,
  Stack,
  Navbar,
  DropdownButton,
  Dropdown,
  Modal
} from "react-bootstrap";

// // reducer
// import { useSelector, useDispatch } from 'react-redux';
// import actions from '../store/editorDataActions';

// context
import EditorDataContext from "../contexts/EditorDataContext";
import reducerActions from "../contexts/reducerActions";

const TopOptions = (props) => {
  // // redux
  // const dispatch = useDispatch();
  // context
  const edCtx = useContext(EditorDataContext);

  const linesNumber = edCtx.linesNumber;
  const checkedOrNotDict = edCtx.checkedOrNotDict;
  const currentFile = edCtx.fileName;
  const direction = edCtx.direction;
  const progressState = edCtx.progressState;
  const stsScoreDict = edCtx.stsScoreDict;
  const dispatch = edCtx.dispatch;

  const [isProject, setIsProject] = useState(false);
  const [reTranslationCheck, setReTranslationCheck] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(edCtx.domain);

  const history = useHistory();

  // number of user checking
  const [userCheckNumber, setUserCheckedNumber] = useState(0);

  const onSelectDomain = (value) => {
    // console.log(value)
    const domain = domainCodeConverter(value, null);
    setSelectedDomain(domain);
    setReTranslationCheck(true);
  };

  const domainCodeConverter = (text = null, code = null) => {
    const domain_dict = {
      일반: "normal",
      특허: "patent",
      법률: "law",
    };
    if (text && code) {
      throw 'can not exists both text, code in the same time.'
    }
    if (text) {
      return Object.keys(domain_dict).includes(text)
      ? domain_dict[text]
      : "normal";
    }
    if (code) {
      const text = Object.keys(domain_dict).find(key => 
        domain_dict[key] === code);
      return text? text :'일반'
    }
  }

  // get entire sts score
  const getSTSScore = (event) => {
    props.stsCheck(null, null, null);
  };

  // get number of user checking
  // now use since 221221~
  const getUserCheckedNumber = (checkedOrNotDict, stsScoreDict) => {
    if (props.editorType === "번역") {
      setUserCheckedNumber(
        Object.values(checkedOrNotDict).filter((value) => value).length
      );
    } else if (props.editorType === "검수") {
      let count = 0;
      Object.values(stsScoreDict).map((value, index) => {
        if (
          value != -1 &&
          edCtx.sourceSentence[index] != "" &&
          edCtx.sourceSentence[index] != "<p><br></p>"
        ) {
          count += 1;
        }
      });
      setUserCheckedNumber(count);
    }
  };

  const setDomainWithAsync = async (selectedDomain) => {
    reducerActions.setDomain(dispatch, selectedDomain);
  };

  const reTranslationOnHandler = async (e, reTranslation = false) => {
    setReTranslationCheck(false);
    if (reTranslation) {
      reducerActions.setDomain(dispatch, selectedDomain);
      props.translation(currentFile, "true", direction, "fetching", selectedDomain);
    }
    else {
      setSelectedDomain(edCtx.domain);
    } 
  }

  // useEffect(() => {
  //   getUserCheckedNumber(checkedOrNotDict, stsScoreDict);
  // }, [checkedOrNotDict, stsScoreDict]);
  useEffect(() => {
    if (props.editorType === '번역'){
      setUserCheckedNumber(edCtx.trans_count);
    }
    else if (props.editorType === '검수'){
      setUserCheckedNumber(edCtx.postEdit_count);
    }
    
  }, [edCtx.trans_count, edCtx.postEdit_count])

  useEffect(() => {
    setSelectedDomain(edCtx.domain);
  }, [edCtx.domain])

  useEffect(() => {
    if (props.project_no === "individual" || props.project_no === "tempFiles") {
      setIsProject(false);
    } else {
      setIsProject(true);
    }
  }, []);

  return (
    <Navbar
      className="pt-1 pb-1 mb-0"
      sticky="top"
      bg="white"
      expand
    >
      <Stack gap={1} style={{ width: "100%" }}>
        <Row>
          <Col className="pt-3 p-1" sm={2}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.project_no ? history.goBack() : history.push("/upload");
              }}
            >
              <FontAwesomeIcon id="left" icon={faChevronLeft} />
              &nbsp; {isProject ? "프로젝트 설정" : "돌아가기"}
            </span>
          </Col>
          <Col className="p-1" sm={8}>
            <ListGroup variant="flush">
              <ListGroup.Item className="m-0 p-1">
                {`파일: ${currentFile} ${props.editorType} 진행률: ${userCheckNumber}/${linesNumber}`}
              </ListGroup.Item>
              <ListGroup.Item className="m-0 p-1">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-info progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={userCheckNumber}
                    aria-valuemin="0"
                    aria-valuemax={linesNumber}
                    style={{
                      width: (userCheckNumber / linesNumber) * 100 + "%",
                    }}
                  >
                    {((userCheckNumber / linesNumber) * 100).toFixed(2)}%
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
            {/* {props.limitedOptions && <p className = 'pt-2'>{`파일: ${currentFile}`}</p>} */}
          </Col>
        </Row>
        {props.acceptance && (
          <Row>
            <Col className="p-1" sm={2}>
              {!props.limitedOptions && (
                <SelectList
                  title="번역어체"
                  items={["일반", "특허", "법률"]}
                  directionSelect={onSelectDomain}
                  disabled={props.waiting}
                  default={domainCodeConverter(null, selectedDomain)}
                />
              )}
            </Col>
            <Col
              className="p-1"
              sm={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              {!props.limitedOptions && (
                <React.Fragment>
                  <Button
                    size="sm"
                    className="m-1"
                    variant="light"
                    onClick={() => setReTranslationCheck(true)}
                    disabled={props.waiting}
                  >
                    일괄 재번역
                  </Button>
                  <Modal 
                    show={reTranslationCheck}
                    onHide={() => setReTranslationCheck(false)}
                    size = 'sm'
                    centered
                  >
                    <Modal.Body>
                      <strong>일괄 재번역시 <span style = {{color:'red'}}>현재의 번역문을 잃게</span> 됩니다.</strong>
                    </Modal.Body>
                    <Modal.Footer
                      className="p-1"
                    >
                    <Button 
                      variant="success"
                      className='mr-4'
                      onClick = {(e) => 
                        reTranslationOnHandler(e, true)}
                    >
                      번역
                    </Button>
                    <Button 
                      variant="secondary" 
                      onClick={(e) => 
                        reTranslationOnHandler(e, false)}
                    >
                      취소
                    </Button>
                    </Modal.Footer>


                    

                  </Modal>
                </React.Fragment>
              )}

              <Button
                size="sm"
                className="m-1"
                variant="light"
                onClick={getSTSScore}
                disabled={props.waiting}
              >
                일괄 검수
              </Button>
              

              {/* {!props.limitedOptions && <Button
              onClick = {props.onSaveButton}
              size = 'sm'
              id = 'save'
              value = 'save'
              className="m-0"
              variant="light"
              >변경 적용하기
            </Button>} */}

              {!props.limitedOptions && (
                <DropdownButton
                  title="TMX 내보내기"
                  size="sm"
                  id="export-dropdown"
                  variant="secondary"
                  className="m-1"
                  disabled={props.waiting}
                >
                  <Dropdown.Item
                    onClick={(e) => props.onExportButton(e, true)}
                    eventKey="1"
                  >
                    스타일 미포함
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => props.onExportButton(e, false)}
                    eventKey="2"
                  >
                    스타일 포함
                  </Dropdown.Item>
                </DropdownButton>
              )}
              <Download
                project_no={props.project_no}
                file={currentFile}
                save={props.onSaveButton}
                disabled={props.waiting}
              />
              {/* <Button
              onClick = {props.onExportButton}
              
              variant="secondary"
              id = 'export'
              size = 'sm'
              value = 'export'
              className="m-1"
              >export TMX
            </Button> */}
            </Col>
          </Row>
        )}
      </Stack>
    </Navbar>
  );
};

export default TopOptions;

const initialize = (dispatch) => {
  dispatch({
      type: 'EDITOR DATA INITIALIZE',
  })
};

const setFileName = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'fileName',
      payload
  })
};

const setSourceSentence = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'sourceSentence',
      payload
  })
};

const setTargetSentence = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'targetSentence',
      payload
  })
};

const extendTargetSentence = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA LIST EXTEND',
      key: 'targetSentence',
      payload
  })
};


const setModifiedOrNotDict = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'modifiedOrNotDict',
      payload
  })
};

const setCheckedOrNotDict = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'checkedOrNotDict',
      payload
  })
};

const appendCheckedOrNotDict = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA DICT APPEND',
      key: 'checkedOrNotDict',
      payload
  })
};

const setEditorStates = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'editorStates',
      payload
  })
};

const setLoadedSrcLanguage = (dispatch, payload) => {
  dispatch({
      type: 'EDITOR DATA UPDATE',
      key: 'loadedSrcLanguage',
      payload
  })
};

const fileReadReducer = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA FILE READ',
    payload
  })
};

const setTmxMatch = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'tmxMatch',
    payload
  })
};

const extendTmxMatch = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA LIST EXTEND',
    key: 'tmxMatch',
    payload
  })
};

const setStsScoreDict = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'stsScoreDict',
    payload
  })
};

const assignItemStsScoreDict = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA DICT SET ITEM',
    key: 'stsScoreDict',
    index: payload[0],
    payload: payload[1]
  })
};

const setDirection = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'direction',
    payload
  })
};

const setDomain = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'domain',
    payload
  })
};

const setLinesNumber = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'linesNumber',
    payload
  })
};

const setProgressState = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA DICT APPEND',
    key: 'progressState',
    payload
  })
};

const setTmxAppendTarget = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA LIST APPEND',
    key: 'tmxMatch',
    payload
  })
};

const setFirstTargetSentence = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'firstTargetSentence',
    payload
  })
};

const extendFirstTargetSentence = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA LIST EXTEND',
    key: 'firstTargetSentence',
    payload
  })
};

const setGlossaryItems = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'glossaryMatch',
    payload
  })
};

const extendGlossaryItems = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA LIST EXTEND',
    key: 'glossaryMatch',
    payload
  })
};

const setSourceStyleDict = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'sourceStyleDict',
    payload
  })
};

const setTargetStyleDict = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'targetStyleDict',
    payload
  })
};

const setGlossaryMatchedIndex = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'glossaryMatchedIndex',
    payload
  })
};

const extendGlossaryMatchedIndex = (dispatch, payload) => {
  dispatch({
    type: 'GLOSSARY DATA LIST EXTEND',
    key: 'glossaryMatchedIndex',
    payload
  })
};

const setTargetSentenceAccessibility = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'targetSentenceAccessibility',
    payload
  })
};

const extendTargetSentenceAccessibility = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA LIST EXTEND',
    key: 'targetSentenceAccessibility',
    payload
  })
};

const setLoginInfo = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'loginInfo',
    payload
  })
};

const setTransCount = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'trans_count',
    payload
  })
};

const setPostEditCount = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'postEdit_count',
    payload
  })
};

const setFetchedLineNumber = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'fetchedLineNumber',
    payload
  })
};

const setCompletion = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'completion',
    payload
  })
};

const setCurrentActive = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'currentActive',
    payload
  })
};
const setGlossaryData = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'glossaryData',
    payload
  })
};

const setGlossaryActive = (dispatch, payload) => {
  dispatch({
    type: 'EDITOR DATA UPDATE',
    key: 'glossaryActive',
    payload
  })
};

export default {
  initialize,
  setFileName,
  setSourceSentence,
  setTargetSentence,
  extendTargetSentence,
  setModifiedOrNotDict,
  setCheckedOrNotDict,
  appendCheckedOrNotDict,
  setEditorStates,
  setLoadedSrcLanguage,
  fileReadReducer,
  setTmxMatch,
  extendTmxMatch,
  setStsScoreDict,
  assignItemStsScoreDict,
  setDirection,
  setDomain,
  setLinesNumber,
  setProgressState,
  setTmxAppendTarget,
  setFirstTargetSentence,
  extendFirstTargetSentence,
  setGlossaryItems,
  extendGlossaryItems,
  setSourceStyleDict,
  setTargetStyleDict,
  setGlossaryMatchedIndex,
  extendGlossaryMatchedIndex,
  setTargetSentenceAccessibility,
  extendTargetSentenceAccessibility,
  setLoginInfo,
  setFetchedLineNumber,
  setCompletion,
  setTransCount,
  setPostEditCount,
  setCurrentActive,
  setGlossaryData,
  setGlossaryActive
}
import React, { useState, useRef, useEffect, useContext } from "react";
import Axios from "axios";

import { Card, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";

import queryString from 'query-string';

import { BACKEND } from "../../Constants";


const SignUpRequest = (props) => {
    const histroy = useHistory();
    const [chk, setChk] = useState(0);
    var params = histroy.location.pathname.slice(18,)
    var query = queryString.parse(window.location.search)
    // if (Object.keys(query).length === 0) return;
    // console.log("window", window.location.search)

    useEffect(async () => {
        if (query) {
            let url = `${BACKEND.SERVER}/register`;
            let options = {
                method: "POST",
                url: url,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    "params": params,
                    "query": query['temp'],
                    "chk": 1
                },
            };

            await Axios(options
            ).then((response) => {
                setChk(1)
                alert('회원가입이 완료 되었습니다.')
                // history.push("/auth/sign-in");
            }).catch((err) => {
                setChk(2)
                // alert("잘못된 접근입니다.");
            });
        }
    }, [])

    let answer = "확인중입니다. 잠시만 기다려주세요"
    if (chk === 1) {
        answer = "회원가입이 완료되었습니다. 로그인해주세요"
    } else if (chk === 2) {
        answer = "이미 인증이 완료되었습니다."
    }
    return (
        <React.Fragment>
            <div className="text-center mt-4">
                <h1 className="h2">인증확인</h1>
                <p className="lead">당신은 이메일 하나로 놀라운 기능을 경험할 수 있습니다.</p>
            </div>

            <Card>
                <CardBody>
                    <div className="m-sm-4 text-center">
                        {answer}
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default SignUpRequest;
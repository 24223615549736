import React, { useContext, useState, useEffect, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckSquare, faCircle, faDotCircle, } from '@fortawesome/free-solid-svg-icons';
import { Circle } from 'react-feather';
import AuthContext from "../../store/auth-context";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import axios from "axios";
import { BACKEND } from "../../Constants";
import ToastNotification from "../forms/ToastNotification";

const Payment = () => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const isLoggedIn = authCtx.isLoggedIn;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const thename = isLoggedIn ? userInfo.userName : null;
    const useremail = isLoggedIn ? userInfo.userEmail : null;

    let standard_style_origin = {}
    let standard_style = {
        zIndex: "-1",
        // border: "2px solid red",
        border: "1px solid rgba(24,108,233,0.9)",
        // boxShadow: "0 0.5rem 1rem rgba(0 0 0 / 15 %)",
        // boxShadow: "0px 0px 0.5rem 0.1rem rgba(0, 0, 0, .15);"
        // boxShadow: "0px 0px 0.5rem 0.2rem rgba(244,158,150,0.2)",
        boxShadow: "0px 0px 0.2rem 0.1rem rgba(24,108,233,0.8)"
    }

    const methodRef = useRef();

    const [info, setInfo] = useState({ "period": "", "amount": "", "price": "", "payment_status": "N", "payment_datetime": "" });
    const [status, setStatus] = useState(false);
    const [toast, setToast] = useState(false);
    const [signAlert, setSignAlert] = useState(false);

    const [standard, setStandard] = useState(true);
    const [unlimited, setUnlimited] = useState(false);

    const [toggle, setToggle] = useState({ "period": "monthly" });
    const [amount, setAmount] = useState("standard");
    const subscribeAmount = { "standard": "스탠다드", "unlimited": "무제한" }
    const subscribePeriod = { "monthly": "월", "annual": "연" }
    const price = {
        "monthly_standard": 8950, "monthly_unlimited": 16900, "annual_standard": 110000, "annual_unlimited": 201900
    }

    function submitHandler(event) {
        event.preventDefault();
        if (!isLoggedIn) {
            // return <Redirect to='/auth/sign-in' />
            SignAlertHandler();
        } else {
            try {
                window.open("", "auth_popup", "width=560,height=640,scrollbars=yes");
                var form1 = document.form1;
                form1.target = "auth_popup";
                form1.submit()
                setStatus(true)
            } catch (e) {
                setStatus(false)
                console.log(e)
            }
        }
    }

    const toggleHandler = (event, tab) => {
        event.preventDefault();
        if (toggle.period !== tab) {
            setToggle({
                ...toggle,
                "period": tab,
            });
        }
    }

    function confirmEvent(event, selected) {
        event.preventDefault();

        if (info.payment_status === "N") {
            setAmount(selected);
            if (selected === "standard") {
                setUnlimited(false);
                setStandard(true);
            } else if (selected === "unlimited") {
                setStandard(false);
                setUnlimited(true);
            }
        } else {
            setToast(true)
        }

    }

    useEffect(() => {
        axios.post(BACKEND.SERVER + '/chk-payment', { "test": "test" }, {
            headers: {
                "Authorization": `JWT ${token}`
            }
        })
            .then((response) => {
                // console.log("info >>>", response.data)
                if (response.data) {
                    setInfo(response.data)
                }
            })
            .catch((error) => {
                console.log(error.data)
            })
        // if (localStorage.getItem('payment_status') === '1') {
        //     let url = '/payment'
        //     let data = { "period": toggle.period, "amount": amount, "price": price[`${toggle.period}_${amount}`] }
        //     axios.post(BACKEND.SERVER + url, data, {
        //         headers: {
        //             "Authorization": `JWT ${token}`
        //         }
        //     })
        //         .then((response) => {
        //             localStorage.removeItem('payment_status')
        //         })
        //         .catch((error) => {
        //             console.log(error.data)
        //         })
        // }
    }, [])

    const ToastAlert = () => {
        setToast(true)
    }
    const SignAlertHandler = () => {
        setSignAlert(true)
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">구독 시스템 테스트 중입니다.</h1>

            <Row>
                <Col md="10" xl="8" className="mx-auto">
                    <h1 className="text-center">Thinkcat Tools Premium</h1>
                    <p className="lead text-center mb-4">
                        Thinkcat 팀 프로젝트 광⁠고 없⁠는 생성, 무제한 생성

                    </p>


                    <Row className="justify-content-center mt-3 mb-2">
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={(e) => { toggleHandler(e, "monthly") }}
                                    className={
                                        toggle.period === "monthly" ? "active" : ""
                                    }
                                >
                                    월간 구독
                                </Button>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={(e) => { toggleHandler(e, "annual") }}
                                    className={
                                        toggle.period === "annual" ? "active" : ""
                                    }
                                >
                                    연간 구독
                                </Button>
                            </ButtonGroup>

                        </Col>
                    </Row>
                    <p>
                        1개월 무료 체험 • 이후 ₩8,950/월 • VAT 포함
                    </p>
                    <p>
                        체험판이 종료되기 7일 전에 알림 전송
                        무료 체험판은 신규 회원에게만 제공 • 정기 결제 • 언제든지 취소 가능
                    </p>

                    {/* {toast && <ToastNotification text={amount && `THINKCAT ${amount} 사용중.`} />} */}
                    {toast && isLoggedIn && <ToastNotification text={info.amount && `씽캣 ${subscribeAmount[info.amount]}/(${subscribePeriod[info.period]}) 구독중.`} />}
                    {toast && !isLoggedIn && <ToastNotification text={`로그인 후에 이용해주세요`} />}
                    {signAlert && <ToastNotification text={amount && `로그인 후에 이용해주세요.`} />}

                    <TabContent activeTab={toggle.period}>
                        <TabPane tabId="monthly">
                            <Row className="py-4">
                                <Col sm="4" className="mb-3 mb-md-0">
                                    <Card className="text-center h-100">
                                        <CardBody className="d-flex flex-column">
                                            <div>
                                                {info.payment_status === "N" && <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />}
                                            </div>
                                            <div className="mb-4">
                                                <h5>Free</h5>
                                                <span className="display-4">￦0</span>
                                            </div>
                                            <h6>Includes:</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-2">팀 프로젝트 생성 2회</li>
                                                <li className="mb-2">채팅 무제한</li>
                                                <li className="mb-2">프로젝트 팀원 참여 10회</li>

                                            </ul>
                                            {!isLoggedIn && <div className="mt-auto">
                                                <Button size="lg" color="primary" outline onClick={() => { history.push('/auth/sign-up') }}>
                                                    가입하기
                                                </Button>
                                            </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4" className="mb-3 mb-md-0">
                                    <Card className="text-center h-100" style={toast && info.amount === "standard" && info.period === "monthly" ? standard_style : standard_style_origin}>
                                        <CardBody className="d-flex flex-column" style={standard ? { cursor: "pointer", boxShadow: "0px 0px 0.2rem 0.1rem rgba(24,108,233,0.2)" } : { cursor: "pointer", boxShadow: "" }}
                                            onClick={(e) => { confirmEvent(e, "standard") }}>
                                            {info.payment_status === "Y" && info.amount === "standard" && info.period === "monthly" && <div>
                                                <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
                                            </div>}
                                            {info.payment_status === "N" &&
                                                <div>
                                                    {!standard ? <FontAwesomeIcon icon={faCircle} style={{ color: "RGB(235,235,235)" }} /> : <FontAwesomeIcon icon={faDotCircle} style={{ color: "RGB(59,130,236)" }} />}
                                                </div>}
                                            <div className="mb-4">
                                                <h5>Standard</h5>
                                                <span className="display-4">￦8,950</span>
                                                <span>/월</span>
                                            </div>
                                            <h6>Includes:</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-2">팀 프로젝트 생성 50회</li>
                                                <li className="mb-2">채팅 무제한</li>
                                                <li className="mb-2">프로젝트 팀원 참여 100회</li>
                                            </ul>
                                            {/* <div className="mt-auto">
                                                    {!info.price && <Button size="lg" color="primary"
                                                        onClick={(e) => {
                                                            // this.toggle("monthly");
                                                            // confirmEvent(e, "standard")
                                                            methodRef.current.submitHandler();
                                                        }}
                                                    >구매하기
                                                    </Button>}
                                                    {info && info.price &&
                                                        <Button size="lg" color="warning" sytle={{ cursor: "text" }} onClick={ToastAlert}>
                                                            {info.price === "8950" ? "✔" : "-"}
                                                        </Button>}
                                                </div> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4" className="mb-3 mb-md-0">
                                    <Card className="text-center h-100" style={toast && info.amount === "unlimited" && info.period === "monthly" ? standard_style : standard_style_origin}>
                                        <CardBody className="d-flex flex-column" style={unlimited ? { cursor: "pointer", boxShadow: "0px 0px 0.2rem 0.1rem rgba(24,108,233,0.2)" } : { cursor: "pointer", boxShadow: "" }}
                                            onClick={(e) => { confirmEvent(e, "unlimited") }}>
                                            {info.payment_status === "Y" && info.amount === "unlimited" && info.period === "monthly" && <div>
                                                <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
                                            </div>}
                                            {info.payment_status === "N" && <div>
                                                {!unlimited ? <FontAwesomeIcon icon={faCircle} style={{ color: "RGB(235,235,235)" }} /> : <FontAwesomeIcon icon={faDotCircle} style={{ color: "RGB(59,130,236)" }} />}
                                            </div>}
                                            <div className="mb-4">
                                                <h5>Plus</h5>
                                                <span className="display-4">￦16,900</span>
                                                <span>/월</span>
                                            </div>
                                            <h6>Includes:</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-2">팀 프로젝트 생성 <b>무제한</b></li>
                                                <li className="mb-2">채팅 <b>무제한</b></li>
                                                <li className="mb-2">프로젝트 팀원 참여 <b>무제한</b></li>
                                            </ul>
                                            {/* <div className="mt-auto">
                                                    {!info.price && <Button size="lg" color="primary" outline
                                                        onClick={(e) => { confirmEvent(e, "unlimited") }}
                                                    >
                                                        구매하기
                                                    </Button>}
                                                    {info && info.price &&
                                                        <Button size="lg" color="warning" sytle={{ cursor: "text" }} onClick={ToastAlert} outline>
                                                            {info.price === "16900" ? "✔" : "-"}
                                                        </Button>}
                                                </div> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    <TabContent activeTab={toggle.period}>
                        <TabPane tabId="annual">
                            <Row className="py-4">
                                <Col sm="4" className="mb-3 mb-md-0">
                                    <Card className="text-center h-100">
                                        <CardBody className="d-flex flex-column">
                                            <div>
                                                {info.payment_status === "N" && <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />}
                                            </div>
                                            <div className="mb-4">
                                                <h5>Free</h5>
                                                <span className="display-4">￦0</span>
                                            </div>
                                            <h6>Includes:</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-2">팀 프로젝트 생성 2회</li>
                                                <li className="mb-2">채팅 무제한</li>
                                                <li className="mb-2">프로젝트 팀원 참여 10회</li>
                                            </ul>
                                            {!isLoggedIn && <div className="mt-auto">
                                                <Button size="lg" color="primary" outline onClick={() => { history.push('/auth/sign-up') }}>
                                                    가입하기
                                                </Button>
                                            </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4" className="mb-3 mb-md-0">
                                    <Card className="text-center h-100" style={toast && info.amount === "standard" && info.period === "annual" ? standard_style : standard_style_origin}>
                                        <CardBody className="d-flex flex-column" style={standard ? { cursor: "pointer", boxShadow: "0px 0px 0.2rem 0.1rem rgba(24,108,233,0.2)" } : { cursor: "pointer", boxShadow: "" }}
                                            onClick={(e) => { confirmEvent(e, "standard") }}>
                                            {info.payment_status === "Y" && info.amount === "standard" && info.period === "annual" &&
                                                <div>
                                                    <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
                                                </div>}
                                            {info.payment_status === "N" &&
                                                <div>
                                                    {!standard ? <FontAwesomeIcon icon={faCircle} style={{ color: "RGB(235,235,235)" }} /> : <FontAwesomeIcon icon={faDotCircle} style={{ color: "RGB(59,130,236)" }} />}
                                                </div>}
                                            <div className="mb-4">
                                                <h5>Standard</h5>
                                                <span className="display-4">￦110,000</span>
                                                <span>/연</span>
                                            </div>
                                            <h6>Includes:</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-2">팀 프로젝트 생성 50회</li>
                                                <li className="mb-2">채팅 무제한</li>
                                                <li className="mb-2">프로젝트 팀원 참여 100회</li>
                                            </ul>
                                            {/* <div className="mt-auto">
                                                    {!info.price && <Button size="lg" color="primary"
                                                        onClick={(e) => { confirmEvent(e, "standard") }}
                                                    >
                                                        구매하기
                                                    </Button>}
                                                    {info && info.price &&
                                                        <Button size="lg" color="warning" sytle={{ cursor: "text" }} onClick={ToastAlert} outline>
                                                            {info.price === "110000" ? "✔" : "-"}
                                                        </Button>}
                                                </div> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="4" className="mb-3 mb-md-0">
                                    <Card className="text-center h-100" style={toast && info.amount === "unlimited" && info.period === "annual" ? standard_style : standard_style_origin}>
                                        <CardBody className="d-flex flex-column" style={unlimited ? { cursor: "pointer", boxShadow: "0px 0px 0.2rem 0.1rem rgba(24,108,233,0.2)" } : { cursor: "pointer", boxShadow: "" }}
                                            onClick={(e) => { confirmEvent(e, "unlimited") }}>
                                            {info.payment_status === "Y" && info.amount === "unlimited" && info.period === "annual" && <div>
                                                <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
                                            </div>}
                                            {info.payment_status === "N" &&
                                                <div>
                                                    {!unlimited ? <FontAwesomeIcon icon={faCircle} style={{ color: "RGB(235,235,235)" }} /> : <FontAwesomeIcon icon={faDotCircle} style={{ color: "RGB(59,130,236)" }} />}
                                                </div>}
                                            <div className="mb-4">
                                                <h5>Plus</h5>
                                                <span className="display-4">￦201,900</span>
                                                <span>/연</span>
                                            </div>
                                            <h6>Includes:</h6>
                                            <ul className="list-unstyled">
                                                <li className="mb-2">팀 프로젝트 생성 <b>무제한</b></li>
                                                <li className="mb-2">채팅 <b>무제한</b></li>
                                                <li className="mb-2">프로젝트 팀원 참여 <b>무제한</b></li>
                                            </ul>
                                            {/* <div className="mt-auto">
                                                    {!info.price && <Button size="lg" color="primary" outline
                                                        onClick={(e) => { confirmEvent(e, "unlimited") }}
                                                    >
                                                        구매하기
                                                    </Button>}
                                                    {info && info.price &&
                                                        <Button size="lg" color="warning" sytle={{ cursor: "text" }} onClick={ToastAlert} outline>
                                                            {info.price === "201900" ? "✔" : "-"}
                                                        </Button>}
                                                </div> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>

                    <div className="mt-auto text-center">
                        {info.payment_status == 'N' && <Button size="lg" color="primary"
                            onClick={(e) => { submitHandler(e) }}
                        >
                            구매하기
                        </Button>}
                        {info.payment_status == 'Y' &&
                            <small>{info.target_date} 종료예정</small>
                        }
                        {/* {info.payment_status == 'Y' &&
                            <Button size="lg" color="warning" sytle={{ cursor: "text" }} onClick={ToastAlert} outline>
                                ✔
                            </Button>} */}
                    </div>

                    {/* <hr />

                    <div className="text-center my-4">
                        <h2>Frequently asked questions</h2>
                    </div>
                    <Row>
                        <Col sm="6" md="5" className="ml-auto">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Do I need a credit card to sign up?</CardTitle>
                                    <p className="mb-0">
                                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                                        neque sed ipsum. Nam quam nunc, blandit vel, luctus
                                        pulvinar, hendrerit id, lorem.
                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6" md="5" className="mr-auto">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Do you offer a free trial?</CardTitle>
                                    <p className="mb-0">
                                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                                        neque sed ipsum. Nam quam nunc, blandit vel, luctus
                                        pulvinar, hendrerit id, lorem.
                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6" md="5" className="ml-auto">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">What if I decide to cancel my plan?</CardTitle>
                                    <p className="mb-0">
                                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                                        neque sed ipsum. Nam quam nunc, blandit vel, luctus
                                        pulvinar, hendrerit id, lorem.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6" md="5" className="mr-auto">
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">Can I cancel at anytime?</CardTitle>
                                    <p className="mb-0">
                                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                                        neque sed ipsum. Nam quam nunc, blandit vel, luctus
                                        pulvinar, hendrerit id, lorem.
                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
            <div>
                <form name="form" action="/danal/Order.php" method="post">
                    <table>
                        <tr>
                            <td>
                                <input type="hidden" name="CP_CD" maxLength="12" size="16" value="V57650000000" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="hidden" name="REQ_SITE_NM" maxLength="20" size="24" value="thinkcat.tools" />
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
            <form name="form1" action="/danal/Order.php" method="post">
                <input type="hidden" name="orderid" value="" />
                <input type="hidden" name="itemname" value={`${toggle.period}_${amount}`} />
                <input type="hidden" name="username" value="" />
                <input type="hidden" name="useremail" value={useremail} />
                <input type="hidden" name="userid" value="" />
                <input type="hidden" name="useragent" value="" />
                <input type="hidden" name="period" value={toggle.period} />
                <input type="hidden" name="amount" value={String(price[`${toggle.period}_${amount}`])} />
            </form>
        </Container >
    );
}

export default Payment;

const LANGUAGE_CODE = {
  '한국어': 'ko',
  '영어'  : 'en',
  '중국어': 'cn',
  '일본어': 'jp'
}

const direction2code = (directionSrc, directionTgt) => {
  if (directionSrc !== directionTgt){
    const sourceCode = LANGUAGE_CODE[directionSrc];
    const targetCode = LANGUAGE_CODE[directionTgt];
    return `${sourceCode}2${targetCode}`
  }
  else {
    return null
  }
};

export { direction2code }
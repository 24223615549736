import React, { useReducer } from 'react';

import DocumentContext from './document-context';

const defaultDocumentState = {
    items: [],
    searchKeyword: '',
    autoTranslation: true,
    translationDomain: 'normal',
};

const DocumentReducer = (state, action) => {
    if (action.type === 'add') {
        const existsItem = state.items.findIndex((item) => 
            item.filename === action.item.filename);
        const updatedItems = existsItem === -1
        ? state.items.concat(action.item)
        : state.items;
        //console.log(action.item);
        return {
            ...state,
            items: updatedItems
        }   
    }
    else if (action.type === 'changeState') {
        const updateIndex = state.items.findIndex((item) =>
            item.filename === action.item.filename);
        const updateItem = {
            ...state.items[updateIndex],
            randomCreateName: action.item.randomCreateName
            ? action.item.randomCreateName
            : state.items[updateIndex].randomCreateName,
            status: action.item.status
            ? action.item.status
            : state.items[updateIndex].status,
            progress: action.item.progress
            ? action.item.progress
            : 0};
        const updateItems = state.items;
        updateItems[updateIndex] = updateItem;

        return {
            ...state,
            items: updateItems
        }
    
    }
    else if (action.type === 'remove') {
        const removedItems = state.items.filter(
            item => item.filename != action.filename
            
        );
        return {
            ...state,
            items: removedItems
        }
    }
    else if (action.type === 'setItems') {
        return {...state, items: action.items}
    }
    else if (action.type === 'search') {
        return {...state, searchKeyword: action.keyword}
    }
    else if (action.type === 'changeValueForKey') {
        return {
            ...state,
            [action.key]: action.value
        }
    }
};

const DocumentProvider = (props) =>{
    const [documentState, dispatchDocumentState] = useReducer(
        DocumentReducer, defaultDocumentState);
    
    const setDocumentItems = (items) => {
        dispatchDocumentState({type: 'setItems', items: items});
    };
    const addDocument = (item) => {
        dispatchDocumentState({type: 'add', item: item});
    };
    const removeDocument = (fileName) => {
        dispatchDocumentState({type: 'remove', filename: fileName});
    };
    const searchDocument = (keyword) => {
        dispatchDocumentState({type: 'search', keyword: keyword})
    };
    const changeState = (item) => {
        dispatchDocumentState({type: 'changeState', item: item})
    };
    const changeValueForKey = (key, value) => {
        dispatchDocumentState(
            {type: 'changeValueForKey',
             key, value})
    }
   
    const documentContext = {
        items: documentState.items,
        searchKeyword: documentState.searchKeyword,
        autoTranslation: documentState.autoTranslation,
        translationDomain: documentState.translationDomain,

        setItems: setDocumentItems,
        addItem: addDocument,
        removeItem: removeDocument,
        search: searchDocument,
        changeState: changeState,
        changeValueForKey
    };

    return (
        <DocumentContext.Provider value = {documentContext}>
            {props.children}
        </DocumentContext.Provider>
    )
}

export default DocumentProvider;


import React, { useState, useContext, useEffect } from "react";

import axios from "axios";
import FileUploadService from '../../../../services/FileUploadService'

// tmxContext
import TmxContext from '../contexts/tmx-context';

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// reactstrap
import { Table, Progress, Container, Row, Col, Button, Input } from "reactstrap";

// parts
import TopOptions from "./TopOptions"
import TmxFileList from "./TmxFileList";
import UploadFile from "./UploadFile";

// loading
import LoadingModal from "../../progress/LoadingModal";
import loadingGIF from '../../progress/backTranslation.gif';

import { BACKEND } from "../../../../Constants";

const TmxTab = (props) => {
    // index: 0, //번호
    // file: // file object
    // filename: '', //파일이름
    // direction: '', //번역 방향
    // uploadDate: '', // 업로드일
    const tmxCtx = useContext(TmxContext);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const email = userInfo? userInfo.userEmail :null;

    const [loaded, setLoaded] = useState(true);

    const getTmxFiles = async () => {
        setLoaded(false);
        const token = localStorage.getItem('token');
        const results = await axios.get(
            `${BACKEND.SERVER}/document/${props.project_no}/tmx`, {
                headers: {
                        "Authorization": `JWT ${token}`
                    },
            }
        ).then((response) => {
            const num = parseInt(response.data.length);
            if (num > 0) {
                props.setTmxFiles(response.data);
                tmxCtx.setItems(response.data);
            }
            setLoaded(true);
        });
    }

    const tmxItemAddHandler = (file, tempFileName) => {
        let item = {
            "project_no": props.project_no, 
            "filename" : file.name,
            "filesize" : file.size,
            "uploaded" : new Date().toLocaleString() + '',
            "randomCreateName": tempFileName,
            "postEdit_percent": 0,
        }
        //console.log(item);
        tmxCtx.addItem({...item});
    };

    const tmxItemRemoveHanlder = (event) => {
        event.preventDefault();
        const index = event.currentTarget.id;
        const removeIndex = tmxCtx.items.findIndex((item) => 
            item.index == index);
        
        if (props.project_no !== undefined) {
            if (props.project_no !== 'unknown') {
                const token = localStorage.getItem('token');
                FileUploadService.deleteDocument(
                    props.project_no, 
                    email,
                    tmxCtx.items[removeIndex].filename, 
                    'tmx_files',
                    token
                ).then((response) => {
                    tmxCtx.removeItem(index);
                    
                })
            }
        }
    };

    useEffect(() => {
        props.setTmxFiles(tmxCtx.items);
    }, [tmxCtx.items])

    useEffect(() => {
        //console.log(props.project_no);
	    if (props.project_no !== undefined && props.project_no !== 'unknown'){
            props.isActive && getTmxFiles();
        }  
    }, [props.isActive]);

    //console.log(tmxCtx);

    return (
        <Container>
            <LoadingModal 
                children = '불러오는중...'
                gif = {loadingGIF}
                show = {!loaded}
            />
            {props.availableEdit && <UploadFile 
                project_no = {props.project_no}
                addItem = {tmxItemAddHandler}    
            />}
            <TopOptions 
                project_no = {props.project_no} 
                addItem = {tmxItemAddHandler}
            />
            <Row>
                <TmxFileList 
                    removeItem = {tmxItemRemoveHanlder}
                    project_no = {props.project_no} 
                    availableEdit = {props.availableEdit}
                    acceptance = {props.acceptance}
                    sourceLang = {props.sourceLang}
                    targetLang = {props.targetLang} 
                    projectType = {props.projectType}
                />
            </Row>
        </Container>
    )
}

export default TmxTab;
import React, { useContext } from 'react';
// import { useSelector } from 'react-redux';

import EditorDataContext from '../../contexts/EditorDataContext';
import { Table } from 'react-bootstrap';



const GlossaryList = (props) => {
  const edCtx = useContext(EditorDataContext);
  // const glossaryMatch = useSelector((state) => state.editorData.glossaryMatch)[props.index];
  const glossaryMatch = props.index !== null
    ?edCtx.glossaryMatch[props.index]
    :null;
  // console.log(edCtx.glossaryMatch)
  // console.log(glossaryMatch, props.index)
  const trClickHandler = (index) => {
    props.setGlossaryActive([index]);
  };

  return (
    <Table striped bordered hover className='m-0'>
      <thead>
        <tr>
          <th className='p-1'>#</th>
          <th className='p-1'>용어집 원문</th>
          <th className='p-1'>용어집 번역문</th>
        </tr>
      </thead>
      <tbody>
      {glossaryMatch && glossaryMatch.map((item, index) => (
        <tr 
          key = {index} 
          onClick = {() => trClickHandler(index)}
          style = {{
            backgroundColor: (props.glossaryActive.includes(index) && 
                              props.index === edCtx.currentActive)
            ? "rgba(85, 85, 85, 0.1)"
            : null
          }}
        >
          <td className='p-1'>{index}</td>
          <td className='p-1'>{item.glossary_src.map((value, i) => (
            <div key = {i}>{value}</div>
          ))}
          </td>
          <td className='p-1'>{item.glossary_tgt.map((value, i) => (
            <div key = {i}>{value}</div>
          ))}
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  )
};

export default GlossaryList;
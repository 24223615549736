import React, { useState, useRef, useContext } from "react";
import axios from "axios";

// uploadservice
import UploadService from "../../../../services/FileUploadService";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus, faTrashAlt   } from "@fortawesome/free-solid-svg-icons";

// reactstrap
import { Table, Progress, Container, Row, Col, Button, Input } from "reactstrap";

// createGlossary
import CreateGlossary from './CreateGlossary';

// context
import GlossaryContext from "../contexts/Glossary-context";

const TopOptions = (props) => {
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [createGlossary, onCreateGlossary] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const gsCtx = useContext(GlossaryContext);

  const fileRef = useRef();

  const uploadProgress = (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total))
  };

  const uploadButtonHandler = (event) => {
      event.preventDefault();
      fileRef.current.click();
  };

  const createButtonHandler = (event) => {
    onCreateGlossary(true);
  };

  const searching = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    keyword === '' && gsCtx.search('');
  };
  const search = () => {
    gsCtx.search(searchKeyword);
  }

  const fileUploadOnChange = (event) => {
      const [file] = event.target.files;
      const email = JSON.parse(localStorage.getItem('userInfo')).userEmail;
      UploadService.upload(
          file, email, props.project_no, false, uploadProgress
          ).then((response) => {
              if (response.data.filename === 'duplicateFile') {
                  alert(response.data.message);
              }
              else {
                  //setTmxFile(event.target.files);
                  //setMessage(response.data.message);
                  props.addItem(file, response.data.filename);
              }
          }
          ).catch((error) => {
              alert('파일을 업로드할 수 없었습니다!');
          });
      
  };

  return (
    <Row className = 'mb-1'>
      <CreateGlossary 
        createGlossary = {createGlossary}
        onCreateGlossary = {onCreateGlossary}
        project_no = {props.project_no}
        getDateTime = {props.getDateTime}
      />
      <Col className = 'pl-1 text-left d-flex align-items-end'>
        <Button 
          className = ''
          size = 'sm'  
          type = 'file'
          color = 'secondary' 
          onClick = {createButtonHandler}
        >
          <FontAwesomeIcon
            className="pr-1" 
            icon={faPlus} size ='sm'/>
          용어집생성
        </Button>
      </Col>
      <Col xs = 'auto' className = 'pr-0 align-self-center'>
        <Input 
          placeholder = '용어집 검색'
          onChange = {searching}
          onKeyDown = {(event) => {
            event.key === 'Enter' && search()
          }}  
        />
      </Col>
      <Col xs = 'auto' className = 'pl-0 pr-0 text-right'>
        <Button 
          outline  
          color = 'secondary' 
          className="ml-10 mr-10"
          style = {{borderColor:'rgba(0,0,0,0)'}} 
          onClick = {search}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
        
      </Col>
    </Row>
  )
};

export default TopOptions;
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Wrapper from "../helpers/Wrapper";

// files
import Read from "../files/Read";
import Save from "../files/Save";
import Export2Tmx from "../files/Export2Tmx";

// service
import CheckTMX from "../services/CheckTmx";
import service from "../../../services/FileUploadService"

// parts
import TextEditors from "./TextEditors";
import TopOptions from "./TopOptions";
import BottomOptions from "./bottomOptions/BottomOptions.navbar";

// translate
import Translate from "../translate/Translate";
import BackTranslate from "../translate/BackTranslate";

import PopupModalList from "./PopupModal.List";

// mainFuctions
import MainFunctions from "./MainFunctions";

// context
import EditorDataContext from "../contexts/EditorDataContext";
import reducerActions from "../contexts/reducerActions";

// error
import ErrorPage from "../../../components/ErrorPage";

// react-bootstrap
import { Card } from "react-bootstrap";

// rightClickMenu
import RightContext from "./lightButtonMenu/Index";

// main part
// it exist by twin : src\pages\WebEditor\parts\OnlyInspection.js
const MainPart = (props) => {
    // -- react Hooks & variables start --
    // const [observerTarget, setObserverTarget] = useState(null);
    const observerTarget = useRef(null);
    const history = useHistory();
    const edCtx = useContext(EditorDataContext);

    const fileName = edCtx.fileName;
    const sourceSentence = edCtx.sourceSentence;
    const targetSentence = edCtx.targetSentence;
    const modifiedOrNotDict = edCtx.modifiedOrNotDict;
    const checkedOrNotDict = edCtx.checkedOrNotDict;
    const editorStates = edCtx.editorStates;
    const loadedSrcLanguage = edCtx.loadedSrcLanguage;
    // const stsScoreDict      = edCtx.stsScoreDict;
    const direction = edCtx.direction;
    const domain = edCtx.domain;
    const progressState = edCtx.progressState;
    const fetchedLineNumber = edCtx.fetchedLineNumber;
    const accumulatedNumber = edCtx.accumulatedNumber;
    const dispatch = edCtx.dispatch;

    // progress state
    // const [progressState, setProgressState] = useState('ready');
    // const [progressState, dispatchProgressState] = useReducer(
    //     MainFunctions.progressReducer, {state: 'ready'}
    // );

    const [progressBar, setProgressBar] = useState(0);
    const [waiting, setWaiting] = useState(false);
    const [completion, setCompletion] = useState(false);
    const [onRightClickMenu, setOnRightClickMenu] = useState(false);
    const [xYPosition, setXyPosition] = useState({ x: 0, y: 0 });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const ownerId = userInfo ? userInfo.userUid : "nonMember";
    const userEmail = userInfo ? userInfo.userEmail : null;
    // -- react Hooks & variables end --

    // -- Funtions start --
    // editor state (textarea or web editor)
    const onEditorStateHandler = (event, force = null) => {
        let id = "";
        if (event.target.id) {
            id = event.target.id;
        } else if (event.currentTarget.id) {
            id = event.currentTarget.id;
        } else {
            id = event.currentTarget.htmlFor;
        }
        //console.log(id);
        const stateDict = MainFunctions.setEditorState(
            editorStates,
            force ? { key: id, force: force } : { key: id, formState: "change form" }
        );

        reducerActions.setEditorStates(dispatch, stateDict);
    };

    // check to modified or not
    const onModifedOrNotHandler = (props) => {
        const modifiedOrNot = MainFunctions.setModifedOrNot(
            modifiedOrNotDict,
            props
        );
        reducerActions.setModifiedOrNotDict(dispatch, modifiedOrNot);
    };

    // check to user checking or not
    const onCheckedOrNotHandler = (event) => {
        const id = event.currentTarget.id;
        const checkedOrNot = MainFunctions.setCheckedOrNot(checkedOrNotDict, {
            key: id,
        });
        service.put(
            fileName,
            props.project_no ? props.project_no : "tempFiles",
            userEmail,
            ownerId,
            checkedOrNot[id] === false ? 0 : 1,
            null,
            id,
            'document.put_one_check').then((response) => {
                reducerActions.setTransCount(dispatch, response.data.trans_count)
                reducerActions.setCheckedOrNotDict(dispatch, checkedOrNot);
            }).catch((err) => {
                console.log(err)
            })
    };

    // on tr clicked
    const onLineSelect = (event, index) => {
        reducerActions.setCurrentActive(dispatch, index);
        if (edCtx.currentActive !== index) {
            reducerActions.setGlossaryActive(dispatch, []);
            reducerActions.setGlossaryData(dispatch, []);
        }
    };

    // temp glossary data
    const setGlossaryData = (payload) => {
        reducerActions.setGlossaryData(dispatch, payload);
    };

    const setGlossaryActive = (payload) => {
        reducerActions.setGlossaryActive(dispatch, payload);
    };

    // file read
    const readHandler = (fileName) => {
        reducerActions.setProgressState(dispatch, {
            'processing': true, 'file reading': true
        });
        reducerActions.setFileName(dispatch, fileName);
        const process = `document.fetched-${fetchedLineNumber}`;
        Read(fileName, ownerId, props.project_no, process)
            .then((response) => {
                // console.log(response)
                const loginInfo = {
                    'userId': ownerId,
                    'userEmail': userEmail,
                    'project_no': props.project_no
                };
                reducerActions.setLoginInfo(dispatch, loginInfo);

                const data = MainFunctions.intializeState(
                    response.data[0], response.data[0].length, fetchedLineNumber);

                const [stateDict, modifiedOrNot, checkedOrNot, number] = data;
                // console.log(fetchedLineNumber, number)
                reducerActions.setLinesNumber(dispatch, response.data[2]);

                const item = {
                    modifiedOrNotDict: modifiedOrNot,
                    checkedOrNotDict: checkedOrNot,
                    editorStates: stateDict,
                    sourceSentence: response.data[0],
                    maxLinesNumber: response.data[2],
                    accumulatedNumber: number,
                };
                reducerActions.fileReadReducer(dispatch, item);
                response.data[3] && reducerActions.setDomain(dispatch, response.data[3]);

                if (ownerId === 'nonMember') {
                    if (fetchedLineNumber > edCtx.nonMemberLimitLine) {
                        setCompletion(true);
                    }
                } else {
                    setCompletion(response.data[2] <= accumulatedNumber + number);
                }
                // reducerActions.setSourceStyleDict(dispatch, response.data[1]);
                checkTMX(response.data[0], props.project_no, fetchedLineNumber);
                translation(fileName, 'false', props.direction, 'fetched', response.data[3]);
            })
            .then(() => {
                reducerActions.setLoadedSrcLanguage(dispatch, true);
                reducerActions.setProgressState(dispatch, {
                    'file reading': false
                });
                setWaiting(false);
            })
            .catch((err) => {
                reducerActions.setProgressState(dispatch, {
                    'file reading': false
                });
                setWaiting(false);
                console.log(err)
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            history.push({
                                pathname: '/error/401', state: {
                                    status: 401, title: '로그인이 필요 합니다.'
                                }
                            });
                            break;
                        case 404:
                            history.push({
                                pathname: '/error/404', state: {
                                    status: 404, title: '권한이 없거나 접근 할 수 없습니다.'
                                }
                            });
                            break;
                        case 500:
                            history.push({
                                pathname: '/error/500', state: {
                                    status: 500, title: '내부 서버 에러'
                                }
                            });
                            break;
                        default:
                            history.push({
                                pathname: '/error/404', state: {
                                    status: 404, title: '권한이 없거나 접근 할 수 없습니다.'
                                }
                            });
                    }
                }
                else {
                    history.push('/unauthorized');
                }
                reducerActions.setLoadedSrcLanguage(dispatch, false);
            });
    };

    // translation
    // twin function: src\pages\projects\DocumentTab\parts\UploadFile.js
    const translation = (fileName, force, direction,
        fetchProcess = 'fetched', otherDomain = null) => {
        // translation workflow:
        // 1. get job(part) count
        // 2. translation each part
        // 3. assemble parts

        const project_no = props.project_no ? props.project_no : "tempFiles";
        const translateData = new FormData();
        translateData.append("filename", fileName);
        translateData.append("domain", otherDomain ? otherDomain : domain);
        translateData.append("direction", direction);
        translateData.append("project_no", project_no);
        translateData.append("ownerEmail", userEmail);
        translateData.append("ownerId", ownerId);
        translateData.append("force", force);
        translateData.append("process", "getJobCount");
        translateData.append("nextProcess", `${fetchProcess}-${fetchedLineNumber}`);
        // translateData.append("nextProcess", `fetched-0`);

        setProgressBar(1);

        const translation_part = async (sequence, translateData, jobCount) => {
            translateData.append('returnData', true);
            translateData.append('lastJob', false);

            await initTargetData().then(async () => {
                for (const [index, indices] of sequence.entries()) {
                    translateData.set("indices", indices);
                    // console.log(indices)
                    if (index === sequence.length - 1) {
                        translateData.set('lastJob', true);
                    }
                    await Translate(translateData).then((response) => {
                        storeData(response.data);
                        setProgressBar(((response.process) / jobCount) * 100);
                    }).catch((err) => {
                        // console.log(err);
                    });
                }
            })
        };

        const storeData = (data) => {
            let checkedOrNot = {};
            data[1].map((check, index) => {
                const checkBool = check === 1 ? true : false;
                checkedOrNot = {
                    ...checkedOrNot,
                    [(fetchedLineNumber + index).toString()]: checkBool
                };
            });
            reducerActions.appendCheckedOrNotDict(dispatch, checkedOrNot);
            reducerActions.extendTargetSentence(dispatch, data[0]);
            reducerActions.extendFirstTargetSentence(dispatch, data[0]);
            reducerActions.extendGlossaryItems(dispatch, data[2]);
            reducerActions.extendGlossaryMatchedIndex(dispatch, data[3]);
            reducerActions.extendTargetSentenceAccessibility(dispatch, data[4]);
            data[5].map((value) => {
                reducerActions.assignItemStsScoreDict(dispatch, value);
            });
            reducerActions.setTransCount(dispatch, data[6]);
        };

        const initTargetData = () => {
            edCtx.editorDataTargetInit();
            return new Promise((resolve) => { resolve(true) })
        };

        reducerActions.setProgressState(dispatch, {
            'processing': true,
            'translation': force === 'true' ? true : false
        });

        Translate(translateData)
            .then((response) => {
                // console.log(response)
                translateData.set("process", "translation_part");
                translateData.append("indices", "");
                translateData.append("dataPath", response.data[1]);

                const jobCount = response.data[0].length;
                if (response.status === 'translated') {
                    // console.log(response)
                    storeData(response.data);
                    setProgressBar(100);
                    reducerActions.setProgressState(dispatch, {
                        'translation': false
                    });

                }

                else {
                    reducerActions.setProgressState(dispatch, {
                        'processing': true,
                        'translation': true
                    });
                    translation_part(response.data[0], translateData, jobCount
                    ).then(() => {
                        reducerActions.setProgressState(dispatch, {
                            'translation': false
                        });
                        setProgressBar(100);
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    // get sts score
    const getSTSScore = (src, tgt, indice = null) => {
        reducerActions.setProgressState(dispatch, {
            'processing': true,
            'sts checking': !indice ? true : false,
            'etc processing': !indice ? null : 'post-editing one'
        });
        setProgressBar(1);

        const backTranslateData = new FormData();

        if (src === null || tgt === null) {
            src = sourceSentence;
            tgt = targetSentence;
        } else {
            src = [src];
            tgt = [tgt];
        }
        backTranslateData.append("source", !indice ? null : JSON.stringify(src.slice()));
        // backTranslateData.append("target", JSON.stringify(tgt.slice()));
        backTranslateData.append("target", !indice ? null : JSON.stringify(tgt.slice()));
        backTranslateData.append("filename", fileName);
        backTranslateData.append("domain", domain);
        backTranslateData.append("direction", direction);
        backTranslateData.append("project_no", props.project_no ? props.project_no : "tempFiles");
        backTranslateData.append("ownerEmail", userEmail);
        backTranslateData.append("ownerId", ownerId);
        backTranslateData.append("process", !indice ? 'get job count' : 'post-editing one');
        backTranslateData.append("indices", indice);

        BackTranslate(backTranslateData).then(async (response) => {
            if (response.status === 'got job count') {
                backTranslateData.set("process", 'post-editing part');
                const jobCount = response.data.length;
                for (const [iter_seq, iter] of response.data.entries()) {
                    // Promise.all(response.data.map(async (iter, iter_seq) => {
                    // backTranslateData.set("source", JSON.stringify(src.slice(iter[1], iter[2])));
                    // backTranslateData.set("target", JSON.stringify(tgt.slice(iter[1], iter[2])));
                    backTranslateData.set("indices", iter);
                    await BackTranslate(backTranslateData).then((response) => {
                        if (response.status === 'got post-editing part') {
                            response.data.map((score, index) => {
                                reducerActions.assignItemStsScoreDict(dispatch, [(iter[1] + index).toString(), score]);
                            })
                            setProgressBar(((iter[0] + 1) / jobCount) * 100);
                        }
                    })
                }
                reducerActions.setProgressState(dispatch, {
                    'sts checking': false
                });
            }
            else if (response.status === 'got post-editing one') {
                reducerActions.assignItemStsScoreDict(dispatch, [indice.toString(), response.data[0]]);
                reducerActions.setProgressState(dispatch, {
                    'etc processing': null
                });
                setProgressBar(100);

            }
            else {
                alert('일시적으로 기능을 사용 할 수 없습니다. 문제에 대해 문의 주시길 바랍니다.')
            }
        });
    };

    // tmx check
    const checkTMX = (data, project_no, fetchedLineNumber) => {
        if (project_no) {
            if (project_no !== 'individual' && project_no !== 'tempFiles') {
                reducerActions.setProgressState(dispatch, {
                    'processing': true,
                    'tm checking': true
                });
                CheckTMX(data, project_no, fetchedLineNumber).then((response) => {
                    // console.log(response)
                    reducerActions.extendTmxMatch(dispatch, response);
                    reducerActions.setProgressState(dispatch, {
                        'tm checking': false
                    });
                });
            }
        }
    };

    // text modify
    const onChangeLines = (props) => {
        const { key, line } = props;
        const lines = targetSentence.slice();

        if (lines[key] !== line) {
            lines[key] = line;
            reducerActions.setTargetSentence(dispatch, lines);
            onModifedOrNotHandler({ key });
        }
    };

    // text modify for one line
    const onChangeLine = (key, line, defaultSize = 20, addParagraph = true) => {
        const lines = targetSentence.slice();
        const setLine = addParagraph
            ? `<p style=\"text-align: left;\"><span style="font-size: ${defaultSize}px;">${line}</span></p>`
            : line;
        lines[key] = setLine;

        reducerActions.setTargetSentence(dispatch, lines);
        onModifedOrNotHandler({ key });
    };

    // save to file
    const onSaveButton = async (event, fileType = null, callback = null) => {
        setProgressBar(1);
        reducerActions.setProgressState(dispatch, {
            'processing': true,
            'etc processing': 'save file'
        });
        event.preventDefault();
        const humanCheckedList = [];

        const project_no = props.project_no ? props.project_no : "tempFiles";
        if (project_no === 'individual' || project_no === 'tempFiles') {
            for (const dict_key in modifiedOrNotDict) {
                humanCheckedList.push(modifiedOrNotDict[dict_key] === false ? 0 : 1);
            }
        }
        else {
            for (const dict_key in checkedOrNotDict) {
                humanCheckedList.push(checkedOrNotDict[dict_key] === false ? 0 : 1);
            }
        }

        const save_props = {
            file: fileName,
            textarray: targetSentence,
            humanChecked: humanCheckedList,
            domain: domain,
            project_no: project_no,
            user_email: userEmail,
            process: 'document.getJobCount'
        };

        // console.log(save_props);
        await Save(save_props).then(async (response) => {
            if (response.data.status === 'got job count') {
                await save_all(
                    response.data.job_sequence,
                    save_props,
                    response.data.job_sequence.length,
                ).then(async (response) => {
                    if (fileType === 'pdf') {
                        const props_ = {
                            ...save_props,
                            textarray: null,
                            process: 'document.convert2pdf'
                        };
                        await Save(props_).then((response) => {
                            reducerActions.setProgressState(dispatch, {
                                'etc processing': null
                            });
                        })
                    }
                    else {
                        reducerActions.setProgressState(dispatch, {
                            'etc processing': null
                        });
                    }

                    if (callback) {
                        callback(event)
                    } else {
                        return response
                    }
                }).catch((err) => {

                });
            }
            else if (response.data.status === 'exists translated file') {
                reducerActions.setProgressState(dispatch, {
                    'etc processing': 'save file(exists translated file)'
                });
                setProgressBar(null);
                const props_ = {
                    ...save_props,
                    process: 'document.write_part'
                }
                await Save(props_).then(async (response) => {
                    // if (fileType == 'pdf') {
                    //   const props_ = {
                    //     ...save_props,
                    //     textarray: null,
                    //     process: 'document.convert2pdf'
                    //   };
                    //   await Save(props_).then((response) => {
                    //     reducerActions.setProgressState(dispatch, {
                    //       'etc processing': null
                    //     });
                    //   })
                    // }
                    reducerActions.setProgressState(dispatch, {
                        'etc processing': null
                    });
                    if (callback) {
                        callback(event)
                    } else {
                        return response
                    }

                })
            }
        });
    };

    const save_all = async (sequence, save_props, jobCount) => {
        for (const [index, indices] of sequence.entries()) {
            const props_ = {
                ...save_props,
                textarray: null,
                process: 'document.write_all',
                indices: indices,
                lastJob: index === sequence.length - 1 ? true : false,
            };
            await Save(props_).then((response) => {
                setProgressBar(((index + 1) / jobCount) * 100);
            })
        }
        return new Promise((resolve) => { resolve(true) })
    };



    // save to db
    const saveToDB = (text) => {
        // if check is True, does not process.
        if (!checkedOrNotDict[text.key] && text.line !== targetSentence[text.key]) {
            service.put(
                fileName,
                props.project_no ? props.project_no : "tempFiles",
                userEmail,
                ownerId,
                checkedOrNotDict[text.key] === false ? 0 : 1,
                text.line,
                text.key
            )
            reducerActions.assignItemStsScoreDict(dispatch, [text.key, -1]);
        }
    };

    const onExportButton = (event, withoutStyle) => {
        reducerActions.setProgressState(dispatch, {
            'processing': true, 'etc processing': 'export tmx'
        });
        event.preventDefault();
        const project_no = props.project_no ? props.project_no : "tempFiles";

        const export_props = {
            fileName: fileName,
            project_no: project_no,
            direction: direction,
            withoutStyle: withoutStyle,
        };
        Export2Tmx(export_props).then((data) => {
            reducerActions.setProgressState(dispatch, {
                'etc processing': null
            });
            //console.log(data);
            const element = document.createElement("a");
            const file = new Blob([data], {
                type: "text/plain",
            });
            element.href = URL.createObjectURL(file);
            element.download = `${fileName}.tmx`;
            element.click();
        });
    };

    const onIntersection = ([entry], observer) => {
        if (!waiting && entry.isIntersecting && !progressState['file reading']) {
            setWaiting(true);
            !completion && readHandler(props.currentFile);
        }
        // console.log(entry)
        // console.log(observer)
    };
    // -- Funtions end --

    // -- use Effect start --
    // editor data initialize
    useEffect(() => {
        reducerActions.initialize(dispatch);
        setWaiting(false);
    }, []);

    // observing
    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.5 })
        observerTarget.current && observer.observe(observerTarget.current);

        return () => observer && observer.disconnect();
    }, [onIntersection])

    // file read & translation (get src, tgt lines)
    useEffect(() => {
        reducerActions.setProgressState(dispatch, {
            'processing': false,
            'file reading': false,
            'translation': false,
            'sts checking': false,
            'tm checking': false,
            'etc processing': null,
            'point': 0
        });
        reducerActions.setDirection(dispatch, props.direction);
        readHandler(props.currentFile);
        reducerActions.setLoadedSrcLanguage(dispatch, true);
    }, [dispatch]);

    // translation. (get tgt lines)
    // useEffect(() => {
    //   if (progressState === "translated" && fileName){
    //     reducerActions.setProgressState(dispatch, {
    //       'processing': true,
    //       'translation': true
    //     });
    //     translation(fileName, 'true', direction, 'fetching');
    //   }
    // }, [domain]);

    // progress handler
    useEffect(() => {
        if (
            progressState['processing'] &&
            !progressState['file reading'] &&
            !progressState['translation'] &&
            !progressState['sts checking'] &&
            !progressState['tm checking'] &&
            !progressState['etc processing'] &&
            progressState['point'] === 0
        ) {
            reducerActions.setProgressState(dispatch, {
                'processing': false,
            });
        }
    }, [progressState])

    // useEffect(() => {
    //   progressState === 'translated' && setProgressBar(0);
    // }, [progressState])

    // useEffect(() => {
    //     progressState == 'translated' && getSTSScore(
    //         sourceSentence, targetSentence);
    // }, [targetSentence])
    // -- use Effect end --

    // console.log(fetchedLineNumber);
    // console.log(completion);
    // console.log(progressState);
    // console.log(stsScoreDict);
    // console.log(modifiedOrNotDict);
    // console.log(linesNumber);
    // console.log(direction);
    // console.log(checkedOrNotDict);
    // console.log(props.project_no);
    // console.log(tmxMatch);
    // console.log(sourceSentence);
    // console.log(targetSentence);
    // console.log(fetchedLineNumber);
    // console.log(observerTarget);
    // console.log(observerTarget.current)
    // console.log(edCtx);
    // console.log(loadedSrcLanguage)
    // console.log(progressBar)
    // console.log(edCtx.firstTargetSentence)
    // console.log(stsScoreDict)
    // console.log(waiting)

    return (
        <Wrapper>
            {onRightClickMenu && <RightContext
                xYPosition={xYPosition}
                onChangeLine={onChangeLine}
                saveToDB={saveToDB}
                setOnRightClickMenu={setOnRightClickMenu}
            />}
            {loadedSrcLanguage && (
                <Wrapper>
                    <TopOptions
                        stsCheck={getSTSScore}
                        project_no={props.project_no}
                        translation={translation}
                        onSaveButton={onSaveButton}
                        onExportButton={onExportButton}
                        limitedOptions={
                            (props.project_no === 'individual' ||
                                props.project_no === 'tempFiles') ? true : false}
                        acceptance={
                            props.acceptance
                                ? props.acceptance === 'accepted'
                                    ? true
                                    : false
                                : true}
                        waiting={waiting}
                        editorType='번역'

                    />
                    <PopupModalList
                        progressState={progressState}
                        progress={progressBar}
                    />

                    <Card className="m-0">
                        <TextEditors
                            changeLines={onChangeLines}
                            changeLine={onChangeLine}
                            onLineSelect={onLineSelect}
                            setGlossaryData={setGlossaryData}
                            setGlossaryActive={setGlossaryActive}
                            onEditorStateHandler={onEditorStateHandler}
                            onModifedOrNotHandler={onModifedOrNotHandler}
                            onCheckedOrNotHandler={onCheckedOrNotHandler}
                            getSTSScore={getSTSScore}
                            onSaveButton={onSaveButton}
                            saveToDB={saveToDB}
                            setOnRightClickMenu={setOnRightClickMenu}
                            setXyPosition={setXyPosition}
                            ownerId={ownerId}
                            limitedOptions={
                                (props.project_no === 'individual' ||
                                    props.project_no === 'tempFiles') ? true : false}
                            // setObserverTarget={setObserverTarget}
                            observerTarget={observerTarget}
                            completion={completion}
                            editorType='번역'
                        />
                    </Card>
                    <BottomOptions
                        progressState={progressState}
                        progress={progressBar}
                        activeLine={edCtx.currentActive}
                        onChangeLine={onChangeLine}
                        saveToDB={saveToDB}
                        setGlossaryActive={setGlossaryActive}
                        available={ownerId !== 'nonMember'}
                    />
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default MainPart;

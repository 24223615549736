import axios from "axios";

import { BACKEND } from "../../../../Constants";

const deleteItem = (project_no, userNo, name, index) => {
  const data = new FormData();
  
  data.append('project_no', project_no);
  data.append('user_no', userNo);
  data.append('owner', userNo);
  data.append('fileName', name);
  data.append('lineNumber', index);
  data.append('process', 'glossary.deleteLine');

  axios.put(`${BACKEND.SERVER}/files`, data
    ).then((response) => {
      // console.log(response)
    })
};

export { deleteItem };
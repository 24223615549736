import React from "react";

// wrapper
import Wrapper from "../helpers/Wrapper";

// react-bootstrap
import { Card, Form, } from "react-bootstrap";

// `default-${type}`
const CheckSwitch = (props) => {
    return (
        <Form>
            <Form.Check 
                type = {'switch'}
                id = {props.id.toString()}
                label = {props.label}
                checked = {props.checked}
                value = {props.id}
                readOnly = {true}
                style = {props.style}
            />
        </Form>
        )
};

export default CheckSwitch;
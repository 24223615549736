import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, NavLink as RRNavLink } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { enableCorporateTheme } from "../../redux/actions/themeActions";
import MainFooter from "../../components/Footer";

import LetteredAvatar from 'react-lettered-avatar';
import AuthContext from '../../store/auth-context';
import * as Icon from "react-feather";

import Wrapper from "../../components/Wrapper";
import Content from "../../components/Content2";
import Sidebar from "../../components/Sidebar";
import { toggleSidebar } from "../../redux/actions/sidebarActions";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    ListGroupItem,
    Media,
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    Row,
    UncontrolledDropdown,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
// import mainlogo from "../../assets/img/brands/gocat_large.svg"
// import mainlogo from "../../assets/img/brands/thinkCAT.svg";
// import mainlogo from "../../assets/img/brands/THINKCATICON.png";
import mainlogo from "../../assets/img/brands/ver2/thinkcat.svg"
import { useMediaQuery } from "react-responsive";
const june = "/jpg/June1.png";
const haku = "/jpg/Haku.png";
const rie = "/jpg/Rie.png";
const cats = "/jpg/cats.png";

const calDate = (d) => {
    var date = new Date(d * 1000);

    var time = date.getFullYear() + '-' +
        ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) + ' ' +
        ('00' + date.getHours()).slice(-2) + ':' +
        ('00' + date.getMinutes()).slice(-2) + ':' +
        ('00' + date.getSeconds()).slice(-2);
    return time;
}

const NavbarDropdown = ({ children, count, showBadge, header, footer, icon: Icon }) => {
    let link = '/chats';
    if (footer === '모든 알림 보기') link = '/notifications';
    return (
        <UncontrolledDropdown nav inNavbar className="mr-2">
            <DropdownToggle nav className="nav-icon dropdown-toggle">
                <div className="position-relative">
                    <Icon className="align-middle" size={18} />
                    {showBadge ? <span className="indicator">{count}</span> : null}
                </div>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-lg py-0">
                <div className="dropdown-menu-header position-relative">
                    {count} {header}
                </div>
                <ListGroup>{children}</ListGroup>
                <DropdownItem header className="dropdown-menu-footer">
                    <Link to={link}><span className="text-muted">{footer}</span></Link>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

const NavbarDropdownItem = ({ icon, title, description, time, query }) => {
    const history = useHistory();
    const styles = { cursor: 'pointer' };
    return (
        <ListGroupItem>
            <Row style={styles} noGutters className="align-items-center" onClick={() => {
                { query && history.push("/support/" + query) }
                { !query && history.push("/chats/" + title) }
            }}>
                <Col xs={2}>{icon}</Col>
                <Col xs={10} className={!query ? "pl-2" : null}>
                    <div className="text-dark">{title}</div>
                    <div className="text-muted small mt-1">{description}</div>
                    <div className="text-muted small mt-1">{time}</div>
                </Col>
            </Row>
        </ListGroupItem>
    )
};

const Navigation = () => {
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const history = useHistory();

    // 
    const notifications = authCtx.notifications;
    const messages = authCtx.messages;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const thename = isLoggedIn ? userInfo.userName : null;
    const theemail = isLoggedIn ? userInfo.userEmail : null;

    useEffect(() => {
        if (isLoggedIn) {
            // localStorage.setItem('socket', JSON.stringify(authCtx.socket))
            localStorage.setItem('socket', authCtx.socket)
        } else {
            localStorage.setItem('socket', null)
        }
    }, [])

    const logoutHandler = () => {
        authCtx.logout();
        localStorage.clear();
        history.replace("/");
    };
    const sticky = useSelector((state) => state.navbar.topNavBarSticky)
    const topNavbarSticky = sticky ? 'top' : 'false'
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    return (
        // Mobile
        <Navbar light expand="md" className="navbar-landing" sticky={topNavbarSticky}>
            {isMobile &&
                <Container>
                    <span
                        className="sidebar-toggle d-flex mr-2"
                        onClick={() => {
                            dispatch(toggleSidebar());
                        }}
                    >
                        <i className="hamburger align-self-center" />
                    </span>
                    {isLoggedIn &&
                        <div className="justify-content-end" style={{ display: "flex", listStyle: "none" }}>
                            <NavbarDropdown
                                header="새 메시지"
                                footer="모든 메시지 보기"
                                icon={Icon.MessageCircle}
                                count={messages ? messages.length : 0}
                                showBadge={messages && messages.length > 0 ? true : ''}
                            >

                                {messages && messages.map((item, key) => {
                                    return (
                                        <NavbarDropdownItem
                                            key={key}
                                            icon={
                                                <LetteredAvatar
                                                    size={40}
                                                    name={item.nick}
                                                />
                                            }
                                            title={item.nick}
                                            description={item.message}
                                            time={calDate(item.time)}
                                            query=""
                                        />
                                    );
                                })}
                            </NavbarDropdown>
                            <NavbarDropdown
                                header="새 알림"
                                footer="모든 알림 보기"
                                icon={Icon.Bell}
                                count={notifications ? notifications.length : 0}
                                showBadge={notifications && notifications.length > 0 ? true : ''}
                            >
                                {notifications && notifications.slice(0, 5).map((item, key) => {
                                    let icon = <Icon.MessageSquare size={18} className="text-success" />;
                                    let title = '댓글 알림';
                                    let type = '댓글을 쓰셨습니다.';
                                    if (item.comment == 'Love') {
                                        icon = <Icon.Heart size={18} className="text-danger" />;
                                        title = '추천 알림';
                                        type = '추천을 하셨습니다.';
                                    } else if (item.comment == 'kick out') {
                                        icon = <Icon.MinusCircle size={18} className="text-danger" />;
                                        title = '프로젝트 퇴장';
                                        type = "님이 회원님을 프로젝트에서 내보냈습니다.";
                                    } else if (item.comment == 'invite') {
                                        icon = <Icon.Smile size={18} className="text-success" />;
                                        title = '프로젝트 초대';
                                        type = "님이 회원님을 프로젝트에 초대했습니다."
                                    }
                                    else if (item.thread !== 'A')
                                        icon = <Icon.MessageSquare size={18} className="text-info" />;
                                    return (
                                        <NavbarDropdownItem
                                            icon={icon}
                                            key={key}
                                            title={title}
                                            description={
                                                // item.thread.length > 1 ? (item.member_name + "님이 회원님의 댓글에 댓글을 쓰셨습니다.") : (item.member_name + "님이 회원님의 글에 " + type)
                                                (item.thread === 'pjt') ? (item.member_name + type) :
                                                    (item.thread === 'dlt') ? (item.member_name + type) :
                                                        (item.thread !== 'pjt' && item.thread !== 'dlt' && item.thread !== 'A' && item.thread !== '0') ? (item.member_name + "님이 회원님의 댓글에 댓글을 쓰셨습니다.") :
                                                            (item.thread !== 'pjt' && item.thread !== 'dlt' && item.thread === 'A' || item.thread === '0') && (item.member_name + "님이 회원님의 글에 " + type)
                                            }
                                            time={item.created_datetime}
                                            query={item.query}
                                        />

                                    );
                                })}
                            </NavbarDropdown>
                            {isMobile && isLoggedIn && <>
                                <Button
                                    onClick={logoutHandler}
                                    rel="noopener noreferrer"
                                    color="primary"
                                    size="lg"
                                >로그아웃
                                </Button>
                            </>
                            }
                        </div>
                    }
                    {!isLoggedIn && <div className="justify-content" style={{ display: "flex" }}>

                        <Link to="/auth/sign-in">
                            <Button color="primary" size="lg" outline>
                                로그인
                                {/* <NavItem>
                                    <NavLink exact to="/auth/sign-in" active tag={RRNavLink}>
                                    로그인
                                    </NavLink>
                                </NavItem> */}
                            </Button>
                        </Link>
                        <Link to="/auth/sign-up">
                            <Button
                                rel="noopener noreferrer"
                                color="primary"
                                className="ml-2"
                                size="lg"
                            >회원 가입
                        </Button>
                        </Link>
                    </div>}

                    <Container>
                        <NavbarBrand href="/" style={{ margin: "auto" }}>
                            <img src={mainlogo} height='60' title="main CAT" />
                            {/* <img src={cats} height="60" title="main CAT" />       */}
                        </NavbarBrand>

                    </Container>
                    <div className="landing-icons mt-4">
                        <Link className="landing-icon-link rounded-circle m-auto" to="/upload" style={{ textDecorationLine: "none" }}>
                            <p className="landing-icon-p">
                                {/* <FontAwesomeIcon className="landing-icon" icon={faUserFriends} /> */}
                                <Icon.FileText className="landing-icon feather" />
                            </p>
                            <b>&nbsp;간편</b>
                        </Link>
                        <Link className="landing-icon-link rounded-circle m-auto" to="/projects" style={{ textDecorationLine: "none" }}>
                            <p className="landing-icon-p">
                                {/* <FontAwesomeIcon className="landing-icon" icon={faUserFriends} /> */}
                                <Icon.Edit className="landing-icon feather" />
                            </p>
                            <b>&nbsp;번역</b>
                        </Link>
                        <Link className="landing-icon-link rounded-circle m-auto" to={{ pathname: "/projects", state: { type: "post-editing" } }} style={{ textDecorationLine: "none" }}>
                            <p className="landing-icon-p">
                                {/* <FontAwesomeIcon className="landing-icon" icon={faUserFriends} /> */}
                                <Icon.CheckSquare className="landing-icon feather" />
                            </p>
                            <b className="">&nbsp;검수</b>
                        </Link>
                    </div>
                </Container >}

            {/* PC */}
            {
                !isMobile &&
                <Container>
                    <NavbarBrand href="/">
                        <img src={mainlogo} height='60' title="main CAT" />
                        {/* <img src={cats} height="60" title="main CAT" />       */}
                    </NavbarBrand>
                    <Nav className="ml-auto" navbar>
                        {isLoggedIn && (
                            <>
                                <NavbarDropdown
                                    header="새 메시지"
                                    footer="모든 메시지 보기"
                                    icon={Icon.MessageCircle}
                                    count={messages ? messages.length : 0}
                                    showBadge={messages && messages.length > 0 ? true : ''}
                                >

                                    {messages && messages.map((item, key) => {
                                        return (
                                            <NavbarDropdownItem
                                                key={key}
                                                icon={
                                                    <LetteredAvatar
                                                        size={40}
                                                        name={item.nick}
                                                    />
                                                }
                                                title={item.nick}
                                                description={item.message}
                                                time={calDate(item.time)}
                                                query=""
                                            />
                                        );
                                    })}
                                </NavbarDropdown>
                                <NavbarDropdown
                                    header="새 알림"
                                    footer="모든 알림 보기"
                                    icon={Icon.Bell}
                                    count={notifications ? notifications.length : 0}
                                    showBadge={notifications && notifications.length > 0 ? true : ''}
                                >
                                    {notifications && notifications.slice(0, 5).map((item, key) => {
                                        let icon = <Icon.MessageSquare size={18} className="text-success" />;
                                        let title = '댓글 알림';
                                        let type = '댓글을 쓰셨습니다.';
                                        if (item.comment == 'Love') {
                                            icon = <Icon.Heart size={18} className="text-danger" />;
                                            title = '추천 알림';
                                            type = '추천을 하셨습니다.';
                                        } else if (item.comment == 'kick out') {
                                            icon = <Icon.MinusCircle size={18} className="text-danger" />;
                                            title = '프로젝트 퇴장';
                                            type = "님이 회원님을 프로젝트에서 내보냈습니다.";
                                        } else if (item.comment == 'invite') {
                                            icon = <Icon.Smile size={18} className="text-success" />;
                                            title = '프로젝트 초대';
                                            type = "님이 회원님을 프로젝트에 초대했습니다."
                                        }
                                        else if (item.thread !== 'A')
                                            icon = <Icon.MessageSquare size={18} className="text-info" />;
                                        return (
                                            <NavbarDropdownItem
                                                icon={icon}
                                                key={key}
                                                title={title}
                                                description={
                                                    // item.thread.length > 1 ? (item.member_name + "님이 회원님의 댓글에 댓글을 쓰셨습니다.") : (item.member_name + "님이 회원님의 글에 " + type)
                                                    (item.thread === 'pjt') ? (item.member_name + type) :
                                                        (item.thread === 'dlt') ? (item.member_name + type) :
                                                            (item.thread !== 'pjt' && item.thread !== 'dlt' && item.thread !== 'A' && item.thread !== '0') ? (item.member_name + "님이 회원님의 댓글에 댓글을 쓰셨습니다.") :
                                                                (item.thread !== 'pjt' && item.thread !== 'dlt' && item.thread === 'A' || item.thread === '0') && (item.member_name + "님이 회원님의 글에 " + type)
                                                }
                                                time={item.created_datetime}
                                                query={item.query}
                                            />

                                        );
                                    })}
                                </NavbarDropdown>
                            </>)}
                        {isLoggedIn && (
                            <>
                                <NavItem>
                                    <NavLink
                                        exact
                                        to={{ pathname: "/projects", state: { type: "translation" } }}
                                        tag={RRNavLink}>
                                        번역
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        exact
                                        to={{ pathname: "/projects", state: { type: "post-editing" } }}
                                        tag={RRNavLink}>
                                        검수
                                </NavLink>
                                </NavItem>
                            </>
                        )}
                        {!isLoggedIn && (
                            <NavItem>
                                <NavLink exact to="/auth/sign-in" active tag={RRNavLink}>
                                    로그인
                            </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    {!isLoggedIn && (
                        <Link to="/auth/sign-up">
                            <Button
                                rel="noopener noreferrer"
                                color="primary"
                                className="ml-2"
                                size="lg"
                            >회원 가입</Button></Link>
                    )}
                    {isLoggedIn && (
                        <Button
                            onClick={logoutHandler}
                            rel="noopener noreferrer"
                            color="primary"
                            className="ml-2"
                            size="lg"
                        >로그아웃
                        </Button>
                    )}
                </Container>
            }
        </Navbar >
    );
};

const Intro = () => {
    const handleClick = (e) => {
        document.getElementById('demos').scrollIntoView();
        e.preventDefault();
    }
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <section className="landing-intro pt-5">
            <Container>
                <Row>
                    <Col md="7" className="mx-auto text-center">
                        <h1 className="landing-intro-title mt-4 mb-7">전문 번역 AI를 갖춘 CAT 툴</h1>
                        {/* <h3 className="text-muted">(2022년 6월 30일자)</h3> */}
                    </Col>
                </Row>
                <Row>
                    <Col md="7" className="mx-auto text-center">
                        <Link to="/upload" className="btn btn-light btn-lg mr-1 my-4">
                            번역할 파일 업로드하기
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col md="8" className="mx-auto text-center">
                        <div className="mt-0 mb-6">
                            <small>지원하는 파일: *.docx, *.pdf, *.srt, *.smi, *.txt</small>
                        </div>
                        {/* {!isMobile && <div className="mt-2" >
                            <img src="/jpg/coming-soon.png" className="img-fluid rounded-lg" alt="Corporate Bootstrap 4 Dashboard Theme" style={{ opacity: "0" }} />
                        </div>} */}
                    </Col>
                </Row>
            </Container>

            {/*	   <svg className="landing-intro-shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#F7F9FC" fill-opacity="1" d="M0,160L1440,32L1440,320L0,320Z"></path></svg> */}

        </section>
    )
};

const Features = () => (
    <section className="py-6">
        <Container>
            <Row>
                <Col md="10" className="mx-auto text-center">
                    <div className="mb-5">
                        <h2>서비스 특징</h2>
                        <p className="text-muted text-lg">
                            번역 PM, 번역자, 검수자가 온라인에서 AI의 도움을 받아 번역하고 검수하는 통합 서비스
            </p>
                    </div>

                    <Row className="text-left">
                        <Col md="6">
                            <Media className="py-3">
                                <img src={haku} height="60" title="Haku" />
                                <Media body>
                                    <h4 className="mt-0">번역 PM</h4>
                                    <p className="font-size-lg">
                                        <Icon.Check />이메일 대신 온라인에서<br />
                                        <Icon.Check />진척도 실시간 확인<br />
                                        {/* <Icon.Check />번역자 기여도 자동 측정<br /> */}
                                        <Icon.Check />추가 인력 투입 및 업무 분산 용이

                  </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md="6">
                            <Media className="py-3">
                                <img src={rie} height="60" title="Rie" />
                                <Media body>
                                    <h4 className="mt-0">번역자</h4>
                                    <p className="font-size-lg">
                                        <Icon.Check />파일만 업로드하면 자동 번역<br />
                                        <Icon.Check />AI 가번역 결과 중 잘된 부분은 그대로 사용하게 체크<br />
                                        {/* <Icon.Check />추가 편집 필요한 부분만 스케줄에 따라 번역<br /> */}
                                        <Icon.Check />일반, 법률, 특허 번역 AI 선택 가능<br />
                                        <Icon.Check />용어집과 안맞는 단어 표시<br />
                                        <Icon.Check />번역 메모리 지원<br />
                                    </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md="6">
                            <Media className="py-3">
                                <img src={june} height="60" title="June" />
                                <Media body>
                                    <h4 className="mt-0">검수자</h4>
                                    <p className="font-size-lg">
                                        <Icon.Check />번역 메모리 파일로 검수 진행<br />
                                        <Icon.Check />번역이 틀린 문장을 표시<br />
                                        {/* <Icon.Check />효율적으로 검수<br /> */}
                                    </p>
                                </Media>
                            </Media>
                        </Col>
                        <Col md="6">
                            <Media className="py-3">
                                <img src={cats} height="60" title="June" />
                                <Media body>
                                    <h4 className="mt-0">번역 팀</h4>
                                    <p className="font-size-lg">
                                        <Icon.Check />이메일로 팀원 초청 기능<br />
                                        <Icon.Check />팀원간 웹 채팅 기능<br />
                                        {/* <Icon.Check />반응형 싱글 페이지 애플리케이션<br /> */}
                                    </p>
                                </Media>
                            </Media>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
);

const FaqQuestion = ({ onClick, isOpen, question, answer }) => (
    <Card className="border mb-3">
        <CardHeader className="cursor-pointer" onClick={onClick}>
            <h6 className="mb-0">{question}</h6>
        </CardHeader>
        <Collapse isOpen={isOpen}>
            <CardBody className="py-3">
                {answer}
            </CardBody>
        </Collapse>
    </Card>
)

const Faq = () => {
    const [activeQuestion, setActiveQuestion] = useState(0);

    return (
        <section className="pt-6 pb-3">
            <Container>
                <div className="mb-5 text-center">
                    <h2>Frequently Asked Questions</h2>
                    <p className="text-muted font-size-lg">서비스 이용 전에 참고하십시오.</p>
                </div>

                <Row>
                    <Col md={9} lg={8} className="mx-auto">
                        <FaqQuestion
                            onClick={() => setActiveQuestion(0)}
                            isOpen={activeQuestion === 0}
                            // question="지원하는 언어가 영어-한글뿐인가요?"
                            // answer={<>2022년 현재는 그렇습니다. 대신 일반적인 번역기에서 지원하지 않는 법률 및 특허 문서에 최적화된 AI 번역기를 선택하실 수 있습니다. 2023년 초에 일어와 중국어가 추가로 지원될 에정입니다.</>}
                            // answer={<>2023년 05월 기준, 한국어 ↔ 일어, 중국어가 추가로 지원되고 있으며 일반적인 번역기에서 지원하지 않는 법률 및 특허 문서에 최적화된 AI 번역기를 선택하실 수 있습니다.</>}
                            question="지원하는 언어가 몇 종류인가요?"
                            // answer={<>기존에는 「영어↔한국어」만 지원됐으나,<br />2023년 5월 현재 「중국어↔한국어」와 「일본어↔한국어」가 추가됐습니다. </>}
                            answer={<>기존에는 영어↔한국어만 지원됐으나,<br />2023년 5월 현재 중국어↔한국어와 일본어↔한국어가 추가됐습니다. </>}
                        />
                        <FaqQuestion
                            onClick={() => setActiveQuestion(1)}
                            isOpen={activeQuestion === 1}
                            question="지원하는 문서양식은 무엇인가요?"
                            answer={<>현재는 MS-Word (*.Docx), PDF (*.pdf), 자막파일 (*.srt, *.smi) 그리고 텍스트 (.txt)만 지원합니다. Docx, PDF 파일 본문의 폰트나 색깔 등의 양식을 모두 살려서 번역됩니다. 추후 다른 파일 형식들이 추가될 예정입니다.</>}
                        />
                        <FaqQuestion
                            onClick={() => setActiveQuestion(2)}
                            isOpen={activeQuestion === 2}
                            question="PC에서만 사용가능한가요?"
                            answer={<>반응형 웹으로 개발되어 스마트폰이나 태블릿에서도 편리하게 사용하실 수 있습니다. 또한 싱글 페이지 애플리케이션으로 개발되어 설치형 소프트웨어과 유사한 사용자 경험을 제공해드립니다.</>}
                        />
                        <FaqQuestion
                            onClick={() => setActiveQuestion(3)}
                            isOpen={activeQuestion === 3}
                            question="사용 요금은 어떻게 되나요?"
                            answer={<>2022년 12월 현재는 테스트 기간으로 모든 기능이 무료입니다. 2023년 초에 유료화 될 예정입니다.</>}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

const DemoVideo = (props) => (
    <div style={{ backgroundColor: "#fff" }}>
        <section className="landing-features3 py-6" >
            <Container>
                <div className="p-1">
                    <Row >
                        <Col md="10" className="mx-auto text-center">
                            <div className="mb-2">
                                <h2>서비스 데모</h2>
                                <p className="text-muted text-lg">
                                    씽캣툴즈 번역 및 검수 기능
                                </p>
                            </div>
                            <div className="landing-product-img basic-scroll">
                                <iframe width={!props.isMobile ? "640" : "320"} height={!props.isMobile ? "360" : "180"} src="https://www.youtube.com/embed/RmiPn5RVk30" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    </div >
);


const Footer = () => (
    <section className="landing-footer pb-6">
        <svg className="landing-footer-shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 100 1440 220">
            <path fill="#F7F9FC" fillOpacity="1" d="M0,128L1440,256L1440,0L0,0Z"></path>
        </svg>
        <Container className="text-center landing-footer-container">
            <Row>
                <Col md="9" lg="8" xl="6" className="mx-auto">
                    <h2 className="h1 text-white mb-3">
                        2022년 12월 1일, thinkcat.tools가 오픈했습니다.
          </h2>
                    <Link to="/auth/sign-up">
                        <Button
                            color="light"
                            size="lg"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mt-n1"
                        >
                            회원 가입하기
          </Button></Link>
                </Col>
            </Row>
        </Container>
    </section>
);

const Landing = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    return (
        <React.Fragment>
            {isMobile ?
                <Wrapper>
                    <Sidebar />
                    <Content className="p-0">
                        <Navigation />
                        <Intro />
                        {/*    <Styles /> */}
                        {/*   <Dashboards /> */}
                        <Features />
                        {/*     <Testimonials /> */}
                        <DemoVideo isMobile={isMobile} />
                        <Faq />
                        <Footer />
                        <MainFooter />
                    </Content>

                </Wrapper>
                : <>
                    <Navigation />
                    <Intro />
                    {/*    <Styles /> */}
                    {/*   <Dashboards /> */}
                    <Features />
                    {/*     <Testimonials /> */}
                    <DemoVideo isMobile={isMobile} />
                    <Faq />
                    <Footer />
                    <MainFooter />
                </>
            }
        </React.Fragment>
    )
}

// export default connect()(Landing);
export default connect(store => ({ app: store.app }))(Landing);
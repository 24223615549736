const LANGUAGENAMES = {
  'ko': '한국어',
  'en': '영어',
  'jp': '일본어',
  'ch': '중국어'
};

const toCode = (value) => {
  const key = Object.keys(LANGUAGENAMES).find(
    key => LANGUAGENAMES[key] === value
  )
  return key
}

export { LANGUAGENAMES, toCode }
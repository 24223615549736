import React from 'react';

import { useParams } from "react-router-dom";

import { Card, Table } from 'react-bootstrap';

// parts
import GlossariesList from './GlossariesList';
import TopOptions from './TopOptions';
import TableHead from './TableHead';

const Editor = (props) => {
  const params = useParams();
  const project_no = params.project_no;

  const name = params.name;
  const direction = params.direction;

  const userInfo = localStorage.getItem('userInfo');
  const userName = userInfo ?JSON.parse(userInfo).userName :'';
  const userNo   = userInfo ?JSON.parse(userInfo).userUid :'';

  return (
    <Card>
      <TopOptions
        project_no = {project_no}
        userName = {userName}
        userNo = {userNo}
        name = {name}
        setImportModal = {props.setImportModal}
        setExportModal = {props.setExportModal}
      />
      <Table striped bordered hover size="sm"
        style = {{
          tableLayout:'fixed'
        }}
      >
        <TableHead 
          direction = {direction}
        />
        <GlossariesList 
          project_no = {project_no}
          name = {name}
        />
      </Table>
    </Card>
  )
};

export default Editor;
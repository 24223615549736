// progress state
const progressReducer = (state, action) => {
  return { state: action.type };
};

// editor state (textarea or web editor)
const setEditorState = (state, action) => {
  if (action.formState === "change form") {
    if (state[action.key] === "textarea") {
      return { ...state, [action.key]: "webEditor" };
    } else {
      return { ...state, [action.key]: "textarea" };
    }
  } else {
    return { ...state, [action.key]: action.force };
  }
};

// check to modified or not
const setModifedOrNot = (state, action) => {
  if (state[action.key] === false) {
    return { ...state, [action.key]: true };
  } else {
    return { ...state };
  }
};

// check to user checking or not
const setCheckedOrNot = (state, action) => {
  return { ...state, [action.key]: !state[action.key] };
};

// get number of valid lines
const linesNumberReducer = (state, action) => {
  return state + 1;
};

// editor data reducer utils
const intializeState = (lines, linesNumber, fetchedLineNumber) => {
  let stateDict = {};
  let modifiedOrNot = {};
  let checkedOrNot = {};
  lines.map((line, index) => {
    stateDict = { ...stateDict, 
      [(fetchedLineNumber+index).toString()]: "textarea" };
    modifiedOrNot = { ...modifiedOrNot, 
      [(fetchedLineNumber+index).toString()]: false };
    checkedOrNot = { ...checkedOrNot, [
      (fetchedLineNumber+index).toString()]: false };
    if (line === "<p><br></p>" || line === "") {
      linesNumber--;
    }
  });
  return [stateDict, modifiedOrNot, checkedOrNot, linesNumber];
};

const isIterable = (value) => {
  return Symbol.iterator in Object(value);
};

// get text match range
const getTextMatchIndex = (word, glossaries) => {
  let matchLength = 0;
  let matching = false;
  const wordIndex = [...Array(word.length).keys()];
  if (isIterable(glossaries) && isIterable(wordIndex)) {
    for (const glossary of glossaries) {
      for (const i of wordIndex) {
        // console.log(glossary, glossary.length);
        if (i === glossary.length - 1) {
          break;
        }
        if (glossary.slice(0, i + 1) === word.slice(0, i + 1)) {
          matchLength = i;
          matching = true;
        }
      }
    }
  }
  return [matchLength, matching];
};

// get glossary match
const tagGlossary = (word, wordIndex, glossary, key) => {
  let matchedIndex = -1;
  let matchedLength = 0;
  // console.log(key);
  let matchedAll = key === "glossary_src" ? true : false;
  glossary.some((items, index) => {
    items[key].some((item, item_i) => {
      if (word.search(item) !== -1) {
        matchedIndex = index;
        matchedLength = item.length;
        if (key === "tgt") {
          const [matchLength, matching] = getTextMatchIndex(
            word.toLowerCase(),
            items["glossary_tgt"]
          );
          matchedLength = matchLength;
          matchedAll = matching;
        }
        return;
      }
    });
  });
  return [matchedIndex, matchedLength, matchedAll];
};

const setItem = (dispatch, action) => {
  dispatch({
    type: "EDITOR DATA UPDATE",
    key: action.key,
    payload: action.payload,
  });
};

export default {
  progressReducer,
  setEditorState,
  setModifedOrNot,
  setCheckedOrNot,
  linesNumberReducer,
  intializeState,
  tagGlossary,
};

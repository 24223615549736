import React, { useContext, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

import { Container, Card } from "reactstrap";

import InputForm from "./InputForm";

import AuthContext from "../../../../store/auth-context";
import ProjectInfoContext from "../contexts/ProjectInfo-context";

import ProjectInfoProvider from "../contexts/ProjectInfoProvider";

import { BACKEND } from "../../../../Constants";

const AddProjectCard = (props) => {
  const authCtx = useContext(AuthContext);
  const projectCtx = useContext(ProjectInfoContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const onCreateButton = () => {
    if (projectCtx.project.isValid.title === 'valid' &&
        projectCtx.project.isValid.src === 'valid' &&
        projectCtx.project.isValid.tgt === 'valid') {
      const token = localStorage.getItem('token');
      const data = {
        title: projectCtx.project.title,
        description: projectCtx.project.description,
        source_language: projectCtx.project.sourceLang,
        target_language: projectCtx.project.targetLang,
        deadline: projectCtx.project.deadLine,
        projectType: props.projectType,
      }
      axios.post(`${BACKEND.SERVER}/project`, data, {
        headers: {
          "Authorization": `JWT ${token}`
        }}
      ).then((response) => {
      }).catch(()=> {
        alert('알수없는 오류입니다.')
      })
      props.onCancelButton();

    } else {
      alert('필수 입력 항목이 올바르지 않습니다.')
    }
  };

  useEffect(()=> {
    projectCtx.initProject();
  }, [])

  if (!isLoggedIn) {
    return <Redirect to="/auth/sign-in" />;
  } else {
    return (
      <Card className = 'p-1 pb-3'  color = 'white'>
        <Container fluid className="p-0">
          <InputForm
            onCancelButton = {props.onCancelButton}
            onCreateButton = {onCreateButton}
            availableEdit = {true}
            projectType = {props.projectType}
          />
        </Container>
      </Card>
    );
  }
};

const AddProject = (props) => {
  return (
    <ProjectInfoProvider>
      <AddProjectCard {...props} />
    </ProjectInfoProvider>
  )
}
export default AddProject;

import React from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import AssignmentPage from '../projects/TaskAssignment/parts/Assignment.Page';

const TaskAssignment = () => {
  const params = useParams();

  return (
    <Container fluid className="p-0">
      <AssignmentPage />
    </Container>
  );
}

export default TaskAssignment;

import React from "react";

import { Modal } from "react-bootstrap";

const LoadingModal = (props) => {
  const handleClose = () => {}
  return (
    <Modal 
      show={props.show} 
      onHide={handleClose}
      centered
      size = 'sm'
    >
      <Modal.Body className="align-items-center">
        <div align='center'>
          <img height='200' src = {props.gif} border = '0' />
          <h4 className = 'mt-2'>{props.children}</h4>
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default LoadingModal;
import React, { useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

// reactstrap
import { Table, Button } from "reactstrap";

import { toCode } from "../../../editor/glossary/assets/languageNames";

// contexts
import GlossaryContext from '../contexts/Glossary-context';

import { useMediaQuery } from "react-responsive";

const GlossaryFileList = (props) => {
  const gsCtx = useContext(GlossaryContext);
  const [gsList, setGsList] = useState([]);
  const history = useHistory();

  const isMobile = useMediaQuery({query: '(max-width: 760px)'});

  function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    
    return bytes.toFixed(dp) + units[u];
  }

  const glossaryItemRemoveHandler = (event) => {
    event.preventDefault();
    console.log(event.currentTarget.id);
    //props.removeItem(event)
  };

  const getFileOrCustom = (size) => {
    if (typeof(size) === 'number') {
      return '로컬 파일'
    } else {
      return '생성됨'
    }
  };

  const historyPush = (event, item) => {
    const region = event.target.getAttribute('name');
    
    const direction = `${toCode(props.sourceLang)}2${toCode(props.targetLang)}`
    region === 'clickable' && history.push({
      pathname: `/editGlossary/${direction}/${props.project_no}/${item.filename}`,
      state: {
        project_no: props.project_no,
        name: item.filename,
        direction: direction
      }
    })
  };

  useEffect(() => {
    gsCtx.items && setGsList(gsCtx.items);
  }, [gsCtx.items])

  useEffect(() => {
    if (gsCtx.searchKeyword != ''){
      const searchItems = gsCtx.items.filter((item) => 
        item.filename.includes(gsCtx.searchKeyword))
      setGsList(searchItems);
    }
    else {
      setGsList(gsCtx.items);
    }
  }, [gsCtx.searchKeyword])

  

  const TablePC = (props) => {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>번호</th>
            <th>이름</th>
            <th>작성자</th>
            <th>업로드일</th>
            <th style = {{'width':'2px'}}></th>
          </tr>
        </thead>
        <tbody>
          {gsList.map((item, index) => (
          <tr 
            key = {index} 
            style = {{'cursor':'pointer'}}
            onClick = {(event) => historyPush(event, item)}
          >
            <td name = 'clickable'>{index+1}</td>
            <td name = 'clickable'>
              {item.filename}
              ({getFileOrCustom(item.filesize)})
            </td>
            <td name = 'clickable'>{item.owner}</td>
            <td name = 'clickable'>{item.uploaded}</td>
            <td style = {{'width':'auto'}} align = 'right'>
              <Button 
                id = {item.index}
                outline color = 'secondary' 
                className = "ml-10 mr-10"
                style = {{
                    borderColor:'rgba(0,0,0,0)', 
                    cursor: props.currentUserId === item.owner_id
                      ?'pointer' :'not-allowed'
                  }} 
                onClick = {props.removeItem}
                disabled = {props.currentUserId !== item.owner_id}>
                <FontAwesomeIcon icon={faTrashAlt} size ='lg'/>
              </Button>
            </td>
          </tr>
          ))}
        </tbody>
      </Table>
    )
  };

  const TableMobile = (props) => {
    return (
      <div>
        {gsList.map((item, index) => (
          <div
            key = {index}
            style = {{width: '100%'}}
          >
            <Table className='m-1 p-2' style = {{
              width: '100%',
              borderBottom: '1px solid #5b5b5b',
              tableLayout: 'fixed'
            }}>
              <colgroup>
                <col width="30%"/>
                <col width="70%"/>
              </colgroup>              
              <tbody>
                <tr>
                  <th>번호</th>
                  <td>
                    {index + 1}
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td 
                    name = 'clickable'
                    onClick = {(event) => 
                      props.currentUserId === item.owner_id
                      ?historyPush(event, item)
                      :alert('권한이 없습니다.')}
                    style = {{wordBreak:'break-all'}}
                  >
                    <p className = 'font-weight-bolder' name="clickable">
                      {item.filename}
                      ({getFileOrCustom(item.filesize)})
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>작성자</th>
                  <td>
                    {item.owner}
                  </td>
                </tr>
                <tr>
                  <th>업로드일</th>
                  <td>
                    {item.uploaded}
                  </td>
                </tr>
                <tr>
                  <th>삭제</th>
                  <td>
                    <Button 
                      id = {item.index}
                      outline color = 'secondary' 
                      style = {{
                          borderColor:'rgba(0,0,0,0)', 
                        }} 
                      onClick = {props.removeItem}
                      disabled = {props.currentUserId !== item.owner_id}>
                      <FontAwesomeIcon icon={
                        props.currentUserId === item.owner_id
                        ?faTrashAlt
                        :faTimes
                        } 
                        size ='lg'/>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ))}
      </div>
    )
  }
  // console.log(gsList)
  return (
    <React.Fragment>
      {isMobile
        ?<TableMobile {...props} />
        :<TablePC {...props} />
      }
    </React.Fragment>
    
  )
};

export default GlossaryFileList;
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
// redux
// import { useSelector, useDispatch } from 'react-redux';

import { BACKEND } from "../../../../Constants";

// reactstrap
import { Container, Row } from "reactstrap";

// context
import DocumentContext from '../contexts/document-context';

// parts
import UploadFile from "./UploadFile";
import TopOptions from "./TopOptions";
import DocumentList from "./DocumentList";

// loading
import LoadingModal from "../../progress/LoadingModal";
import loadingGIF from '../../progress/backTranslation.gif';
// modify by kimhh 22.12.20~
// document tab attributes:
// 1. get file list
// 2. insert file
// 3. remove file
// 4. link to translation page
// 5. task separation to members
const DocumentTab = (props) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const setProgress = (action) => {
  //   dispatch({
  //       type: 'EDITOR DATA UPDATE',
  //       key: 'progressState',
  //       payload: action
  //   });
  // };
  const [loaded, setLoaded] = useState(true);
  
  const docCtx = useContext(DocumentContext);
  
  // get file list
  const getDocuments = async () => {
    setLoaded(false);
    const token = localStorage.getItem('token');
    const results = await axios.get(
      `${BACKEND.SERVER}/document/${props.project_no}/documents`, {
        headers: {
          "Authorization": `JWT ${token}`
          },
      }
    ).then((response)=>{
      const num = parseInt(response.data.length);
      if (num !== 0) {
        docCtx.setItems(response.data);
      }
      setLoaded(true);
    }).catch((error) => {
    });
  }

  // insert file : UploadFile
  // remove file : DocumentList/removeDocument
  
  useEffect(() => {
    //console.log(props.project_no);
    if (props.project_no !== undefined && props.project_no !== 'unknown'){
      props.isActive && getDocuments();
      // setProgress('ready')
    }  
  }, [props.isActive]);

  useEffect(() => {
    props.setDocuments(docCtx.items);
  }, [docCtx.items]);

  return (
    <Container>
      <LoadingModal 
        children = '불러오는중...'
        gif = {loadingGIF}
        show = {!loaded}
      />
      {props.availableEdit && <Row>
        <UploadFile 
          project_no = {props.project_no}
          directionSrc = {props.sourceLang}
          directionTgt = {props.targetLang}
        />
      </Row>}
      <TopOptions />
      <Row>
        <DocumentList 
          project_no = {props.project_no}
          availableEdit = {props.availableEdit}
          acceptance = {props.acceptance}
          directionSrc = {props.sourceLang}
          directionTgt = {props.targetLang}
        />
      </Row>
    </Container>
  );
};

export default DocumentTab;

import React, { useState, useReducer, useEffect } from "react";

// UI
import TextArea from "./TextArea";
// import CheckBox from "../UI/CheckBox";
// import CheckButton from "../UI/CheckButton";
import CheckSwitch from "../UI/CheckSwitch";
// import ToggleButton from "../UI/ToggleButton";
import SwitchButton from "../UI/SwitchButton";

// parts
import STSCheck from "./STSCheck";
import TmCheck from "./TmCheck";
import LineCheck from "./LineCheck";
import TextEditorTaps from "./TextEditor.taps";

// editor
import Editer from "../editer/Editer";

// wrapper
import Wrapper from "../helpers/Wrapper";

// react-bootstrap
import {
    Row, Accordion,
} from "react-bootstrap";

// font-awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

// UI item
import ToggleButton from '../UI/ToggleButton';
import { Edit, X } from 'react-feather';
import EditorDataContext from "../contexts/EditorDataContext";

// const editorReducer = (state, action) => {
//   if (action.type === "change form") {
//     if (state.formState === "textarea") {
//       return { formState: "webEditor" };
//     } else {
//       return { formState: "textarea" };
//     }
//   }
// };
const ModifyButton = ((props) => {
    return (
        <Row
            style={props.projectType
                ? (props.projectType === 'translation' && props.editorType === '검수')
                    ? { display: "none" }
                    : {}
                : {}}
            className="m-0 p-1 justify-content-center"
        >
            <ToggleButton
                id={props.index}
                variant='light'
                className={props.editorState === 'textarea'
                    ? 'border rounded-sm'
                    : 'b-0'}
                disabled={!props.grant}
                style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
                name={
                    props.grant
                        ? <Edit
                            color={
                                props.editorState === 'textarea'
                                    ? 'lightgray'
                                    : 'cornflowerblue'
                            }
                            height={15}
                            width={15}
                        />
                        : <X
                            color={'red'}
                            height={15}
                            width={15}
                        />
                }
                onClick={props.onEditorStateHandler}
            />
            <p
                className="ml-2 mb-0"
                style={{ alignSelf: 'center' }}
            >
                {'편집'}
            </p>
        </Row>
    )
})


const checkReducer = (state, action) => {
    if (action === "webEditor") {
        return { name: "checking", checkBool: false };
    } else {
        return { name: "checked", checkBool: true };
    }
};

const ParallelEditor = (props) => {
    // const icon = props.editorState === "textarea" ? faSortDown : faSortUp;

    return (
        <Wrapper>
            {/* <Card 
        // style={{ cursor: "pointer", 'boxShadow': '1px 1px rgba(120,120,120,0.5)' }}
        style={{ cursor: "pointer"}}
        id = {props.id}
        className = 'text-center m-0 b-0 p-0' 
        variant="outline-secondary" 
        size = 'sm' 
        onClick = {props.changeable?props.onEditorStateHandler :null}  
    >
        <Card.Body className = 'm-0 b-0 p-0 pb-0'>
            <FontAwesomeIcon icon = {icon} />
        </Card.Body>
      </Card> */}

            {props.editorState === "textarea" && (
                <TextArea
                    glossaryMatch={props.glossaryMatch}
                    glossaryMatch_key={props.glossaryMatch_key}
                    glossaryActive={props.glossaryActive}
                    setGlossaryActive={props.setGlossaryActive}

                    glossaryIndex={props.glossaryIndex}
                    modifiedOrNot={props.modifiedOrNot}

                    index={props.index}

                    id={props.id}
                    line={props.line}
                    styles={props.styles}
                    name={props.name}
                    onClickHandler={props.onEditorStateHandler || null}
                    changeable={props.changeable || null}
                    selected={props.selected || false}
                    onClick={props.onClick || null}
                    onlyInspection={props.onlyInspection}
                    editorType={props.editorType}
                    changeHandler={props.textAreaChangeHandler || null}
                    onMouseLeavehandler={props.webEditorOnMouseLeavehandler || null}
                />
            )}
            {props.editorState === "webEditor" && (
                <Editer
                    id={props.id}
                    data={props.line}
                    name={props.name}
                    fileName={props.fileName}
                    changeHandler={props.webEditorChangeHandler || null}
                    changeable={props.changeable || null}
                    selected={props.selected || false}
                    hasStyle={props.hasStyle || false}
                    onClick={props.onClick || null}
                    onSaveButton={props.onSaveButton || null}
                    onMouseLeavehandler={props.webEditorOnMouseLeavehandler || null}
                />
            )}
        </Wrapper>
    );
};

const TextEditor = (props) => {
    // form state & handler (not use(220101~))
    const [check, dispatchCheck] = useReducer(checkReducer, {
        name: "checked",
        checkBool: true,
    });

    const [selected, setSelected] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    // const [glossaryActive, setGlossaryActive] = useState([]);

    // init src, tgt
    const [src, setSrc] = useState({
        key: props.index,
        line: props.srcLine,
        styles: props.srcStyle
    });
    const [tgt, setTgt] = useState({
        key: props.index,
        line: props.tgtLine,
        styles: props.tgtStyle
    });

    const [matchScore, setMatchScore] = useState(-1);

    // right click menu
    const showNav = (event) => {
        event.preventDefault();
        props.setOnRightClickMenu(false);
        props.setXyPosition({
            x: event.clientX,
            y: event.clientY
        });
        props.onLineSelect(event, props.index);
        props.setOnRightClickMenu(true);
    };

    // object change by kimhh at 22.01.25
    // editor state change -> get line sts score
    const onCheckHandler = (event) => {
        event.preventDefault();
        props.getSTSScore(src.line, tgt.line, event.target.id);

        //dispatchCheck(props.editorState);
        // // editor state change
        //props.onEditorStateHandler(event);
    };

    // console.log(selected);
    const trColor = (onlyInspection, limitedOptions) => {
        if (selected && !onlyInspection && !limitedOptions) {
            return "rgba(85, 85, 85, 0.1)"
        } else {
            if (props.currentActive === props.index) {
                return "rgba(85, 85, 85, 0.12)"
            }

            if (mouseOver) {
                return "rgba(85, 85, 85, 0.05)"
            } else {
                return null
            }
        }
    };

    const webEditorChangeHandler = (string) => {
        // console.log(string)
        const line = {
            key: props.index,
            line: string,
        };
        props.saveToDB(line);
        setTgt(line);
    };
    const textAreaChangeHandler = (event) => {
        //// now use

        // const line = {
        //   key: props.index,
        //   line: event.target.value
        // };
        // props.saveToDB(line);
        // setTgt(line);
    };
    const webEditorOnMouseLeavehandler = (event) => {
        props.saveToDB(tgt);
    };

    const onClickHandler = (event) => {
        const region = event.target.getAttribute('name');
        region !== 'non-clickable' && setSelected(true);
    };

    const onSelect = (event, index) => {
        props.onLineSelect(event, index);
        props.setOnRightClickMenu(false);
    };

    const setGrant = () => {
        if (props.accessibility[0] === -1) {
            return false
        }
        if (props.accessibility.includes(props.ownerId)) {
            return false
        }
        return true
    };

    const changeLine = (tgt) => {
        props.changeLines && props.changeLines(tgt);

        return new Promise((resolve) => { resolve(true) })
    };

    // if the text was changed by parent.
    useEffect(() => {
        const line = {
            key: props.index,
            line: props.tgtLine,
            styles: props.tgtStyle
        };
        setTgt(line);
    }, [props.tgtLine]);

    // if the text was changed here.
    useEffect(() => {
        props.changeLines && props.changeLines(tgt);
    }, [tgt]);

    // useEffect(() => {
    //     dispatchCheck(props.editorState);
    // }, [props.editorState])

    useEffect(() => {
        const srcLine = {
            key: props.index,
            line: props.srcLine,
            styles: props.srcStyle
        };
        setSrc(srcLine);
        const tgtLine = {
            key: props.index,
            line: props.tgtLine,
            styles: props.tgtStyle
        };
        setTgt(tgtLine);
    }, []);

    return (
        <Wrapper>
            {props.ownerId === 'nonMember' && props.index > props.nonMemberLimitLine
                ? <tr>
                    <td>
                        <p style={{
                            color: 'transparent',
                            textShadow: '0 0 5px rgba(0,0,0,0.5)'
                        }}
                        > 로그인 이후 사용 해주세요.
                        </p>
                    </td>
                    <td>
                        <p style={{
                            color: 'transparent',
                            textShadow: '0 0 5px rgba(0,0,0,0.5)'
                        }}
                        > 로그인 이후 사용 해주세요.
                        </p>
                    </td>
                </tr>
                : <tr
                    style={{
                        backgroundColor: trColor(props.onlyInspection, props.limitedOptions),
                    }}
                    id={props.index}
                    onClick={(e) => onSelect(e, props.index)}
                    onMouseOver={() => setMouseOver(true)}
                    onMouseLeave={() => setMouseOver(false)}
                    onContextMenu={showNav}
                >
                    <td
                        className="b-0 p-1 align-middle text-center"
                        name='clickable'
                        style={{ width: '3%' }}
                    >
                        {props.lineNumber}
                    </td>
                    <td
                        className={src.line === undefined
                            ? "b-0 p-0 align-middle"
                            : "b-0 p-0 align-top"}
                        name='clickable'
                    >
                        <ParallelEditor
                            glossaryMatch={props.glossaryMatch}
                            glossaryMatch_key={'src'}
                            glossaryActive={props.glossaryActive}
                            setGlossaryActive={props.setGlossaryActive}

                            glossaryIndex={props.srcGlossary}
                            modifiedOrNot={false}

                            index={props.index}

                            id={src.key}
                            line={src.line}
                            styles={src.styles}
                            fileName={props.fileName}
                            name='clickable'
                            editorState={props.editorState}
                            onEditorStateHandler={null}
                            changeable={false}
                            hasStyle={props.hasStyle}
                            onClick={onClickHandler}
                            onlyInspection={props.onlyInspection}
                            onSaveButton={null}
                            editorType={props.editorType}
                        />
                    </td>
                    <td
                        className={tgt.line === undefined
                            ? "b-0 p-0 align-middle"
                            : "b-0 p-0 align-top"}
                        name='clickable'
                    >
                        <ParallelEditor
                            glossaryMatch={props.glossaryMatch}
                            glossaryMatch_key={'tgt'}
                            glossaryActive={props.glossaryActive}
                            setGlossaryActive={props.setGlossaryActive}

                            glossaryIndex={props.tgtGlossary}
                            modifiedOrNot={props.modifiedOrNot}

                            index={props.index}

                            id={tgt.key}
                            line={tgt.line}
                            styles={tgt.styles}
                            fileName={props.fileName}
                            name='clickable'
                            editorState={props.editorState}
                            onEditorStateHandler={props.onEditorStateHandler}
                            webEditorChangeHandler={webEditorChangeHandler}
                            webEditorOnMouseLeavehandler={webEditorOnMouseLeavehandler}
                            textAreaChangeHandler={textAreaChangeHandler}
                            changeable={!props.checkOrNot}
                            selected={selected}
                            hasStyle={props.hasStyle}
                            onClick={onClickHandler}
                            onlyInspection={props.onlyInspection}
                            onSaveButton={props.onSaveButton}
                            editorType={props.editorType}
                        />
                    </td>
                    <td
                        className="p-0 align-middle"
                        style={{
                            width: "9%",
                        }}
                        name='non-clickable'
                    >
                        {props.modifiedOrNot && (
                            <CheckSwitch
                                id={props.index}
                                label={"변경 감지"}
                                checked={props.modifiedOrNot}
                                style={{ display: "none" }}
                            />
                        )}
                        {!props.onlyInspection && <Row className="m-0 p-1 justify-content-center">
                            <TmCheck
                                index={props.index}
                                tmxmatch={props.tmxmatch}
                                changeLine={props.changeLine}
                                srcLine={src.line}
                                tgtLine={tgt.line}
                                highMatchScore={matchScore}
                                setMatchScore={setMatchScore}
                                disabled={setGrant()}
                            />
                        </Row>}
                        <Row className="m-0 p-1 justify-content-center">
                            <STSCheck
                                id={props.index}
                                onCheckHandler={onCheckHandler}
                                editorState={props.editorState}
                                score={props.score}
                                disabled={setGrant()}
                            />
                        </Row>

                    </td>
                    <td
                        className="p-0 align-middle"
                        style={{
                            width: "10%",
                        }}
                        name='non-clickable'
                    >
                        <ModifyButton
                            index={props.index}
                            projectType={props.projectType}
                            editorType={props.editorType}
                            editorState={props.editorState}
                            grant={!setGrant()}
                            disabled={props.disabled}
                            onEditorStateHandler={props.onEditorStateHandler}
                        />
                        {props.onCheckedOrNotHandler && <Row className="m-0 p-1 justify-content-center">
                            {/* {props.onCheckedOrNotHandler && (
              <CheckBox
                id={props.index}
                label="확인"
                checkOrNot={props.checkOrNot}
                onCheckHandler={props.onCheckedOrNotHandler}
              />
            )} */}
                            <LineCheck
                                id={props.index}
                                label='확인'
                                checkOrNot={props.checkOrNot}
                                onCheckHandler={props.onCheckedOrNotHandler}
                                disabled={!props.accessibility.includes(props.ownerId)}
                            />
                            <p
                                className="ml-2 mb-0"
                                style={{ alignSelf: 'center' }}
                            >
                                {'확인'}
                            </p>
                        </Row>}
                    </td>
                </tr>
            }
        </Wrapper>

    );
};

export default TextEditor;

import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";

import useInput from "./SignInput";

import { Button, Card, CardBody, FormGroup, Label } from "reactstrap";
import { useHistory } from "react-router-dom";

import { BACKEND } from "../../Constants";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");
const isPassword = (value) => value.trim() !== "";

const SignUp = () => {
    const history = useHistory();
    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [chkExistName, setChkExistName] = useState(false);
    const [chkExistEmail, setChkExistEmail] = useState(false);
    const [chkPwd, setChkPwd] = useState(false);
    const [nowName, setNowName] = useState("");
    const [nowEmail, setNowEmail] = useState("");

    const {
        value: nameValue,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName,
    } = useInput(isNotEmpty);
    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);
    const {
        value: passwordValue,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetPassword,
    } = useInput(isPassword);

    let formIsValid = false;

    if (nameIsValid && emailIsValid && passwordIsValid) {
        formIsValid = true;
    }

    // chk overlap name
    useEffect(() => {
        const identifier = setTimeout(() => {
            let data = {
                name: nameValue,
                _type: "CHK_NAME",
            };
            let url = "/chk-information";

            Axios.post(BACKEND.SERVER + url, data)
                .then((res) => {
                    var check = res.data;
                    if (check["name"] === null) {
                        setChkExistName(false);
                    } else {
                        setChkExistName(true);
                    }
                })
                .catch((res) => {
                    console.log("name error");
                    console.log(res.error);
                });
        }, 400);

        return () => {
            setNowName(nameValue);
            clearTimeout(identifier);
        };
    }, [nameValue]);

    // chk overlap email
    useEffect(() => {
        const identifier = setTimeout(() => {
            let data = {
                email: emailValue,
                _type: "CHK_EMAIL",
            };
            let url = "/chk-information";

            Axios.post(BACKEND.SERVER + url, data)
                .then((res) => {
                    var check = res.data;
                    if (check["email"] === null) {
                        setChkExistEmail(false);
                    } else {
                        setChkExistEmail(true);
                    }
                })
                .catch((res) => {
                    console.log("email error");
                    console.log(res.error);
                });
        }, 400);

        return () => {
            setNowEmail(emailValue);
            clearTimeout(identifier);
        };
    }, [emailValue]);

    const submitHandler = async (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        const enteredName = nameInputRef.current.value;
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        const nameRegex = /([^가-힣A-Za-z0-9\s\x20])/i;
        const passwordRegex =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;

        if (!passwordRegex.test(enteredPassword)) {
            alert(
                "패스워드는 숫자, 영문자, 특수문자 조합으로 8자리 이상 입력해주세요"
            );
            setChkPwd(true);
        } else if (!enteredName) {
            alert("닉네임이 입력되지 않았습니다.");
        } else if (!enteredEmail) {
            alert("이메일이 입력되지 않았습니다.");
        } else if (nameRegex.test(enteredName)) {
            resetName();
            alert("닉네임은 2자리 이상 한글 또는 숫자만 가능합니다.");
        } else if (chkExistName) {
            alert("이미 사용중인 닉네임입니다.");
        } else if (chkExistEmail) {
            alert("이미 사용중인 이메일입니다.");
        } else if (emailHasError) {
            alert("이메일주소가 올바르지 않습니다.");
        } else {

            let url = `${BACKEND.SERVER}/register`;
            let options = {
                method: "POST",
                url: url,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    email: enteredEmail,
                    name: enteredName,
                    password: enteredPassword,
                    chk: 0
                },
            };

            setIsLoading(true);
            await Axios(options
            ).then((response) => {
                // let data = response.data;
                alert('회원가입이 완료 되었습니다. 이메일에서 인증을 완료해주세요')
                history.push("/auth/sign-in");
                setIsLoading(false);
            }).catch((err) => {
                // let data2 = err.message;
                alert("등록하신 이메일은 이미 가입되어 있습니다");
                setIsLoading(false);
            })
        }
        // resetEmail();
        resetPassword();

    };

    const nameClasses = nameHasError ? "form-control invalid" : "form-control";
    const emailClasses = emailHasError ? "form-control invalid" : "form-control";
    const passwordClasses = passwordHasError ? "form-control invalid" : "form-control";

    return (
        <React.Fragment>
            <div className="text-center mt-4">
                <h1 className="h2">회원가입</h1>
                <p className="lead">당신은 이메일 하나로 놀라운 기능을 경험할 수 있습니다.</p>
            </div>

            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <form onSubmit={submitHandler}>
                            <FormGroup>
                                {/* <Label>이름</Label> */}
                                <input
                                    className={nameClasses}
                                    bssize="lg"
                                    type="text"
                                    id="name"
                                    value={nameValue}
                                    onChange={nameChangeHandler}
                                    onBlur={nameBlurHandler}
                                    placeholder="닉네임"
                                    required
                                    ref={nameInputRef}
                                    style={
                                        nameHasError || chkExistName ? { border: "", boxShadow: "0px 0px 3.5px 0.8px rgba(255,158,150,0.5)" } :
                                            nameValue && !chkExistName ? { border: "", boxShadow: "0px 0px 3.5px 0.8px rgba(104,158,100,0.5)" } :
                                                { boxShadow: "" }
                                    }
                                />
                                {/* {nameHasError && <p className="error-text">올바른 이름을 입력하세요.</p>} */}
                            </FormGroup>
                            <FormGroup>
                                {/* <Label>이메일 (ID로 사용)</Label> */}
                                <input
                                    className={emailClasses}
                                    bssize="lg"
                                    type="email"
                                    id="email"
                                    value={emailValue}
                                    onChange={emailChangeHandler}
                                    onBlur={emailBlurHandler}
                                    placeholder="이메일"
                                    required
                                    ref={emailInputRef}
                                    style={
                                        emailHasError || chkExistEmail ? { boxShadow: "0px 0px 3.5px 0.8px rgba(255,158,150,0.5)" } :
                                            emailValue && !chkExistEmail ? { boxShadow: "0px 0px 3.5px 0.8px rgba(104,158,100,0.5)" } :
                                                { boxShadow: "" }
                                    }
                                />
                                {/* {emailHasError && <p className="error-text">올바른 이메일을 입력하세요.</p>} */}
                            </FormGroup>
                            <FormGroup>
                                {/* <Label>비밀번호</Label> */}
                                <input
                                    className={passwordClasses}
                                    bssize="lg"
                                    type="password"
                                    id="password"
                                    value={passwordValue}
                                    onChange={passwordChangeHandler}
                                    onBlur={passwordBlurHandler}
                                    placeholder="패스워드"
                                    required
                                    ref={passwordInputRef}
                                    // style={
                                    //     !chkPwd
                                    //         ? { boxShadow: "" }
                                    //         : { boxShadow: "0px 0px 3.5px 0.8px rgba(244,158,150,0.5)" }
                                    // }
                                    style={
                                        passwordHasError || chkPwd ? { boxShadow: "0px 0px 3.5px 0.8px rgba(255,158,150,0.5)" } :
                                            passwordValue && !chkPwd ? { boxShadow: "0px 0px 3.5px 0.8px rgba(104,158,100,0.5)" } :
                                                { boxShadow: "" }
                                    }
                                />
                                <p
                                    style={
                                        !chkPwd
                                            ? { color: "gray", fontSize: "10px" }
                                            : { color: "red", fontSize: "10px" }
                                    }
                                >
                                    ※ 패스워드는 숫자, 영문자, 특수문자 조합으로 8자리 이상
                                    입력해주세요
                                </p>
                                {/* {passwordHasError && <p className="error-text">올바른 패스워드를 입력하세요.</p>} */}
                            </FormGroup>
                            <div className="text-center mt-3">
                                {!isLoading && (
                                    <Button color="primary" size="lg">
                                        가입하기
                                    </Button>
                                )}
                                {isLoading && <p>요청을 보냈습니다...</p>}
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default SignUp;

import React, { useCallback, useContext } from "react";

import { useDropzone } from 'react-dropzone'

import { Button, Card } from "react-bootstrap";
import { FilePlus } from 'react-feather'

import DocumentCotext from '../context/file-context';

// utils
import checktoValidFile from "../utils/checkValidExt";

// upload
import service from '../../../services/FileUploadService'

const UploadCard = (props) => {
    const fileCtx = useContext(DocumentCotext);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const email = userInfo ? userInfo.userEmail : null;
    const project_no = userInfo ? 'individual' : 'tempFiles'

    const asyncMap = async (files) => {
        Promise.all(files.map(async (file) =>
            service.upload(file, email, project_no, true, () => { }
            ).then((response) => {
                // console.log(response)
                if (response.data.status === 'duplicateFile') {
                    alert(response.data.message)
                } else {
                    // const newfile = 
                    //   userInfo
                    //   ? file 
                    //   : new File(
                    //   [file], response.data.filename, {type: file.type});
                    const newfile = {
                        project_no: project_no,
                        filename: response.data.filename,
                        filesize: file.size,
                        uploaded: new Date().toLocaleString() + '',
                        status: 'waiting',
                        progress: 0,
                        direction: 'unknown',
                        trans_percent: 0,
                        postEdit_percent: 0,
                        progress: 0,
                        isValid: {
                            src: 'invalid.init',
                            tgt: 'invalid.init'
                        },
                    };

                    fileCtx.addItem(newfile)
                }
            })
        )).then(() => {
            props.modalClose();
        });
    };

    const onDrop = useCallback(async acceptedFiles => {
        props.modalShow();
        // Do something with the files
        const files = checktoValidFile(acceptedFiles);
        asyncMap(files)

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    //console.log(fileCtx);
    return (
        <Card>
            <Card.Header>
                <div>
                    <h3>간편 번역</h3>
                    <p className="text-lg">
                        용어집, 번역메모리, 협업기능은 <b>&nbsp;프로젝트</b>에서 가능합니다.

                    </p>
                    <small><b>*지원 번역 언어:</b> 한국어 ↔ 영어, 일본어, 중국어</small>
                </div>
                {!userInfo &&
                    <Card bg='danger' text='light' className='pl-1 pt-1 m-0'>
                        <p className="text-lg">주의! 로그인 하지 않은 유저입니다.</p>
                        <p>
                            <li>일정 시간 이후 데이터는 파기됩니다.</li>
                            <li>AI 번역이 최대 10문장으로 제한됩니다.</li>
                        </p>
                    </Card>}
            </Card.Header>
            <Card.Body>
                <Card>
                    <div
                        {...getRootProps()}
                        style={{
                            'border': '2px dashed',
                            'textAlign': 'center'
                        }}>
                        <input {...getInputProps()} />
                        {isDragActive
                            ?
                            <>
                                <FilePlus size='40' className="mt-4 mb-4" />
                                <h5>드롭하여 파일 업로드</h5>
                            </>
                            :
                            <>
                                <FilePlus size='40' className="mt-4 mb-4" />
                                <h6>지원되는 파일: docx, pdf, srt, smi, txt</h6>
                                <h5>클릭 또는 드래그 앤 드롭하여 파일 업로드</h5>
                            </>
                        }
                    </div>
                </Card>
            </Card.Body>
        </Card>
    )
};

export default UploadCard;
import React, { useState } from 'react';

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import GlossaryList from './TextEditor.Taps/Glossary.list';
import TmxMatchList from './TextEditor.Taps/TmxMatch.list';
import OptionsTap from './TextEditor.Taps/Options';

const TextEditorTaps = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const NavLinkItem = ({children, activeKey}) => {
    return (
      <NavLink 
        className = 'pt-1 pb-1 pl-2 pr-2 m-1'
        active = {activeTab === activeKey}
        onClick={() => {toggle(activeKey);}}
        style = {activeTab === activeKey
        ?{
          color: '#3B82EC',
          backgroundColor:"rgba(85, 85, 85, 0.15)"
        }
        :{}}
      >
        {children}
      </NavLink>
    )
  };

  return (
    <div className="tab m-0 pl-3 pr-3">
      <Nav tabs>
        <NavItem>
          <NavLinkItem 
            activeKey = '1'
            >
            용어집
          </NavLinkItem>
        </NavItem>
        <NavItem>
          <NavLinkItem 
            activeKey = '2'
            >
            번역 메모리
          </NavLinkItem>
        </NavItem>
        <NavItem>
          <NavLinkItem 
            activeKey = '3'
            >
            옵션
          </NavLinkItem>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className = 'p-0'>
        <TabPane tabId="1">
          <GlossaryList 
            index = {props.index} 
            glossaryActive = {props.glossaryActive} 
            setGlossaryActive = {props.setGlossaryActive}
          />
        </TabPane>
        <TabPane tabId="2">
          <TmxMatchList 
            index = {props.index}
          />
        </TabPane>

        <TabPane tabId="3">
          <OptionsTap 
            index = {props.index}
            onChangeLine = {props.onChangeLine}
            saveToDB = {props.saveToDB}
          
          />
        </TabPane>

      </TabContent>
  </div>
  )
};

export default TextEditorTaps;


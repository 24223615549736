import React from "react";
// import { useMediaQuery } from "react-responsive";
import { Button } from "reactstrap";
import "./withdrawal-modal.scss";


function WithdrawalModal(props) {
    // const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const onCloseModal = (e) => {
        if (e.target === e.currentTarget) {
            props.closeModal();
        }
    };
    const modalHandler = () => {
        props.closeModal();
    };

    return (
        // <div className={!isMobile ? "with_modal__containers" : "with_modal_mobile__containers"} onClick={onCloseModal}>
        <div className="with_modal__containers" onClick={onCloseModal}>
            {/* <Button className={!isMobile ? "with_modal__close mb-10 float-left" : "with_modal_mobile__close mb-10 float-left"} onClick={modalHandler}> */}
            <Button className="with_modal__close mb-10 float-left" onClick={modalHandler}>
                X
            </Button>
            {/* <div className={!isMobile ? "with_modal__content" : "with_modal_mobile__content"}>{props.children}</div> */}
            <div className="with_modal__content">{props.children}</div>
        </div>
    );
}

export default WithdrawalModal;

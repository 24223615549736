import React from "react";

const TeamContext = React.createContext({
  project_no: null,
  team: [],
  
  initProject: (project_no) => {},
  initMember: (team) => {},

  insertMember: (payload) => {},
  deleteMember: (payload) => {},

  memberColorChange: (email, color) => {},
  
})

export default TeamContext;
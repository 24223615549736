import React, { useReducer } from 'react';

import 
  EditorDataContext,
  { environmentDataContext, resourceDataContext, stateDataContext,
  environmentData, resourceData, stateData} from './EditorDataContext';

const defaltDataState = {
  loginInfo: {},

  fileName: '',
  direction: '',
  domain: '',

  direction: 'ko2en',
  domain: 'normal',

  sourceSentence: [],
  targetSentence: [],
  firstTargetSentence: [],
  targetSentenceAccessibility: [],

  sourceStyleDict: [],
  targetStyleDict: [],

  glossaryMatchedIndex: [[], []],

  tmxMatch: [],
  glossaryMatch: [],

  modifiedOrNotDict: {},
  checkedOrNotDict: {},
  editorStates: {},
  stsScoreDict: {},

  loadedSrcLanguage: false,

  linesNumber: 0, // top option에 표기될 빈칸('')을 제외한 라인 개수
  accumulatedNumber: 0, // 빈칸('')을 제외한 누적 라인 개수
  progressState: {
    'processing': false,
    'file reading': false,
    'translation': false,
    'sts checking': false,
    'tm checking': false,
    
    'etc processing': null,
    'point': 0},
  fetchedLineNumber: 0, // 현재 페칭된 라인 개수
  completion: false,

  currentActive: 0,
  glossaryData: null,
  glossaryActive: [],

  trans_count: 0,
  postEdit_count: 0,
  nonMemberLimitLine: 10,
};

const DataReducer = (state, action) => {
  switch(action.type){
    case 'EDITOR DATA UPDATE':{
      return {
        ...state,
        [action.key]: action.payload
      }
    };

    case 'EDITOR DATA INITIALIZE':{
      return {
        ...state,
        fileName: '',
        direction: '',
        domain: '',

        direction: 'ko2en',
        domain: 'normal',

        sourceSentence: [],
        targetSentence: [],
        firstTargetSentence: [],
        targetSentenceAccessibility: [],

        sourceStyleDict: [],
        targetStyleDict: [],

        glossaryMatchedIndex: [[],[]],

        tmxMatch: [],
        glossaryMatch: [],

        modifiedOrNotDict: {},
        checkedOrNotDict: {},
        editorStates: {},
        stsScoreDict: {},

        loadedSrcLanguage: false,

        linesNumber: 0, // top option에 표기될 빈칸('')을 제외한 라인 개수
        accumulatedNumber: 0, // 빈칸('')을 제외한 누적 라인 개수
        progressState: {
          'processing': false,
          'file reading': false,
          'translation': false,
          'sts checking': false,
          'tm checking': false,
          
          'etc processing': null,
          'point': 0},
        fetchedLineNumber: 0, // 현재 페칭된 라인 개수
        completion: false,

        currentActive: null,
        glossaryData: null,
        glossaryActive: [],
        nonMemberLimitLine: 10,
        };
    };

    case 'EDITOR DATA LIST APPEND':{
      const item  = action.payload;
      const items = state[action.key];
      items.push(item);
      return {
        ...state,
        [action.key]: items
      }

    };

    case 'EDITOR DATA LIST EXTEND':{
      const item  = action.payload;
      const items = state[action.key];
      items.push(...item);
      return {
        ...state,
        [action.key]: items
      }

    };

    case 'GLOSSARY DATA LIST EXTEND':{
      const item  = action.payload;
      const items = state[action.key];
      items[0].push(...item[0]);
      items[1].push(...item[1]);
      return {
        ...state,
        [action.key]: items
      }
    };

    case 'EDITOR DATA DICT APPEND':{
      const item  = action.payload;
      const items = {
        ...state[action.key],
        ...item
      }
      return {
        ...state,
        [action.key]: items
      }
    };

    case 'EDITOR DATA DICT SET ITEM':{
      const item = action.payload;
      const dict = {
        ...state[action.key],
        [action.index]: item
      }
      return {
        ...state,
        [action.key]: dict
      }
    };

    case 'EDITOR DATA FILE READ':{
      const item = action.payload;
      const modifiedOrNotDict = {
        ...state['modifiedOrNotDict'],
        ...item.modifiedOrNotDict
      };
      const checkedOrNotDict = {
        ...state['checkedOrNotDict'],
        ...item.checkedOrNotDict
      };
      const editorStates = {
        ...state['editorStates'],
        ...item.editorStates
      };

      const sourceSentence = state['sourceSentence'];
      sourceSentence.push(...item.sourceSentence);

      const fetchedLineNumber = state.fetchedLineNumber;
      const accumulatedNumber = state.accumulatedNumber;
      const trans_count       = state.trans_count;
      const postEdit_count    = state.postEdit_count;
      
      return {
        ...state,
        modifiedOrNotDict: modifiedOrNotDict,
        checkedOrNotDict: checkedOrNotDict,
        editorStates: editorStates,
        sourceSentence: sourceSentence,
        fetchedLineNumber: fetchedLineNumber + item.sourceSentence.length,
        accumulatedNumber: accumulatedNumber + item.accumulatedNumber,
        completion: item.maxLinesNumber === state.fetchedLineNumber,
        trans_count: item.trans_count? item.trans_count : trans_count,
        postEdit_count: item.postEdit_count? item.postEdit_count : postEdit_count
      }
    };
    case 'EDITOR DATA TARGET INIT': {
      const editorStates = state.editorStates;
      Object.keys(editorStates).map((key) => {
        Object.assign(editorStates, {[key]: 'textarea'})
      });
      
      return {
        ...state,
        editorStates: editorStates,
        checkedOrNotDict: {},
        targetSentence: [],
        firstTargetSentence: [],
        glossaryMatch: [],
        glossaryMatchedIndex: [[], []],
        targetSentenceAccessibility: [],
        stsScoreDict: {},
        trans_count: 0,
        postEdit_count: 0
      }
    };

  }
};

const EditorDataProvider = (props) => {
  const [state, dispatchState] = useReducer(
    DataReducer, defaltDataState
  );

  const editorDataUpdate = (action) => {
    dispatchState({
      type:'EDITOR DATA UPDATE',
      ...action
    })
  };
  const editorDataInit = () => {
    dispatchState({
      type:'EDITOR DATA INITIALIZE' 
    })
  };
  const editorDataListAppend = (action) => {
    dispatchState({
      type:'EDITOR DATA LIST APPEND',
      ...action
    })
  };
  const editorDataListExtend = (action) => {
    dispatchState({
      type:'EDITOR DATA LIST EXTEND',
      ...action
    })
  };
  const editorDataDictAppend = (action) => {
    dispatchState({
      type:'EDITOR DATA DICT APPEND',
      ...action
    })
  };
  const edtiorDataFileRead = (action) => {
    dispatchState({
      type:'EDITOR DATA FILE READ',
      ...action
    })
  };
  const editorDataTargetInit = () => {
    dispatchState({
      type:'EDITOR DATA TARGET INIT'
    })
  };
  
  const dataContext = {
    ...state,
    
    editorDataUpdate,
    editorDataInit,
    editorDataListAppend,
    editorDataListExtend,
    editorDataDictAppend,
    edtiorDataFileRead,
    
    dispatch: dispatchState,

    editorDataTargetInit: editorDataTargetInit,
  };

  return (
    <EditorDataContext.Provider value = {dataContext}>
      {props.children}
    </EditorDataContext.Provider>
  )
};

export default EditorDataProvider;
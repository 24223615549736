import React, { useContext } from "react";

import EditorDataContext from "../../contexts/EditorDataContext";
import { Edit, Edit2, Edit3 } from 'react-feather';

import classes from './lightButtonMenu.module.css';

const RightContext = (props) => {
  const edCtx = useContext(EditorDataContext);

  const onClickReplace2Source = (event) => {
    edCtx.currentActive &&
    !edCtx.checkedOrNotDict[edCtx.currentActive] &&
    props.onChangeLine(
      edCtx.currentActive, 
      edCtx.sourceSentence[edCtx.currentActive],
      null,
      false);
    props.saveToDB({
      key: edCtx.currentActive,
      line: edCtx.sourceSentence[edCtx.currentActive]
    });
    props.setOnRightClickMenu(false);
  };

  return (
    <div 
      className = 'p-1'
      style = {
      {
        position:'fixed',
        zIndex: 1,
        border: '1px solid gray',
        background: 'white',
        top: props.xYPosition.y,
        left: props.xYPosition.x,
      }}
    >
      <div
        class = {classes.menu}
        id = 'option-1'
        onClick={onClickReplace2Source}
      >
        <Edit3 
          height = {20}
          width = {20}
          className = 'pr-1'
        />원문으로 대체
      </div>
      {/* <div
        class = {classes.menu}
        id = 'option-2'
      >
        <Edit
          height = {20}
          width = {20}
          className = 'pr-1'
        />옵션1
      </div>
      <div
        class = {classes.menu}
        id = 'option-3'
      >
        <Edit2 
          height = {20}
          width = {20}
          className = 'pr-1'
        />옵션1
      </div> */}
    </div>
  )
};

export default RightContext;
import axios from "axios";
import React, { useState, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Button,
    Card,
    Input,
    Row, Col,
} from "reactstrap";
import { Modal, ListGroup, InputGroup } from 'react-bootstrap';

// import AddTeamArea from "./AddTeamArea";
import TeamContext from '../contexts/TeamContext';

import { BACKEND } from "../../../../Constants";

const COLOR_DUMMY = [
    'rgb(51, 51, 255)',
    'rgb(51, 255, 255)',
    'rgb(51, 255, 51)',
    'rgb(153, 255, 51)',
    'rgb(255, 255, 51)',
    'rgb(255, 153, 51)',
    'rgb(255, 51, 51)',
    'rgb(255, 51, 153)',
    'rgb(255, 51, 255)',
    'rgb(153, 51, 255)'
];

function createRoomName(id1, id2) {
    if (id1 > id2) {
        // swap two values
        let temp = id2;
        id2 = id1;
        id1 = temp;
    }
    return id1 + '|' + id2;
}

const AddTeam = (props) => {
    const { project_no } = props;
    const [email, setEmail] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [searchingList, setSearchingList] = useState([]);

    const handleClose = () => props.setShow(false);
    const handleShow = () => props.setShow(true);

    const teamContext = useContext(TeamContext);

    const onEmailChange = e => {
        const letters = e.target.value;
        setInputValue(letters);
        setEmail(letters);
        if (letters !== '') {
            const token = localStorage.getItem('token');
            axios.get(`${BACKEND.SERVER}/team/getSearching/${letters}`,
                {
                    headers: {
                        "Authorization": `JWT ${token}`
                    },
                }).then((response) => {
                    // console.log(response)
                    setSearchingList(response.data)
                });
        }
    };

    const onUserClick = (name, email) => {
        setInputValue(name);
        setEmail(email);
    };

    const sender_email = localStorage.getItem('useremail');
    // console.log("sender", typeof sender_email)

    const requestSubmit = () => {
        const checkEmail = validateEmail(email);
        if (checkEmail) {
            const token = localStorage.getItem('token');

            const teamNumber = teamContext.team.length;
            const personalColorI = teamNumber % 10;

            const data = {
                project_no: teamContext.project_no,
                user_email: email,
                personal_color: COLOR_DUMMY[personalColorI]
            };

            // axios.post("http://175.125.94.218:5001/team", data, {
            axios.put(`${BACKEND.SERVER}/team`, data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            }).then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                    console.log("addTeam.js invite1 ::: ", res.data)
                } else if (res.statusText === "OK") {
                    alert(res.data.message);
                    console.log("addTeam.js invite2 ::: ", res.data)
                    if (res.data.new_member) {
                        const newMember = res.data.new_member;
                        const payload = {
                            name: newMember.name,
                            email: newMember.email,
                            color: newMember.color,
                            role: 'member',
                            acceptance: 'waiting'
                        }
                        teamContext.insertMember(payload);
                        MailSend();
                        var date = new Date();
                        date = date.getFullYear() + '-' +
                            ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
                            ('00' + date.getDate()).slice(-2) + ' ' +
                            ('00' + date.getHours()).slice(-2) + ':' +
                            ('00' + date.getMinutes()).slice(-2) + ':' +
                            ('00' + date.getSeconds()).slice(-2);

                        const sender_email = localStorage.getItem('useremail');
                        const sender_name = localStorage.getItem('username');
                        if (!sender_email || !sender_name) {
                            alert("재 로그인이 필요합니다.")
                        }
                        let room = null
                        if (newMember.email != sender_email) {
                            room = createRoomName(newMember.email, sender_email);
                        }
                        let newproposal = {
                            "recipient_email": newMember.email,
                            "recipient_name:": newMember.name,
                            "member_email": sender_email,
                            "member_name": sender_name,
                            "comment": 'invite',
                            "thread": 'pjt',
                            "ref": 0,
                            "no": Number(teamContext.project_no),
                            // "fid": added.fid,
                            // "pid": added.pid,
                            "query": String(teamContext.project_no),
                            "created_datetime": date,
                            "room": room,
                        }
                        console.log("Added members completed")
                        props.socket.emit("invite_member", newproposal);
                    }
                    // history.push("/projects");
                };
                // console.log("requests: ", res.data);
            }).catch((err) => {
                console.log(err);
            });
        }
        else {
            alert('올바른 이메일 형식이 아닙니다.')
        }
    };

    const MailSend = (props) => {
        const token = localStorage.getItem('token');
        const info = localStorage.getItem('userInfo')
        const sender = info ? JSON.parse(info).userEmail : 'unknown';
        const data = new FormData()
        data.append('sender', sender)
        data.append('recipient', email)
        data.append('project_no', project_no)


        axios.post(`${BACKEND.SERVER}/teamRequest`, data, {
            headers: {
                "Authorization": `JWT ${token}`
            }
        }).then((response) => {
            // alert(response.data.message)
        })
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            style={{
                'top': '20%'
            }}
        >
            <Modal.Body>
                <Row className='ml-3 mb-1'>
                    <Col>
                        <h5>이메일 전송</h5>
                    </Col>
                    <Col style={{ 'textAlign': 'right' }}>
                        <Button
                            className='float-right'
                            color="dark"
                            outline
                            style={{ 'border': 0 }}
                            size="sm"
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Col>
                </Row>
                <Row className='ml-3 mb-3'>
                    <Col>
                        <h6 className="card-subtitle text-muted">프로젝트 참여자를 초대하세요</h6>
                    </Col>
                </Row>
                <InputGroup className="mb-3">
                    <Input
                        type="email"
                        name="email"
                        placeholder="닉네임 또는 이메일 검색"
                        value={inputValue}
                        onChange={onEmailChange}
                    />
                    <Button
                        type="submit"
                        color="primary"
                        onClick={requestSubmit}>
                        전송
          </Button>
                </InputGroup>
                <Card>
                    <ListGroup
                        style={{
                            'maxHeight': '300px',
                            'overflow': 'auto'
                        }}
                    >
                        {searchingList.map((value, key) => {
                            return (
                                searchingList.length > 0 && <ListGroup.Item
                                    className='pt-2 pb-2'
                                    key={key}
                                    eventKey={key}
                                    onClick={() => onUserClick(value.name, value.email)}
                                >{value.name}</ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Card>
            </Modal.Body>
        </Modal>
    );
};

export default AddTeam;

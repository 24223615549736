import React, { useContext } from "react";

// react-bootstrap
import { Table } from 'react-bootstrap';

// UI
import ModalScreen from "../UI/ModalScreen"

// sunEditor
import SunEditor from 'suneditor-react';

//helper
import Wrapper from "../helpers/Wrapper";

// redux
// import { useSelector } from 'react-redux';

import EditorDataContext from "../contexts/EditorDataContext";

const getDiffTextStyle = (text, i) => {
  if (text.slice(0,8) === '<source>') {
    return <font
      style = {{'backgroundColor': '#81E697'}} 
      key = {i}>{`${text.slice(8, -9)} `}</font>
  } 
  else if (text.slice(0,8) === '<target>') {
    return <font
      style = {{'backgroundColor': '#E6C181'}} 
      key = {i}><s>{`${text.slice(8, -9)} `}</s></font>
  }
  else if (text.slice(-1) === '.') {
    return <font key = {i}>{`${text}`}</font>
  }
  else {
    return <font key = {i}>{`${text} `}</font>
  }
};

const setDiffTextStyle = (text) => {
  if (text !== undefined){
    const text_list = text.split(' ');
    return (
      <Wrapper>
        {text_list.map((word, i) => (
            getDiffTextStyle(word, i)
        ))}
      </Wrapper>
    )   
  }
};

const TMCheckList = (props) => {
  //console.log(props.tmxmatch);
  // const sourceSentence = useSelector((state) => state.editorData.sourceSentence);
  // const targetSentence = useSelector((state) => state.editorData.firstTargetSentence);
  const edCtx = useContext(EditorDataContext);
  const sourceSentence = edCtx.sourceSentence;
  const targetSentence = edCtx.firstTargetSentence;

  const textIndex = props.tmxmatch[0]['source_i']-1;

  // console.log(props.tmxmatch)
  // console.log(targetSentence)
  // console.log(targetSentence[props.tmxmatch[0]['source_i']-1])
  
  const checkList = (
    <Table striped bordered hover size="sm">
      <tbody>
        {props.tmxmatch.map((item, index) => (
        <tr 
          id = {index} 
          key = {index} 
          onClick={(event) => props.selectList(index, true)}
          style = {{cursor: props.disabled? 'not-allowed' :'pointer'}}
        >
          <td>
            {// not used now.
            false && <SunEditor 
              setOptions={{
                mode: 'balloon-always',
                buttonList: [],
                toolbarWidth:0}}
              disable = {true}
              setContents={item.diff_text[0]}
            />}
            {setDiffTextStyle(item.diff_text[0])}
          </td>
          <td>
            {item.tmx_target[0]}
          </td>
          <td>{`TM ${item.score[0]}%`}</td>
        </tr>
        ))}
        {(props.tmxmatch.length > 0 && targetSentence.length >= textIndex) && <tr 
          id = {textIndex}
          onClick={(event) => props.selectList(
            textIndex, false
          )}
        >
          <td>
            {sourceSentence[textIndex] && sourceSentence[textIndex].replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, '').replace(/&nbsp;/g, ' ')}
          </td>
          <td>
            {targetSentence[textIndex] && targetSentence[textIndex].replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, '').replace(/&nbsp;/g, ' ')}
          </td>
          <td>
            MT
          </td>
        </tr>}
      </tbody>
    </Table>)
      
  return (
    <ModalScreen 
      show = {props.show}
      handleClose = {props.handleClose}    
      children = {checkList} />
  )
};

export default TMCheckList;
export { setDiffTextStyle };
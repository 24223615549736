import React, { useReducer } from "react";

import GlossaryContext from "./Glossary-context";

const defaultGlossaryState = {
    items: [],
    searchKeyword: ''
};

const GlossaryReducer = (state, action) => {
  if (action.type === 'add') {
    const maxIndex = state.items.length !== 0 
      ?state.items[state.items.length-1].index
      :0;
    const item = {
      'key': maxIndex+1,
      'index': maxIndex+1,
      ...action.item
    }
    
    const updatedItems = state.items.concat(item);
    //console.log(action.item);
    return {
      ...state,
      items: updatedItems
    }   
  }
  else if (action.type === 'remove') {
    const removedItems = state.items.filter(
      item => item.index != action.index
        
    );
    return {
      ...state,
      items: removedItems
    }
  }
  else if (action.type === 'setItems') {
    return {...state, items: action.items}
  }
  else if (action.type == 'search') {
    return {...state, searchKeyword: action.keyword}
  }
};

const GlossaryProvider = (props) =>{
  const [glossaryState, dispatchGlossaryState] = useReducer(
    GlossaryReducer, defaultGlossaryState);
  
  const setGlossaryItems = (items) => {
    dispatchGlossaryState({type: 'setItems', items: items});
  };
  const addGlossaryFile = (item) => {
    dispatchGlossaryState({type: 'add', item: item});
  };
  const removeGlossaryFile = (index) => {
    dispatchGlossaryState({type: 'remove', index: index});
  };
  const searchGlossary = (keyword) => {
    dispatchGlossaryState({type: 'search', keyword: keyword});
  }
  
  const glossaryContext = {
    items: glossaryState.items,
    searchKeyword: glossaryState.searchKeyword,
    
    setItems: setGlossaryItems,
    addItem: addGlossaryFile,
    removeItem: removeGlossaryFile,
    search: searchGlossary, 
  };

  return (
    <GlossaryContext.Provider value = {glossaryContext}>
      {props.children}
    </GlossaryContext.Provider>
  )
}

export default GlossaryProvider;

// toggle button for reactstrap 4.5.2 version
import React, { useState, useReducer } from "react";

// react-bootstrap
import { Button } from "react-bootstrap";

const ToggleButton = (props) => {
    //const [checked, setChecked] = useState(true);
    //const [buttonName, setButtonName] = useState('Checked')

    return (
        <Button
            onClick={props.onClick}
            className = {props.className || 'mb-1 border border-secondary'}
            id = {props.id}
            value = {props.id}
            style = {props.style || {}}
            variant = {props.variant || `outline-primary`}
            size = {props.size || 'sm'}
            disabled = {props.disabled || null}
            >
            {props.name || 'test'}
        </Button>
    )
};

export default ToggleButton;
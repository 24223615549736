import http from "../../../services/http-common";

import { BACKEND } from "../../../Constants";

const CheckTMX = (source, project_no, fetchedLineNumber = 0) => {
    // const data = {
    //     'source': source,
    //     'project_no': project_no
    // };
    //console.log(data);
    const data = new FormData();
    data.append('source', JSON.stringify(source.slice()));
    data.append('project_no', project_no);
    data.append('fetchedLineNumber', fetchedLineNumber);
    const promise = new Promise(async (resolve) => {
        await http.post(
            `${BACKEND.SERVER}/matchingTMX`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                  }
            }
        ).then((response) => {
            resolve(response.data);
        })
    });
    return promise
}

export default CheckTMX;

import React, { useState } from 'react';

// react-bootstrap
import {Col, Row, Card, Button, Modal} from 'react-bootstrap';

const ModalScreen = (props) => {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
        <Modal size = 'xl' show = {props.show} onHide={props.handleClose}>
            <Modal.Header>
                <Modal.Title>TM memory</Modal.Title>
            </Modal.Header>
            <Modal.Body className = 'p-0'>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalScreen;

import React from "react";
import axios from "axios";
import { useHistory } from "react-router";

import {
  Badge,
  Card,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  UncontrolledDropdown,
} from "reactstrap";

import { MoreHorizontal } from "react-feather";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BACKEND } from "../../../../Constants";

const ProjectsList = ({
  projects,
  currentPage,
  deleteProject,
  projectType,
}) => {
  const history = useHistory();
  // 프로젝트 삭제 요청
  const handleDelete = async (no) => {
    const token = localStorage.getItem("token");
    //  console.log("delete: ", no, token);
    const results = await axios.delete(
      `${BACKEND.SERVER}/project/${no}`,
      { no: "" },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
    if (results.data.ok === "ok") {
      alert("프로젝트가 삭제되었습니다.");
      deleteProject();
    } else {
      alert(results.data.message);
    }
  };

  const editProject = (event, state) => {
    const region = event.target.getAttribute("name");

    region === "line2edit" &&
      history.push({
        pathname: `/projects/edit/${state.no}`,
        state,
      });
  };

  return (
    <Card className="flex-fill">
      <Table striped hover>
        <thead>
          <tr>
            <th>번호</th>
            <th>프로젝트 이름</th>
            <th>참여자</th>
            <th>진행률</th>
            <th>상태</th>
            <th>언어</th>
            <th>담당자</th>
            <th>만든 날짜</th>
            <th>마감일</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {projects.map((data, index) => {
            let trans_state = "진행중";
            let trans_color = "warning";
            if (data.trans_percent === "0") {
              trans_state = "대기중";
              trans_color = "secondary";
            } else if (data.trans_percent === "100") {
              trans_state = "완료";
              trans_color = "success";
            }

            let postEdit_state = "진행중";
            let postEdit_color = "warning";
            if (data.postEdit_percent === "0") {
              postEdit_state = "대기중";
              postEdit_color = "secondary";
            } else if (data.postEdit_percent === "100") {
              postEdit_state = "완료";
              postEdit_color = "success";
            }

            return (
              <tr 
                key={data.no} 
                onClick={(event) => editProject(event,
                {
                  no: data.no,
                  title: data.title,
                  description: data.description,
                  sourceLang: data.source_language,
                  targetLang: data.target_language,
                  deadline: data.deadline,
                  currentUserRole: data.role,
                  acceptance: data.acceptance,
                  projectType: data.type
                })}
                style = {{cursor: 'pointer'}}
              >
                <td name = 'line2edit'>{index + (currentPage - 1) * 12 + 1}</td>
                <td name = 'line2edit' className="text-truncate">{data.title}</td>
                <td name = 'line2edit'>
                  {data.user_names[0]} 외 {data.user_names.length - 1}명
                </td>
                <td name = 'line2edit'>
                  <p className="mb-1 font-weight-bold">
                    {projectType === "translation" ? "번역" : "검수"}
                    <span className="float-right">
                      {projectType === "translation"
                        ? data.trans_percent.toFixed(2)
                        : data.postEdit_percent.toFixed(2)}
                      %
                    </span>
                  </p>
                  <Progress
                    className="mb-2"
                    value={
                      projectType === "translation"
                        ? data.trans_percent
                        : data.postEdit_percent
                    }
                  />
                </td>
                <td name = 'line2edit'>
                  <Badge
                    className="my-2"
                    color={projectType ? trans_color : postEdit_color}
                  >
                    {projectType ? trans_state : postEdit_state}
                  </Badge>
                </td>
                <td name = 'line2edit'>
                  {`${data.source_language}  `}
                  <FontAwesomeIcon icon={faChevronRight} />
                  {`  ${data.target_language}`}
                </td>
                <td name = 'line2edit'>{data.manager_name}</td>
                <td name = 'line2edit'>{data.created_datetime.split(" ")[0]}</td>
                <td name = 'line2edit'>{data.deadline.split(" ")[0]}</td>
                <td>
                  <div className="card-actions">
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a">
                        <MoreHorizontal />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {/* <DropdownItem>즐겨찾기 추가</DropdownItem>
                          <DropdownItem onClick={() =>
                            history.push({
                              pathname: `/projects/edit/${data.no}`,
                              state: {
                                no: data.no,
                                title: data.title,
                                description: data.description,
                                sourceLang: data.source_language,
                                targetLang: data.target_language,
                                deadline: data.deadline,
                              },
                            })
                          }>
                              프로젝트 설정
                          </DropdownItem> */}
                        <DropdownItem
                          onClick={() => {
                            handleDelete(data.no);
                          }}
                        >
                          프로젝트 삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

export default ProjectsList;

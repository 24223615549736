import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import FileUploadService from '../../../../services/FileUploadService'

// contexts
import GlossaryContext from '../contexts/Glossary-context';

// reactstrap
import { Table, Container, Row } from "reactstrap";

import { BACKEND } from "../../../../Constants";

//parts
import UploadFile from "./UploadFile";
import TopOptions from "./TopOption";
import GsList from "./GlossaryList";

// loading
import LoadingModal from "../../progress/LoadingModal";
import loadingGIF from '../../progress/backTranslation.gif';

const GlossaryTab = (props) => {
    const gsCtx = useContext(GlossaryContext);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const email = userInfo? userInfo.userEmail :null;
    const userUid = userInfo? userInfo.userUid :null;
    const history = useHistory();

    const [loaded, setLoaded] = useState(true);

    const getGsFiles = async () => {
      setLoaded(false);
      const token = localStorage.getItem('token');
      const results = await axios.get(
        `${BACKEND.SERVER}/document/${props.project_no}/gs`, {
          headers: {
            "Authorization": `JWT ${token}`
          },
        }
      ).then((response)=> {
        const num = parseInt(response.data.length);
        if (num > 0) {
          props.setGlossary(response.data);
          gsCtx.setItems(response.data);
        }
        setLoaded(true);        
      });

    };

    const getDateTime = () => {
      const today = new Date();
      const year  = String(today.getFullYear());
      const month = ("0" + today.getMonth()).slice(-2);
      const date  = ("0" + today.getDate()).slice(-2);
      
      const hour   = ("0" + today.getHours()).slice(-2);
      const minute = ("0" + today.getMinutes()).slice(-2);
      const second = ("0" + today.getSeconds()).slice(-2);
      
      const times = `${hour}:${minute}:${second}`
      return [`${year}-${month}-${date}`, times]
    };

    const gsItemAddHandler = (file, tempFileName) => {
      const [date, times] = getDateTime();
      const item = {
        "project_no": String(props.project_no), 
        "filename" : file.name,
        "filesize" : file.size,
        'owner': email,
        'owner_id': userUid,
        "uploaded" : `${date} ${times}`,
        "randomCreateName": tempFileName
      }
      //console.log(item);
      gsCtx.addItem({...item});
    };

    const gsItemRemoveHandler = (event) => {
      event.preventDefault();
      const index = event.currentTarget.id;
      const removeIndex = gsCtx.items.findIndex((item) => 
        item.index == index);

      if (props.project_no !== undefined) {
        if (props.project_no !== 'unknown') {
          const token = localStorage.getItem('token');
          FileUploadService.deleteDocument(
            props.project_no, 
            email,
            gsCtx.items[removeIndex].filename, 
            'glossary_files',
            token
          ).then((response) => {
            // console.log(response);
            gsCtx.removeItem(index);
          })
        }
      }
    };

    // useEffect(() => {
    //     props.setTmxFiles(tmxCtx.items);
    // }, [tmxCtx.items])


    useEffect(() => {
      !userUid && history.push('/unauthorized');
    }, [userUid]);

    useEffect(() => {
      //console.log(props.project_no);
	    if (props.project_no !== undefined && props.project_no !== 'unknown'){
        props.isActive && getGsFiles();
      }  
    }, [props.isActive]);

    // console.log(gsCtx);

    return (
      <Container>
        <LoadingModal 
          children = '불러오는중...'
          gif = {loadingGIF}
          show = {!loaded}
        />
        <Row>
          {props.availableEdit && <UploadFile 
            project_no = {props.project_no}
            addItem = {gsItemAddHandler}  
          />}
        </Row>
        <TopOptions 
          project_no = {props.project_no} 
          getDateTime = {getDateTime}
        />
        <Row>
          <GsList 
            removeItem = {gsItemRemoveHandler}
            project_no = {props.project_no} 
            currentUserId = {userUid}
            sourceLang = {props.sourceLang}
            targetLang = {props.targetLang} />
        </Row>
      </Container>
    )
}

export default GlossaryTab;
import React, { useContext } from "react";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Card, Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";

import AssignmentContext from "../contexts/assignment-context";

const Header = (props) => {
  const assignCtx = useContext(AssignmentContext);
  const history = useHistory();
  
  return (
    <Card.Header>
      <Card.Title tag="h5">
        <Button 
          variant = 'light' 
          onClick={()=> {
            history.goBack()
          }}
        >
          <FontAwesomeIcon 
            id="left" 
            icon={faChevronLeft} 
            />&nbsp;
            {'프로젝트 설정'}
        </Button>
      </Card.Title>
      <div>
        <h4 className="card-subtitle">
          {props.filename}
        </h4>
      </div>
      {/* <div className="progress">
        <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={0}
            aria-valuemin="0"
            aria-valuemax={10}
            style={{ width: 10 + "%" }}
        >{(assignCtx.transPercent/10).toFixed(2)}%
        </div>
      </div>
      <div>
        문서 데드라인
      </div> */}
    </Card.Header>
  )  
};

export default Header;
import React from 'react';

const AssignmentContext = React.createContext({
  items: [],
  teamMembers: [],
  deadline: '',
  transPercent: 0,

  selected: [],

  initItems: () => {},
  extendItem: (payload) => {},
  assignItem: (sentence, user) => {},
  selectItem: (payload) => {}
});

export default AssignmentContext;
import React, { useState, useContext, useEffect } from "react";

import axios from "axios";

import { Button, Col, Container, Row, Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faList,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { Minus } from "react-feather";

import Paginator from "../../../../components/Paginator";
import AuthContext from "../../../../store/auth-context";

import ProjectsList from "./ProjectsList";
import Project from "./ProjectCard";
import AddProject from "./AddProject";

import { useDispatch } from "react-redux";
import { initGlobalState } from "../../../../redux/actions/statesAction";

import { BACKEND } from "../../../../Constants";

const Projects = (props) => {
  const history = props.history;
  const projectType = props.location.state
    ? props.location.state["type"]
    : "translation";
  const dispatch = useDispatch();
  const [onAddProject, setOnAddproject] = useState(false);

  const [isList, setisList] = useState(false);
  const [listIcon, setlistIcon] = useState(faList);
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState([]);

  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(0);
  const [itemsPerPage] = useState(12);
  const [isDisabled, setIsDisabled] = useState(false);

  const [selectDel, setSelectDel] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [chkItems, setChkItems] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const getProjectList = async () => {
    const token = localStorage.getItem("token");
    const results = await axios.get(
      `${BACKEND.SERVER}/load_projects?sort=created_datetime+desc&page=${pageNum}&type=${projectType}`,
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );

    const num = parseInt(results.data[0].total_records);
    if (num == 0) {
      setOnAddproject(true);
      setTotalNum(num);
      setTotalProjects([]);
    } else {
      // console.log(results.data)
      setOnAddproject(false);
      // console.log("pageNum: ", pageNum)
      setProjects(results.data);

      setTotalNum(num);

      if (num === pageNum * 12 || totalNum === pageNum * 12)
        setIsDisabled(true);
      if (pageNum === 1) setTotalProjects(results.data);
      else {
        let newProjects = [...totalProjects, ...results.data];
        setTotalProjects(newProjects);
      }
    }
  };

  // 프로젝트 삭제 요청시 card pagination 부분
  const deleteProject = async () => {
    setPageNum(1);
    if (totalNum - 1 !== 12) setIsDisabled(false);
    //if (isList) getProjectList();
    getProjectList();
  };

  const paginate = (number) => {
    setPageNum(number);
  };

  const viewMore = () => {
    let num = pageNum + 1;
    setPageNum(num);
    if (num === parseInt(totalNum / itemsPerPage) + 1) setIsDisabled(true);
  };

  const SelectHandler = () => {
    if (selectDel == true) {
      setChkItems([]);
    }
    setSelectDel(!selectDel);
  };
  const chkItemsHandler = (checked, no) => {
    if (checked) {
      setChkItems((prev) => [...prev, no]);
    } else {
      setChkItems(chkItems.filter((el) => el != no));
    }
  };
  const deleteItemsHandler = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      let url = `${BACKEND.SERVER}/delpjt`;
      let data = {
        no: chkItems,
      };
      let token = localStorage.getItem("token");
      axios
        .post(url, data, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.data);
        });
      // onConfirm();
    } else {
      alert("취소 되었습니다.");
      // onCancel();
    }
  };

  useEffect(() => {
    // console.log(chkItems);
    // console.log(chkItems.length);
  }, [chkItems]);
  // console.log(totalProjects)
  // console.log("here", totalProjects)

  useEffect(() => {
    isLoggedIn && !onAddProject && getProjectList();
    setModalOpen(!isLoggedIn);
  }, [isLoggedIn, pageNum, onAddProject, projectType]);

  useEffect(() => {
    dispatch(initGlobalState());
  }, []);

  return (
    <Container fluid className="p-0m mt-2">
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        fade
        centered
      >
        <ModalBody>
          <Row style={{ placeContent: "center" }}>
            로그인 이후 사용해 주십시오.
          </Row>
          <Row className="p-1 mt-1" style={{ placeContent: "center" }}>
            <Button
              color="info"
              className="mr-2"
              onClick={() => history.push("/auth/sign-in")}
            >
              로그인
            </Button>
            <Button onClick={() => setModalOpen(false)}>닫기</Button>
          </Row>
        </ModalBody>
      </Modal>
      <FontAwesomeIcon
        className="float-right mt-n1"
        icon={listIcon}
        pull="right"
        size="2x"
        // transform="right-95"
        type="button"
        onClick={() => {
          if (isList === false) {
            setisList(true);
            setlistIcon(faTh);
            setPageNum(1);
          } else {
            setisList(false);
            setlistIcon(faList);
            setPageNum(1);
            totalNum !== 12 ? setIsDisabled(false) : setIsDisabled(true);
          }
        }}
      />
      <Button
        color="primary"
        className="float-right mr-1 mt-n1 "
        onClick={() => setOnAddproject(true)}
      >
        <FontAwesomeIcon className="pr-1" icon={faPlus} />
        프로젝트 생성
      </Button>

      <Button
        color="primary"
        className="float-right mr-1 mt-n1 "
        outline
        onClick={SelectHandler}
      >
        편집
      </Button>
      {chkItems.length > 0 && (
        <Button
          color="danger"
          className="float-right mr-1 mt-n1 "
          onClick={deleteItemsHandler}
        >
          삭제
        </Button>
      )}

      {totalNum >= 0 && (
        <h1 className="h3 mb-3">
          {projectType === "translation" && "번역 프로젝트"}
          {projectType === "post-editing" && "검수 프로젝트"}
        </h1>
      )}
      {/* {!onAddProject && totalNum == 0 && <div>프로젝트 없음</div>} */}
      {onAddProject && (
        <AddProject
          projectType={projectType}
          onCancelButton={() => setOnAddproject(false)}
        />
      )}
      {isList ? (
        <div>
          <ProjectsList
            projects={projects}
            currentPage={pageNum}
            deleteProject={(e) => {
              deleteProject(e);
            }}
            projectType={projectType}
          />
          <Paginator
            itemsPerPage={itemsPerPage}
            totalItems={totalNum}
            paginate={paginate}
            currentPage={pageNum}
          />
        </div>
      ) : (
        <Row>
          {totalProjects
            .filter((project) => project.type === projectType)
            .map((data) => {
              return (
                <Col md="6" lg="3" key={data.no}>
                  {selectDel && !selectDel && (
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        chkItemsHandler(e.target.checked, data.no);
                      }}
                      checked={chkItems.includes(data.id) ? true : false}
                    />
                  )}
                  {!selectDel ? (
                    <Project
                      no={data.no}
                      title={data.title}
                      trans_percentage={data.trans_percent}
                      postEdit_percentage={data.postEdit_percent}
                      participants={data.user_names}
                      description={data.description}
                      sourceLang={data.source_language}
                      targetLang={data.target_language}
                      deadline={data.deadline}
                      currentUserRole={data.role}
                      acceptance={data.acceptance}
                      projectType={data.type}
                      deleteProject={(e) => {
                        deleteProject(e);
                      }}
                    />
                  ) : (
                    <>
                      {selectDel && selectDel && (
                        <input
                          type="checkbox"
                          name={`select-${data.no}`}
                          onClick={(e) => {
                            chkItemsHandler(e.target.checked, data.no);
                          }}
                          checked={chkItems.includes(data.no) ? true : false}
                        />
                      )}
                      <Project
                        onMouseOver={() => {
                          setMouseOver(true);
                        }}
                        onMouseOut={() => {
                          setMouseOver(false);
                        }}
                        style={
                          mouseOver
                            ? {
                                borderWidth: "thin",
                                borderColor: "red",
                                cursor: "pointer",
                              }
                            : { borderWidth: "thin", cursor: "pointer" }
                        }
                        no={data.no}
                        title={data.title}
                        trans_percentage={data.trans_percent}
                        postEdit_percentage={data.postEdit_percent}
                        participants={data.user_names}
                        description={data.description}
                        sourceLang={data.source_language}
                        targetLang={data.target_language}
                        deadline={data.deadline}
                        currentUserRole={data.role}
                        acceptance={data.acceptance}
                        deleteProject={(e) => {
                          deleteProject(e);
                        }}
                      />
                    </>
                  )}
                </Col>
              );
            })}

          {totalNum > 12 && (
            <Button
              color="secondary"
              size="lg"
              block
              onClick={viewMore}
              disabled={isDisabled}
            >
              더보기
            </Button>
          )}
        </Row>
      )}
    </Container>
  );
};

export default Projects;

import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Form, Button, InputGroup } from 'react-bootstrap';
import { Plus, Minus } from 'react-feather';
// contexts
import GlossaryContext from '../contexts/glossary-context';

// utils
import { getDateTime } from '../services/glossary.utils';
import { modifyItem } from '../services/glossary.modify';
import { deleteItem } from '../services/glossary.delete';

import { BACKEND } from '../../../../Constants';

const GlossariesList = (props) => {
  const gsCtx = useContext(GlossaryContext);
  const userInfo = localStorage.getItem('userInfo');
  const userId   = userInfo ? JSON.parse(userInfo).userUid :'';
  const [onActive, setOnActive] = useState({
    'active': 'sourceGlossary',
    'index': 0,
    'currentIndex': 0});
  
  const onEditButton = (item) => {
    setOnActive({
      active: 'sourceGlossary', 
      index: item.index,
      currentIndex: item.sourceGlossary.length > 0
        ?item.sourceGlossary.length-1
        :0});
    gsCtx.changeEditing(item.index);
  };

  const getGlossaries = (ref) => {
    const sourceGlossaries = [];
    const targetGlossaries = [];
    const sourceGlossaryComponent = ref.current.children.sourceGlossary.children;
    const targetGlossaryComponent = ref.current.children.targetGlossary.children;

    for (const index of [...Array(sourceGlossaryComponent.length).keys()]) {
      sourceGlossaries.push(sourceGlossaryComponent[index].children[0].value)
    };
    for (const index of [...Array(targetGlossaryComponent.length).keys()]) {
      targetGlossaries.push(targetGlossaryComponent[index].children[0].value)
    };
    return [sourceGlossaries, targetGlossaries]
  }

  const onCheckButton = (index, ref) => {
    const [sourceGlossaries, targetGlossaries] = getGlossaries(ref);

    gsCtx.storeItem(index, sourceGlossaries, targetGlossaries);
    modifyItem(
      sourceGlossaries, targetGlossaries, props.project_no, userId, props.name, index);
  };

  const onRemoveButton = (index) => {
    gsCtx.removeItem(index);
    deleteItem(props.project_no, userId, props.name, index);
  };

  const getEditState = (editState, index) => {
    const editIndex = editState.findIndex(
      item => item.index == index
    );
    return editState[editIndex].status 
  };

  const valueOnChange = (accessKey, name, value) => {
    const [date, time] = getDateTime();
    gsCtx.changeItem(
      accessKey, 
      name,
      value,
      date
    );
    return new Promise((resolve, reject) => {
      resolve(true);
      reject(false);
    });
  };

  const Transform = (props) => {
    const {
      parentRef, value, index, currentIndex, 
      itemlength, name, editState,
      } = props;
    const ref = useRef();
    
    const onAddItemClicked = (event, index, key) => {
      setOnActive({
        active: key, 
        index: parseInt(index[0]),
        currentIndex: currentIndex+1});
      
      const [sourceGlossaries, targetGlossaries] = getGlossaries(parentRef);

      const opposite_value = key === 'sourceGlossary'
        ?targetGlossaries
        :sourceGlossaries;
      const opposite_key = key === 'sourceGlossary'
        ?'targetGlossary'
        :'sourceGlossary'
        
      valueOnChange(index, key, ref.current.value
        ).then(
          valueOnChange(index, opposite_key, opposite_value
            ).then(() => {
              gsCtx.addElement(index, key); 
            })
        )
        
    };
    const onDeleteItemClicked = (event, index, key) => {
      gsCtx.deleteElement(index, key);
    };

    const onKeyDownHandler = (event) => {
      event.key === 'Enter' && onAddItemClicked(
        event, 
        `${index}-${currentIndex}`,
        name
      )
      event.key === 'Backspace' && ref.current.value === '' && onDeleteItemClicked(
        event,
        `${index}-${currentIndex}`,
        name
      )
    };

    useEffect(() => {
      name === onActive.active && 
      index === onActive.index &&
      currentIndex === onActive.currentIndex && 
      ref.current && 
      ref.current.focus();
    }, [])

    if (editState) {
      return (
        <InputGroup key = {`${index}-${currentIndex}}`}>
          <Form.Control 
            ref = {ref}
            style = {{
              'boxShadow': 'none',
              'border': 1, 
            }}
            defaultValue = {value}
          
            className = 'pl-1'
            onKeyDown={(event) => onKeyDownHandler(event, )}
            name = {name}
            index = {index}
            accessKey = {`${index}-${currentIndex}`}
          />
          {itemlength > 1 && <Button
            onClick = {(event) => 
              onDeleteItemClicked(
                event, 
                `${index}-${currentIndex}`,
                name
              )}
            variant='light'
            size = 'sm'>
            <Minus width={15} height={15} />
          </Button>}
          {currentIndex === itemlength -1 && <Button
            onClick = {(event) => 
              onAddItemClicked(
                event, 
                `${index}-${currentIndex}`,
                name
              )}
            variant='light'
            size = 'sm'>
            <Plus width={15} height={15} />
          </Button>}
        </InputGroup>
      )
    }
    else {
      return (
        <div
          key = {`${index}-${currentIndex}}`}
        >
          {value}
        </div>
      ) 
    }
  };

  const TrItem = (props) => {
    const {gsCtx, item, index} = props;
    
    const trRef = useRef();

    return (
      <tr key = {item.index} ref = {trRef} style = {{height:'2.05rem'}}>
        <td className='pt-0 pb-0'>
          {index+1}
        </td>
        <td name = 'sourceGlossary' className='p-0 align-text-top'>
          {item.sourceGlossary.map((source, key) => (
            <Transform 
              key = {key}

              parentRef = {trRef}
              value = {source}
              index = {item.index}
              currentIndex = {key}
              itemlength = {item.sourceGlossary.length} 
              name = {'sourceGlossary'} 
              editState = {getEditState(gsCtx.editing, item.index)} 
            />
          ))}
        </td>
        <td name = 'targetGlossary' className='p-0 align-text-top'>
          {item.targetGlossary.map((target, key) => (
            <Transform 
              key = {key}

              parentRef = {trRef}
              value = {target}
              index = {item.index}
              currentIndex = {key}
              itemlength = {item.targetGlossary.length} 
              name = {'targetGlossary'} 
              editState = {getEditState(gsCtx.editing, item.index)} 
            />
          ))}
        </td>
        <td className='pl-1 align-text-top'>
          {item.auther}
        </td>
        <td className='pt-0 pb-0'>
          {item.date}
        </td>
        <td className='pt-0 pb-0'>
          <FontAwesomeIcon 
            icon={!getEditState(gsCtx.editing, item.index) ?faEdit :faCheck} 
            className = 'ml-1 mr-1'
            style = {{
              'cursor':'pointer'
            }}
            onClick = {() => {
              !getEditState(gsCtx.editing, item.index)
              ?onEditButton(item)
              :onCheckButton(item.index, trRef)}}
            color = {!getEditState(gsCtx.editing, item.index)
              ?'rgba(10, 30, 190, 1)'
              :'rgba(30, 255, 30, 1)'}
          />
          <FontAwesomeIcon 
            icon={faTrash} 
            className = 'ml-1 mr-1'
            style = {{
              'cursor':'pointer'
            }}
            onClick = {() => {
              onRemoveButton(item.index)
            }}
            />
        </td>
      </tr>
    )
  }

  const getContext = (project_no, name) => {
    const userInfo = localStorage.getItem('userInfo');
    const userId   = userInfo ? JSON.parse(userInfo).userUid :'';
    axios.get(`${BACKEND.SERVER}/files/${project_no}/${userId}/${name}/glossary`
    ).then((response) => {
      // console.log(response.data);
      gsCtx.setItems(response.data)
    })
  };
 
  useEffect(() => {
    getContext(props.project_no, props.name);
  }, []);

  return (
    <tbody>
      {gsCtx.items.map((item, index) => (
        <TrItem 
          gsCtx = {gsCtx}
          item = {item}
          index = {index}
        />
      ))}

      
    </tbody>
  )
};

export default GlossariesList;